import type { ResultFrom } from "@artesa/feathers-pinia";
import type { Application } from "./feathers.client";
import app, { connection } from "./feathers.client";
import type { ModelRegisterFrontend, QueryRegister, QueryRegisterFrontend } from "@artesa/shared";
import type { TransportConnection, Query } from "@feathersjs/feathers";
import { Sentry } from "@/main.plugins/main.sentry";

declare module "@feathersjs/feathers" {
  export interface Params<Q = Query> {
    $populateParams?: {
      name: string;
    };

    /**
     * paginate on 'client' or on 'server'
     * @default 'client'
     */
    resultFrom?: ResultFrom;

    /**
     * @default 50
     */
    debounce?: number;
  }
}

export const {
  defineFeathersPiniaStore,
  isOfType,
  servicePathForObject,
  servicePathRegister,
  getStoreByName,
} = makeDefineFeathersPiniaStore<
  ModelRegisterFrontend, 
  QueryRegister, 
  QueryRegisterFrontend,
  Application,
  TransportConnection
>({
  app: () => app,
  connection: () => connection,
  Sentry: () => Sentry
});
