export const assignmentCustomAttributeTypeValues = [
  "text",
  "string",
  "integer",
  "float",
  "double",
  "date",
  "date_only",
  "time",
  "boolean"
];
