import { defineStore } from "pinia";
import {
  getDownloadUrl,
  openBlank,
  openUpload,
  uploadIsImage,
  uploadIsPdf,
} from "./feathers/services/uploads/uploads.helpers";
import type { UploadFrontend } from "@artesa/shared";
import type { RouteLocationNormalizedLoaded } from "vue-router";

export type LightboxItem = {
  src?: string;
  title?: string;
  alt?: string;
};

export const useGeneralStore = defineStore("general", () => {
  const isMainMenuShown = ref(false);
  const sortableOldList = ref<any[] | undefined>();
  const lightboxIndex = ref<null | number>(null);
  const lightboxItems = ref<(LightboxItem | string)[]>([]);
  const hideMainMenu = ref(false);
  const hideMainHeader = ref(false);

  // use skipServiceWorker for pdf generation
  const skipServiceWorker = ref(false);

  const viewTitleRef = ref();

  const _breakpoint = useBreakpoints();
  const _route = useRoute();

  const isHeaderVisible = computed(() => {
    return _breakpoint.maxSm && _route.meta.hideMainHeader !== true && !hideMainHeader.value;
  });

  const isLightBoxOpen = computed(() => lightboxIndex.value !== null);

  function toggleMainMenu() {
    isMainMenuShown.value = !isMainMenuShown.value;
  }

  function showMainMenu(isActive = true) {
    isMainMenuShown.value = isActive;
  }

  async function openFile(upload: UploadFrontend, items: (LightboxItem | string)[]) {
    if (uploadIsImage(upload)) {
      const url = getDownloadUrl(upload);
      if (!url) {
        return;
      }

      lightboxIndex.value = null;
      lightboxItems.value = items;
      const index = items.findIndex(item =>
        typeof item === "string" ? item === url : item.src === url,
      );
      lightboxIndex.value = index;
    } else if (uploadIsPdf(upload)) {
      await openUpload(upload);
    } else {
      await openBlank(upload);
    }
  }

  const predecessorRoutes = ref<RouteLocationNormalizedLoaded[]>([]);

  const skipVueRouterRestoreDataNavigationGuard = ref(false);

  const userDecidedCollapsed = useLocalStorage("main-menu-collapsed", true);

  return {
    hideMainMenu,
    hideMainHeader,
    isMainMenuShown,
    sortableOldList,
    lightboxIndex,
    lightboxItems,
    isLightBoxOpen,
    toggleMainMenu,
    showMainMenu,
    openFile,
    isHeaderVisible,
    viewTitleRef,
    predecessorRoutes,
    skipVueRouterRestoreDataNavigationGuard,
    skipServiceWorker,
    userDecidedCollapsed,
  };
});
