import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable, StringEnum } from "../../typebox/index.js";
import { featureFlagNameValues } from "./feature-flags.types.js";
export const FeatureFlag = defineSchema(
  "feature-flags",
  {
    name: StringEnum(featureFlagNameValues),
    enabledAt: Type.Optional(Nullable(Type.Date())),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  {
    relations: (r) => ({
      company: r.belongsTo("companies", { nullable: true })
    })
  }
);
