import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable } from "../../typebox/index.js";
export const Gang = defineSchema(
  "gangs",
  {
    name: Type.String(),
    order: Type.Optional(Type.Integer({ minimum: 1, default: 1 })),
    deactivatedAt: Type.Optional(Nullable(Type.Date({ default: null }))),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  {
    relations: (r) => ({
      company: r.belongsTo("companies"),
      gangUsers: r.hasMany("gang-users", "gangId")
    })
  }
);
