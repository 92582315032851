import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable, StringEnum } from "../../typebox/index.js";
import { messageTypeValues } from "./assignment-messages.types.js";
export const AssignmentMessage = defineSchema(
  "assignment-messages",
  {
    type: StringEnum(messageTypeValues, { default: "unknown" }),
    text: Type.Optional(Nullable(Type.String({ default: null }))),
    meta: Nullable(Type.Object({ frontendId: Type.Optional(Type.String()) }, { default: null })),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date()),
    deletedAt: Type.Optional(Nullable(Type.Date()))
  },
  {
    relations: (r) => ({
      // belongsTo
      company: r.belongsTo("companies"),
      assignment: r.belongsTo("stairs"),
      assignmentEventGroup: r.belongsTo("assignment-event-groups", { nullable: true }),
      assignmentEvent: r.belongsTo("assignment-events", { nullable: true }),
      user: r.belongsTo("users", { nullable: true }),
      replyTo: r.belongsToTypeUnsafe("assignment-messages", { nullable: true }),
      reactionTo: r.belongsToTypeUnsafe("assignment-messages", { nullable: true }),
      chat: r.belongsTo("assignment-chats"),
      // hasMany
      uploads: r.hasMany("uploads", "messageId"),
      reactions: r.hasManyTypeUnsafe("assignment-messages", "reactionToId")
    })
  }
);
