import { StringEnum, defineSchema } from "@artesa/model-compiler";
import { Type, Nullable } from "../../typebox/index.js";
import { customFormPdfStatusValues } from "./custom-forms.utils.js";
export const CustomForm = defineSchema(
  "custom-forms",
  {
    data: Type.Any(),
    associatedServicePath: Type.Optional(Nullable(Type.String())),
    associatedId: Type.Optional(Nullable(Type.Integer({ minimum: 1 }))),
    associatedParentServicePath: Type.Optional(Nullable(Type.String())),
    associatedParentId: Type.Optional(Nullable(Type.Integer({ minimum: 1 }))),
    title: Type.String(),
    lockedAt: Nullable(Type.Date()),
    pdfStatus: Type.Optional(Nullable(StringEnum(customFormPdfStatusValues))),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date()),
    deletedAt: Type.ReadonlyOptional(Nullable(Type.Date()))
  },
  {
    relations: (r) => ({
      company: r.belongsTo("companies"),
      formTemplate: r.belongsTo("custom-form-templates"),
      createdBy: r.belongsTo("users", { nullable: true }),
      lockedBy: r.belongsTo("users", { nullable: true }),
      pdf: r.belongsTo("uploads", { nullable: true })
    }),
    query: {
      additionalProperties: {
        "$formTemplate.group$": { type: "string", profile: "server" }
      }
    }
  }
);
