import type { IAssignmentEventWorkPeriodService } from "@artesa/shared";
import { ASSIGNMENT_EVENT_WORK_PERIOD_METHODS, AssignmentEventWorkPeriod } from "@artesa/shared";
import { defineFeathersPiniaStore } from "../../base-store";
import { makeTransformDatesHook } from "../../hooks/transformDates.hook";

declare module "../../feathers.client" {
  interface Services {
    "assignment-event-work-periods": IAssignmentEventWorkPeriodService;
  }
}

export const useAssignmentEventWorkPeriodStore = defineFeathersPiniaStore({
  schema: AssignmentEventWorkPeriod,
  servicePath: "assignment-event-work-periods",
  methods: ASSIGNMENT_EVENT_WORK_PERIOD_METHODS,
  setupHook({ service, transformDates }) {
    const transformDatesHook = makeTransformDatesHook(transformDates);
    service.hooks({
      after: {
        find: [transformDatesHook],
        get: [transformDatesHook],
        create: [transformDatesHook],
        update: [transformDatesHook],
        patch: [transformDatesHook],
        remove: [transformDatesHook],
      },
    });
  },
});

export default useAssignmentEventWorkPeriodStore;