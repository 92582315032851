import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable } from "../../typebox/index.js";
export const UserAbsenceNorm = defineSchema(
  "user-absence-norms",
  {
    year: Type.Integer({ minimum: 2e3, max: 2100 }),
    allocation: Type.Optional(Nullable(Type.Integer({ minimum: 0, default: null }))),
    carryOverBefore: Type.Optional(Nullable(Type.Integer({ default: null }))),
    carryOverAfter: Type.Optional(Nullable(Type.Integer({ default: null }))),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  {
    relations: (r) => ({
      company: r.belongsTo("companies"),
      user: r.belongsTo("users"),
      absenceType: r.belongsTo("user-absence-types")
    })
  }
);
