import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable } from "../../typebox/index.js";
export const UserAbsenceType = defineSchema(
  "user-absence-types",
  {
    name: Type.String(),
    color: Type.Optional(
      Nullable(Type.RegExp(/^#[0-9a-fA-F]{8}$|#[0-9a-fA-F]{6}$|#[0-9a-fA-F]{4}$|#[0-9a-fA-F]{3}$/))
    ),
    needApproval: Type.Optional(Type.Boolean({ default: true })),
    needCompensation: Type.Optional(Type.Boolean({ default: false })),
    countAsWorkingTime: Type.Optional(Type.Boolean({ default: true })),
    order: Type.Optional(Type.Integer({ minimum: 1, default: 1 })),
    deactivatedAt: Type.Optional(Nullable(Type.Date({ default: null }))),
    isAbsenceHourly: Type.Boolean({ default: false }),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  {
    relations: (r) => ({
      company: r.belongsTo("companies")
    })
  }
);
