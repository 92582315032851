import { AssignmentCustomAttribute } from '@artesa/shared';
import { defineFeathersPiniaStore } from "../../base-store";

export const useAssignmentCustomAttributesStore = defineFeathersPiniaStore({
  schema: AssignmentCustomAttribute,
  servicePath: 'assignment-custom-attributes',
  setupHook({ service, transformDates }) {
    const transformDatesHook = makeTransformDatesHook(transformDates);
    service.hooks({
      after: {
        find: [transformDatesHook],
        get: [transformDatesHook],
        create: [transformDatesHook],
        update: [transformDatesHook],
        patch: [transformDatesHook],
        remove: [transformDatesHook],
      },
    });
  },
})

export default useAssignmentCustomAttributesStore;