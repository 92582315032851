import { Kind, ReadonlyKind, Type } from '@sinclair/typebox';
export * from '@sinclair/typebox';
import * as prettier from 'prettier';
export { Value } from '@sinclair/typebox/value';
export { TypeCompiler } from '@sinclair/typebox/compiler';

// src/compiler/compile-single-schema.ts

// src/compiler/utils/regex-utils.ts
var _escapeRegExp = (text) => {
  return text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};
function escapePropertyKey(key) {
  if (/^[a-zA-Z_$][a-zA-Z0-9_]*$/.test(key)) {
    return key;
  }
  return `"${key}"`;
}
function testStringNotInQuotes(str, search) {
  return new RegExp(`(?<!["'])${_escapeRegExp(search)}(?!["'])`).test(str);
}
function regexpNotIn(search, notIn) {
  search = _escapeRegExp(search);
  const n1 = _escapeRegExp(notIn[0]);
  const n2 = _escapeRegExp(notIn[1]);
  return new RegExp(
    search + `(?![^${n1}]*${n2}(?:[^${n1}]*${n1}[^${n2}]*${n2})*[^${n1}]*$)`,
    "g"
  );
}
function splitNotIn(input, pattern, notIn) {
  return input.split(regexpNotIn(pattern, notIn));
}

// src/compiler/utils/parse-utils.ts
function parseUnion(types) {
  return types.split(regexpNotIn(" | ", ["{", "}"]));
}
function parseNonNullable(types) {
  return types.replace(regexpNotIn(" | null", ["{", "}"]), "");
}
function isSchemaUnion(option) {
  return option[Kind] === "Union";
}
function isSchemaIntersect(option) {
  return option[Kind] === "Intersect";
}
function isSchemaObject(option) {
  return option[Kind] === "Object";
}
function isSchemaReadonly(options) {
  if (ReadonlyKind in options) {
    return options[ReadonlyKind] === "Readonly";
  }
  if (isSchemaIntersect(options)) {
    return options.allOf.every((value) => value[ReadonlyKind] === "Readonly");
  }
  if (isSchemaUnion(options)) {
    return options.anyOf.some((value) => value[ReadonlyKind] === "Readonly");
  }
  return false;
}
function isSchemaVirtual(option) {
  if ("virtual" in option) {
    return option.virtual === true;
  }
  if (isSchemaIntersect(option)) {
    return option.allOf.every((value) => value.virtual === true);
  }
  if (isSchemaUnion(option)) {
    return option.anyOf.some((value) => value.virtual === true);
  }
  return false;
}

// src/compiler/utils/jsdoc-utils.ts
function stringifyJsDoc(comment, options) {
  const result = [];
  function addLine(comment2) {
    result.push(...comment2.split("\n").map((x) => x.trim()));
  }
  function appendSection(comment2) {
    if (!comment2) {
      return;
    }
    const lines = Array.isArray(comment2) ? comment2 : [comment2];
    if (result.length) {
      addLine("");
    }
    for (const line of lines) {
      addLine(line);
    }
  }
  appendSection(comment);
  if (options && "default" in options) {
    appendSection(`@default ${JSON.stringify(options.default)}`);
  }
  if (options?.readonly) {
    appendSection("@readonly");
  }
  if (options?.deprecated) {
    appendSection("@deprecated");
  }
  if (!result.length) {
    return "";
  }
  let generatedCode = spaces(options?.spaces, "/**\n");
  for (const line of result) {
    generatedCode += spaces(options?.spaces, " * ", line, "\n");
  }
  generatedCode += spaces(options?.spaces, " */\n");
  return generatedCode;
}
function jsDocOptionsForSchema(schema, options) {
  const comment = [];
  if (schema.title) {
    comment.push(schema.title);
  }
  if (schema.description) {
    if (comment.length) {
      comment.push("");
    }
    comment.push(schema.description);
  }
  return {
    comment,
    spaces: options?.spaces,
    ..."default" in schema ? { default: schema.default } : {},
    deprecated: schema.deprecated,
    readonly: isSchemaReadonly(schema)
  };
}

// src/compiler/utils/stringify-utils.ts
function quote(value) {
  return `"${value}"`;
}
function spaces(number, ...append) {
  return `${" ".repeat(number ?? 0)}${append?.join("") ?? ""}`;
}
function partial(value) {
  if (!value) {
    return value;
  }
  return `Partial<${value}>`;
}
function newLine(append) {
  if (arguments.length === 0) {
    return "\n";
  }
  if (!append) {
    return "";
  }
  const isNewLine = typeof append === "number" ? true : append ?? false;
  if (!isNewLine) {
    return "";
  }
  const num = typeof append === "number" ? append : 1;
  return `${"\n".repeat(num)}`;
}
function stringifyUnion(types) {
  return types.join(" | ");
}
function stringifyProperty(key, value, options) {
  let result = "";
  result += newLine(options?.prependNewLine);
  if (options?.jsDocComment) {
    result += stringifyJsDoc(options.jsDocComment.comment, {
      ...options.jsDocComment,
      spaces: options?.spaces
    });
  }
  result += spaces(options?.spaces);
  result += stringifyIf(options?.readonly, "readonly ");
  if (options?.skipEscapePropertyKey) {
    result += key;
  } else {
    result += escapePropertyKey(key);
  }
  result += stringifyIf(options?.optional, "?");
  result += ": ";
  result += `${value}`;
  if (options?.nullable && !isNullable(value)) {
    result += " | null";
  }
  result += ";";
  result += newLine(options?.appendNewLine);
  return result;
}
function stringifyObject(properties, options) {
  let result = newLine(options?.prependNewLine);
  if (options?.jsDocComment) {
    result += stringifyJsDoc(options.jsDocComment.comment, {
      ...options.jsDocComment,
      spaces: options?.spaces
    });
  }
  result += "{";
  for (const line of properties) {
    const _spaces = `${" ".repeat(options?.spaces ?? 0)}`;
    result += stringifyProperty(line[0], _spaces + line[1], {
      ...line[2],
      prependNewLine: true,
      appendNewLine: false,
      spaces: (options?.spaces ?? 0) + 2
    });
  }
  result += `
${spaces(options?.spaces, "}")}`;
  result += newLine(options?.appendNewLine);
  return result;
}
function stringifyType(name, options) {
  let result = newLine(options?.prependNewLine);
  if (options?.jsDocComment) {
    result += stringifyJsDoc(options.jsDocComment.comment, {
      ...options.jsDocComment,
      spaces: 0
    });
  }
  if (options?.export) {
    result += "export ";
  }
  if (options?.interface) {
    result += `interface ${name} `;
    if (options.extends) {
      result += `extends ${options.extends.join(", ")} `;
    }
    if (options.value) {
      result += `${options.value}`;
    } else {
      result += stringifyObject(options?.properties ?? []);
    }
  } else {
    result += `type ${name} = `;
    const intersect = [
      ...options?.extends ?? []
    ];
    if (options?.value) {
      intersect.push(options.value);
    }
    if (options?.properties) {
      intersect.push(stringifyObject(options.properties));
    }
    result += intersect.join(" & ");
  }
  result += ";";
  result += newLine(typeof options?.appendNewLine === "number" ? options.appendNewLine : 1);
  return result;
}
function stringifyIf(condition, value) {
  return condition ? value : "";
}
function isNullable(value) {
  return value === "null" || value.endsWith(" | null");
}
function simplifyType(value, options) {
  if (testStringNotInQuotes(value, "Array<") || testStringNotInQuotes(value, "[]")) {
    return "any[]";
  }
  if (testStringNotInQuotes(value, "Record<") || testStringNotInQuotes(value, "{")) {
    return "Record<string, any>";
  }
  try {
    const number = Number(value);
    if (!isNaN(number)) {
      return "number";
    }
  } catch {
  }
  const types = splitNotIn(value, " | ", ["{", "}"]);
  if (types.length > 1) {
    const simplified = /* @__PURE__ */ new Set();
    for (const type of types) {
      simplified.add(simplifyType(type));
    }
    if (options?.stripNull) {
      simplified.delete("null");
    }
    return stringifyUnion(Array.from(simplified));
  }
  if (value.includes('"') || value.includes("'")) {
    return "string";
  }
  for (const type of ["string", "number", "Date", "boolean", "undefined", "any", "null", "void"]) {
    if (testStringNotInQuotes(value, type)) {
      return type;
    }
  }
  return value;
}

// src/extended-types/frontend-only.ts
var FrontendOnlyKind = Symbol.for("Artesa/FrontendOnly");
function frontendOnly(schema) {
  const modifiedSchema = schema;
  modifiedSchema[FrontendOnlyKind] = true;
  return modifiedSchema;
}
function isSchemaFrontendOnly(option) {
  if (FrontendOnlyKind in option) {
    return !!option[FrontendOnlyKind];
  }
  if (isSchemaIntersect(option)) {
    return option.allOf.every((value) => FrontendOnlyKind in value && !!value[FrontendOnlyKind]);
  }
  if (isSchemaUnion(option)) {
    return option.anyOf.some((value) => FrontendOnlyKind in value && !!value[FrontendOnlyKind]);
  }
  return false;
}

// src/extended-types/not-in-create.ts
var OnlyNotCreateKind = Symbol.for("Artesa/OnlyNotCreate");
function onlyNotCreate(schema) {
  const modifiedSchema = schema;
  modifiedSchema[OnlyNotCreateKind] = true;
  return modifiedSchema;
}
function isSchemaOnlyNotCreate(option) {
  if (OnlyNotCreateKind in option) {
    return !!option[OnlyNotCreateKind];
  }
  if (isSchemaIntersect(option)) {
    return option.allOf.every((value) => OnlyNotCreateKind in value && !!value[OnlyNotCreateKind]);
  }
  if (isSchemaUnion(option)) {
    return option.anyOf.some((value) => OnlyNotCreateKind in value && !!value[OnlyNotCreateKind]);
  }
  return false;
}
function tSchemaToTsType(schema) {
  switch (schema[Kind]) {
    case "Union": {
      const union = schema;
      return `${union.anyOf.map((value) => tSchemaToTsType(value)).join(" | ")}`;
    }
    case "Object":
      return tSchemaTObjectToTsType(schema);
    case "RegExp":
    case "String":
      return "string";
    case "Integer":
    case "Number":
      return "number";
    case "Boolean":
      return "boolean";
    case "BigInt":
      return "bigint";
    case "Date":
      return "Date";
    case "Literal": {
      if (schema.type === "number") {
        return `${schema.const}`;
      }
      return `"${schema.const}"`;
    }
    case "Never":
      return "never";
    case "Null":
      return "null";
    case "Undefined":
      return "undefined";
    case "Uint8Array":
      return "Uint8Array";
    case "Unknown":
      return "unknown";
    case "Void":
      return "void";
    case "Any":
      return "any";
    case "Array": {
      const array = schema;
      return `(${tSchemaToTsType(array.items)})[]`;
    }
    case "Intersect": {
      const intersect = schema;
      return `${intersect.allOf.map((value) => tSchemaToTsType(value)).join(" & ")}`;
    }
    case "TemplateLiteral":
      console.warn(`TemplateLiteral gets converted to string`);
      return "string";
    default:
      console.warn(`Failed to convert type ${schema[Kind]} to typescript type`, schema);
      return "any";
  }
}
function tSchemaTObjectToTsType(schema) {
  if (Object.keys(schema.properties).length === 0) {
    return `Record<string, any>`;
  }
  const properties = [];
  for (const propertyKey in schema.properties) {
    const propertyOptions = schema.properties[propertyKey];
    properties.push([
      propertyKey,
      tSchemaToTsType(propertyOptions),
      {
        optional: !schema.required?.includes(propertyKey),
        readonly: schema[ReadonlyKind] === "Readonly"
      }
    ]);
  }
  return stringifyObject(properties);
}
function modelSchemaPropertyToType(schema, propertyKey) {
  if (!isSchemaUnion(schema)) {
    return tSchemaToTsType(schema.properties[propertyKey]);
  }
  const types = schema.anyOf.map((value) => tSchemaToTsType(value.properties[propertyKey])).join(" | ");
  const uniq = [...new Set(parseUnion(types))].join(" | ");
  return uniq;
}

// src/compiler/compile-single-schema.ts
function relationProperties(schema, options = {}) {
  const properties = [];
  for (const relationKey in schema.relations) {
    const relationConfig = schema.relations[relationKey];
    const relationName = options.modelName?.(relationConfig.service);
    if (!relationName) {
      throw new Error(
        `Relation service ${relationConfig.service} not found in modelMap`
      );
    }
    properties.push([
      relationKey,
      `${relationName}${stringifyIf(relationConfig.asArray, "[]")}`,
      {
        prependNewLine: true,
        spaces: options.spaces,
        optional: true,
        nullable: relationConfig.nullable
      }
    ]);
  }
  return properties;
}
function virtualProperties(schema, options = {}) {
  const properties = [];
  for (const relationKey in schema.virtual) {
    const relationConfig = schema.virtual[relationKey];
    const relationName = options.modelName?.(relationConfig.service);
    if (!relationName) {
      throw new Error(
        `Relation service ${relationConfig.service} not found in modelMap`
      );
    }
    properties.push([
      relationKey,
      `${relationName}${stringifyIf(relationConfig.asArray, "[]")}`,
      {
        prependNewLine: true,
        spaces: options.spaces,
        optional: true
      }
    ]);
  }
  return properties;
}
function compileSingleSchema(schema, options) {
  const properties = [];
  const {
    spaces: spaces2 = 2,
    properties: profile
  } = options;
  if (profile) {
    for (const propertyKey in schema.properties) {
      const propertyOptions = schema.properties[propertyKey];
      const isFrontendOnly = isSchemaFrontendOnly(propertyOptions);
      const onlyNotCreate2 = isSchemaOnlyNotCreate(propertyOptions);
      if (isFrontendOnly && profile === "base") {
        continue;
      }
      if (!isFrontendOnly && profile === "frontend") {
        continue;
      }
      if (onlyNotCreate2 && profile === "create") {
        continue;
      }
      properties.push(
        [
          propertyKey,
          tSchemaToTsType(propertyOptions),
          {
            prependNewLine: true,
            optional: !schema.required?.includes(propertyKey),
            readonly: schema[ReadonlyKind] === "Readonly",
            spaces: spaces2,
            jsDocComment: jsDocOptionsForSchema(propertyOptions)
          }
        ]
      );
    }
  }
  if (options.relations) {
    properties.push(...relationProperties(schema, options));
  }
  if (options.virtual) {
    properties.push(...virtualProperties(schema, options));
  }
  properties.push([
    "__service",
    quote(schema.service),
    {
      prependNewLine: true,
      spaces: spaces2,
      optional: true
    }
  ]);
  return stringifyObject(properties);
}

// src/compiler/params/common-query-properties.ts
var commonQueryPropertiesMap = {
  "string": "QueryPropertyString",
  "string | null": "QueryPropertyStringNullable",
  "number": "QueryPropertyNumber",
  "number | null": "QueryPropertyNumberNullable",
  "boolean": "QueryPropertyBoolean",
  "boolean | null": "QueryPropertyBooleanNullable",
  "Date": "QueryPropertyDate",
  "Date | null": "QueryPropertyDateNullable"
};
function commonQueryProperties(options) {
  let generatedCode = "";
  for (const [type, name] of Object.entries(commonQueryPropertiesMap)) {
    generatedCode += stringifyType(name, {
      value: queryPropertyValue(type, options),
      appendNewLine: 2
    });
  }
  return generatedCode;
}

// src/compiler/params/queryProperty.ts
function queryPropertyValue(type, options) {
  const simplifiedType = simplifyType(type, { stripNull: true });
  const typeNonNullable = parseNonNullable(type);
  const comparisonOp = ["number", "Date", "any"].includes(simplifiedType) ? ["$lt", "$lte", "$gt", "$gte"] : [];
  const properties = [
    ...[
      "$eq",
      "$ne"
    ].map((key) => {
      return [key, type, { optional: true }];
    }),
    ...comparisonOp.map((key) => {
      return [key, typeNonNullable, { optional: true }];
    }),
    ...[
      "$in",
      "$nin"
    ].map((key) => {
      return [key, `(${type})[]`, { optional: true }];
    })
  ];
  const additionalOperators = options?.operators ? typeof options.operators === "function" ? [options.operators(type, simplifiedType)] : options.operators.map((fn) => fn(type, simplifiedType)) : [];
  for (const operators of additionalOperators) {
    if (!operators) {
      continue;
    }
    for (const { key, type: type2 } of operators) {
      properties.push([key, type2, { optional: true }]);
    }
  }
  const obj = stringifyObject(properties, {
    spaces: options?.spaces ?? 0
  });
  return `${type} | ${obj}`;
}
function queryProperty(propertyKey, type, options) {
  const value = type in commonQueryPropertiesMap ? commonQueryPropertiesMap[type] : queryPropertyValue(type, options);
  return [
    propertyKey,
    value,
    {
      prependNewLine: true,
      spaces: (options?.spaces ?? 0) + 2,
      optional: true,
      jsDocComment: options?.jsDocComment
    }
  ];
}

// src/compiler/params/compileQuery.ts
function compileQuery(schema, options) {
  const SharedQuery = options.serviceMaps.query.shared[schema.service];
  const _SharedQuery = `_${SharedQuery}`;
  let generatedCode = "";
  const properties = isSchemaUnion(schema) ? schema.anyOf[0].properties : schema.properties;
  (() => {
    const SharedParams = options.serviceMaps.params.shared[schema.service];
    const props = [];
    for (const propertyKey in properties) {
      const propertyOptions = properties[propertyKey];
      const isFrontendOnly = isSchemaFrontendOnly(propertyOptions);
      if (isFrontendOnly) {
        continue;
      }
      const _jsDocComment = jsDocOptionsForSchema(propertyOptions);
      props.push(queryProperty(
        propertyKey,
        modelSchemaPropertyToType(schema, propertyKey),
        {
          ...options,
          jsDocComment: {
            comment: _jsDocComment.comment,
            deprecated: _jsDocComment.deprecated,
            spaces: _jsDocComment.spaces
          }
        }
      ));
    }
    const additionalQueryProperties = schema.query?.additionalProperties ?? {};
    for (const additionalKey in additionalQueryProperties) {
      const additionalProperty = additionalQueryProperties[additionalKey];
      if (additionalProperty.profile && additionalProperty.profile !== "shared") {
        continue;
      }
      props.push([
        additionalKey,
        additionalProperty.type,
        options
      ]);
    }
    generatedCode += stringifyType(_SharedQuery, {
      properties: props,
      appendNewLine: 2
    });
    generatedCode += stringifyType(SharedQuery, {
      export: true,
      value: `QueryCommon<${_SharedQuery}>`,
      appendNewLine: 2
    });
    generatedCode += `export type ${SharedParams}<Required extends boolean = false> = DefineParams<${SharedQuery}, Required>

`;
  })();
  const modelNameFrontend = options.serviceMaps.model.frontend[schema.service];
  (() => {
    const ServerQuery = options.serviceMaps.query.server[schema.service];
    const ServerParams = options.serviceMaps.params.server[schema.service];
    const _ServerQuery = `_${ServerQuery}`;
    const props = [];
    const additionalQueryProperties = schema.query?.additionalProperties ?? {};
    for (const additionalKey in additionalQueryProperties) {
      const additionalProperty = additionalQueryProperties[additionalKey];
      if (additionalProperty.profile !== "server") {
        continue;
      }
      props.push(queryProperty(additionalKey, additionalProperty.type, options));
    }
    generatedCode += stringifyType(_ServerQuery, {
      extends: [_SharedQuery],
      properties: props,
      appendNewLine: 2
    });
    generatedCode += `export type ${ServerQuery} = QueryCommon<${_ServerQuery}, ${_SharedQuery}>

`;
    generatedCode += `export type ${ServerParams}<Required extends boolean = false> = DefineParams<${ServerQuery}, Required>

`;
  })();
  (() => {
    const QueryFrontend = options.serviceMaps.query.frontend[schema.service];
    const ParamsFrontend = options.serviceMaps.params.frontend[schema.service];
    const _QueryFrontend = `_${modelNameFrontend}Query`;
    const props = [];
    for (const propertyKey in properties) {
      const propertyOptions = properties[propertyKey];
      const isFrontendOnly = isSchemaFrontendOnly(propertyOptions);
      if (!isFrontendOnly) {
        continue;
      }
      const _jsDocComment = jsDocOptionsForSchema(propertyOptions);
      props.push(queryProperty(propertyKey, modelSchemaPropertyToType(schema, propertyKey), {
        ...options,
        jsDocComment: {
          comment: _jsDocComment.comment,
          deprecated: _jsDocComment.deprecated,
          spaces: _jsDocComment.spaces
        }
      }));
    }
    const additionalQueryProperties = schema.query?.additionalProperties ?? {};
    for (const additionalKey in additionalQueryProperties) {
      const additionalProperty = additionalQueryProperties[additionalKey];
      if (additionalProperty.profile !== "frontend") {
        continue;
      }
      props.push(queryProperty(additionalKey, additionalProperty.type, options));
    }
    generatedCode += stringifyType(_QueryFrontend, {
      extends: [_SharedQuery],
      properties: props,
      appendNewLine: 2
    });
    generatedCode += `export type ${QueryFrontend} = QueryCommon<${_QueryFrontend}, ${_SharedQuery}>

`;
    generatedCode += `export type ${ParamsFrontend}<Required extends boolean = false> = DefineParams<${QueryFrontend}, Required>

`;
  })();
  return generatedCode;
}

// src/compiler/compile-register.ts
function compileRegister({
  registerName,
  modelName,
  serviceMap
}) {
  const properties = [];
  for (const key in serviceMap) {
    properties.push([key, serviceMap[key], {}]);
  }
  let generatedCode = `// MARK: ${registerName}

`;
  generatedCode += stringifyType(registerName, {
    export: true,
    interface: true,
    properties
  }) + "\n\n";
  generatedCode += `export type ${modelName}<S extends keyof ${registerName} = keyof ${registerName}> = ${registerName}[S]`;
  return generatedCode;
}

// src/compiler/compile.ts
async function compile(schemas, options = {}) {
  let generatedCode = "";
  generatedCode += "type WithOrAnd<T extends Record<string, any>> = T & { $or?: WithOrAnd<T>[], $and?: WithOrAnd<T>[] }\n\n";
  generatedCode += stringifyType("QueryCommon<T extends Record<string, any>, Shared extends T = T>", {
    extends: ["WithOrAnd<T>"],
    properties: [
      ["$select", "(keyof Shared)[]", { optional: true }],
      ["$limit", "number", { optional: true }],
      ["$skip", "number", { optional: true }],
      ["$sort", "{ [key in keyof Shared]?: 1 | -1 | '1' | '-1' | (number & {}) }", { optional: true }]
    ],
    appendNewLine: true
  });
  generatedCode += stringifyType("ParamsOptionalQuery<Q>", {
    properties: [
      ["query", "Q", { optional: true }],
      ["[key: string]", "any", { skipEscapePropertyKey: true }]
    ],
    appendNewLine: true
  });
  generatedCode += stringifyType("ParamsRequiredQuery<Q>", {
    properties: [
      ["query", "Q", {}],
      ["[key: string]", "any", { skipEscapePropertyKey: true }]
    ],
    appendNewLine: true
  });
  generatedCode += "type DefineParams<T, Required extends boolean = false> = Required extends true ? ParamsRequiredQuery<T> : ParamsOptionalQuery<T>\n\n";
  generatedCode += commonQueryProperties({
    operators: options.queryOperators
  });
  const maps = {
    model: {
      base: {},
      baseRelations: {},
      baseWithRelations: {},
      frontendRelations: {},
      frontend: {},
      create: {},
      createRelations: {},
      createWithRelations: {}
    },
    query: {
      server: {},
      frontend: {},
      shared: {}
    },
    params: {
      server: {},
      frontend: {},
      shared: {}
    }
  };
  for (const name in schemas) {
    const schema = schemas[name];
    maps.model.frontend[schema.service] = `${name}Frontend`;
    maps.model.frontendRelations[schema.service] = `${name}FrontendRelations`;
    maps.model.base[schema.service] = `${name}Base`;
    maps.model.baseRelations[schema.service] = `${name}BaseRelations`;
    maps.model.baseWithRelations[schema.service] = `${name}BaseWithRelations`;
    maps.model.create[schema.service] = `${name}Create`;
    maps.model.createRelations[schema.service] = `${name}CreateRelations`;
    maps.model.createWithRelations[schema.service] = `${name}CreateWithRelations`;
    maps.query.server[schema.service] = `${name}Query`;
    maps.query.frontend[schema.service] = `${name}FrontendQuery`;
    maps.query.shared[schema.service] = `${name}SharedQuery`;
    maps.params.server[schema.service] = `${name}Params`;
    maps.params.frontend[schema.service] = `${name}FrontendParams`;
    maps.params.shared[schema.service] = `${name}SharedParams`;
  }
  for (const name in schemas) {
    const schema = schemas[name];
    const isUnion = isSchemaUnion(schema);
    generatedCode += "// MARK: " + schema.service + "\n\n";
    (() => {
      const baseName = maps.model.base[schema.service];
      const relationsName = maps.model.baseRelations[schema.service];
      const withRelationsName = maps.model.baseWithRelations[schema.service];
      generatedCode += stringifyType(baseName, {
        export: true,
        interface: !isUnion,
        value: isUnion ? stringifyUnion(schema.anyOf.map((value) => compileSingleSchema(value, { properties: "base" }))) : compileSingleSchema(schema, { properties: "base" })
      });
      generatedCode += stringifyType(relationsName, {
        export: true,
        interface: !isUnion,
        properties: relationProperties(schema, {
          modelName: (servicePath) => partial(maps.model.baseWithRelations[servicePath])
        }),
        appendNewLine: 2
      });
      generatedCode += stringifyType(withRelationsName, {
        export: true,
        interface: !isUnion,
        extends: [baseName, relationsName],
        appendNewLine: 2
      });
    })();
    (() => {
      const createName = maps.model.create[schema.service];
      const relationsName = maps.model.createRelations[schema.service];
      const withRelationsName = maps.model.createWithRelations[schema.service];
      generatedCode += stringifyType(createName, {
        export: true,
        interface: !isUnion,
        value: isUnion ? stringifyUnion(schema.anyOf.map((value) => compileSingleSchema(value, { properties: "create" }))) : compileSingleSchema(schema, { properties: "create" }),
        appendNewLine: 2
      });
      generatedCode += stringifyType(relationsName, {
        export: true,
        interface: !isUnion,
        properties: relationProperties(schema, {
          modelName: (servicePath) => partial(maps.model.baseWithRelations[servicePath])
        }),
        appendNewLine: 2
      });
      generatedCode += stringifyType(withRelationsName, {
        export: true,
        interface: !isUnion,
        extends: [createName, relationsName],
        appendNewLine: 2
      });
    })();
    (() => {
      const modelName = maps.model.frontendRelations[schema.service];
      generatedCode += stringifyType(modelName, {
        export: true,
        interface: !isUnion,
        properties: relationProperties(schema, {
          modelName: (servicePath) => maps.model.frontend[servicePath]
        }),
        appendNewLine: 2
      });
    })();
    (() => {
      const baseName = maps.model.base[schema.service];
      const relationsName = maps.model.frontendRelations[schema.service];
      const modelName = maps.model.frontend[schema.service];
      generatedCode += stringifyType(modelName, {
        export: true,
        interface: !isUnion,
        extends: isUnion ? [relationsName] : [baseName, relationsName],
        value: isUnion ? stringifyUnion(schema.anyOf.map((value) => compileSingleSchema(value, {
          properties: "baseAndFrontend",
          virtual: true
        }))) : compileSingleSchema(schema, {
          properties: "frontend",
          virtual: true
        }),
        appendNewLine: 2
      });
    })();
    (() => {
      generatedCode += compileQuery(schema, {
        operators: options.queryOperators,
        serviceMaps: maps
      });
      generatedCode += "\n\n";
    })();
  }
  [
    ["ModelRegister", "Model", maps.model.base],
    ["ModelRegisterFrontend", "ModelFrontend", maps.model.frontend],
    ["BaseRelationRegister", "BaseRelations", maps.model.baseRelations],
    ["BaseWithRelationsRegister", "ModelWithRelations", maps.model.baseWithRelations],
    ["FrontendRelationRegister", "FrontendRelations", maps.model.frontendRelations],
    ["ModelRegisterCreate", "ModelCreate", maps.model.create],
    ["ModelRegisterCreateRelations", "ModelCreateRelations", maps.model.createRelations],
    ["ModelRegisterCreateWithRelations", "ModelCreateWithRelations", maps.model.createWithRelations],
    ["QueryRegister", "Query", maps.query.server],
    ["QueryRegisterFrontend", "QueryFrontend", maps.query.frontend],
    ["QueryRegisterShared", "QueryShared", maps.query.shared],
    ["ParamsRegisterShared", "ParamsShared", maps.params.shared],
    ["ParamsRegister", "Params", maps.params.server],
    ["ParamsRegisterFrontend", "ParamsFrontend", maps.params.frontend]
  ].forEach(([registerName, modelName, serviceMap]) => {
    generatedCode += compileRegister({
      registerName,
      modelName,
      serviceMap
    }) + "\n\n";
  });
  if (options.skipPrettier) {
    return generatedCode;
  }
  return await prettier.format(generatedCode, {
    parser: "typescript",
    tabWidth: options.spaces ?? 2
  });
}
var Nullable = (schema, options) => {
  return Type.Union([schema, Type.Null()], {
    ..."title" in schema ? { title: schema.title } : {},
    ..."description" in schema ? { description: schema.description } : {},
    ..."default" in schema ? { default: schema.default } : {},
    ..."deprecated" in schema ? { deprecated: schema.deprecated } : {},
    ...options
  });
};
function isSchemaNullable(option) {
  return isSchemaUnion(option) && option.anyOf.some((o) => o[Kind] === "Null");
}
function getRelations(options) {
  const helper = {
    belongsTo(service, additionalOptions) {
      return {
        service,
        keyHere: "",
        keyThere: "id",
        asArray: false,
        nullable: false,
        ...additionalOptions ?? {}
      };
    },
    belongsToTypeUnsafe(service, additionalOptions) {
      return {
        service,
        keyHere: "",
        keyThere: "id",
        asArray: false,
        nullable: false,
        ...additionalOptions ?? {}
      };
    },
    hasMany(service, foreignKey, additionalOptions) {
      return {
        service,
        keyHere: "id",
        keyThere: foreignKey,
        asArray: true,
        nullable: false,
        ...additionalOptions ?? {}
      };
    },
    hasManyTypeUnsafe(service, foreignKey, additionalOptions) {
      return {
        service,
        keyHere: "id",
        keyThere: foreignKey,
        asArray: true,
        nullable: false,
        ...additionalOptions ?? {}
      };
    }
  };
  const virtualHelper = {
    virtual(service, asArray, getPopulateData, excludeForHasChange) {
      return {
        service,
        asArray,
        virtual: true,
        excludeForHasChange,
        getPopulateData
      };
    }
  };
  const relations = options.relations(helper);
  const virtual = options.virtual(virtualHelper);
  return { relations, virtual };
}
function defineRelations(schema, options) {
  const {
    relations: relationMap,
    virtual: virtualMap
  } = options;
  for (const key in relationMap) {
    const relation = relationMap[key];
    relation.nameAs ||= key;
    if (!relation.keyHere) {
      relation.keyHere = `${key}Id`;
    }
  }
  for (const key in virtualMap) {
    const relation = virtualMap[key];
    relation.nameAs ||= key;
  }
  const eachNonArrayRelation = Object.keys(relationMap).map((key) => relationMap[key]).filter((relation) => !relation.asArray);
  const relationIdProperties = {};
  for (const relation of eachNonArrayRelation) {
    const backendIdKey = `${relation.nameAs}Id`;
    const frontendUuidKey = `${relation.nameAs}Uuid`;
    const isNullable2 = relation.nullable;
    relationIdProperties[backendIdKey] = isNullable2 ? Type.Optional(Nullable(Type.Number())) : Type.Number();
    relationIdProperties[frontendUuidKey] = onlyNotCreate(
      frontendOnly(
        isNullable2 ? Type.Optional(Nullable(Type.String({ virtual: true }))) : Type.String({ virtual: true })
      )
    );
  }
  relationIdProperties.id = onlyNotCreate(Type.Number());
  relationIdProperties.uuid = frontendOnly(onlyNotCreate(Type.String()));
  const mergedSchema = Type.Composite([schema, Type.Object(relationIdProperties)]);
  return mergedSchema;
}

// src/extended-types/attach-service.ts
function attachToSchema(schema, options) {
  Object.assign(schema, options);
  return schema;
}
var schemaRegister = /* @__PURE__ */ new Map();
function defineSchema(service, properties, options) {
  const { relations, virtual } = getRelations({
    relations: options?.relations ?? (() => ({})),
    virtual: options?.virtual ?? (() => ({}))
  });
  const attached = {
    service,
    query: options?.query ?? {},
    relations,
    virtual
  };
  if (Array.isArray(properties)) {
    const schema = attachToSchema(
      Type.Union(
        properties.map(
          (value) => attachToSchema(
            defineRelations(
              Type.Object(value),
              {
                relations,
                virtual
              }
            ),
            attached
          )
        )
      ),
      attached
    );
    schemaRegister.set(service, schema);
    return schema;
  } else {
    const schema = attachToSchema(
      defineRelations(
        Type.Object(properties),
        {
          relations,
          virtual
        }
      ),
      attached
    );
    schemaRegister.set(service, schema);
    return schema;
  }
}
var StringEnum = (allowedValues, options) => {
  const toRecord = {};
  allowedValues.forEach((value) => toRecord[value] = value);
  return Type.Enum(toRecord, options);
};
var NumberEnum = (allowedValues, options) => {
  return Type.Union(
    allowedValues.map((value) => Type.Literal(value)),
    options
  );
};

export { FrontendOnlyKind, Nullable, NumberEnum, OnlyNotCreateKind, StringEnum, attachToSchema, compile, compileQuery, compileSingleSchema, defineRelations, defineSchema, frontendOnly, getRelations, isSchemaFrontendOnly, isSchemaIntersect, isSchemaNullable, isSchemaObject, isSchemaOnlyNotCreate, isSchemaReadonly, isSchemaUnion, isSchemaVirtual, onlyNotCreate, relationProperties, schemaRegister, virtualProperties };
