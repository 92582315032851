import { Type } from "../../typebox/index.js";
export const constructionManagerSchema = Type.Object(
  {
    name: Type.String(),
    tel: Type.String(),
    mail: Type.String()
  },
  {
    default: {
      name: "",
      tel: "",
      mail: ""
    }
  }
);
export const architectSchema = Type.Object(
  {
    name: Type.String(),
    tel: Type.String(),
    mail: Type.String()
  },
  {
    default: {
      name: "",
      tel: "",
      mail: ""
    }
  }
);
