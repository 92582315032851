import { defineSchema } from "@artesa/model-compiler";
import { Type } from "../../typebox/index.js";
export const UserCapability = defineSchema(
  "user-capabilities",
  {
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  {
    relations: (r) => ({
      company: r.belongsTo("companies"),
      user: r.belongsTo("users"),
      eventType: r.belongsTo("assignment-event-types")
    })
  }
);
