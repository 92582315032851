import { viewNameEnum } from "@artesa/shared";
import castProps from "vue-router-parse-props";
import { defineRoutesCustomFormsSuperadmin } from "@/components/custom-forms/superadmin/routes";

export default (): RouteRecordRaw[] => [
  {
    path: "superadmin",
    name: viewNameEnum.ViewSuperAdmin,
    component: () => import("./_views/ViewSuperAdmin.vue"),
    meta: {
      name: "SuperAdmin",
      icon: "fa-solid:tools",
      permission: true,
    },
  },
  {
    path: "superadmin/company",
    name: viewNameEnum.ViewSuperAdminCompanies,
    component: () => import("./_views/ViewSuperAdminCompanies.vue"),
    meta: {
      name: "Unternehmen",
      icon: "fa-solid:building",
      permission: true,
    },
    children: [
      {
        path: ":companyId/:settingName?",
        name: viewNameEnum["ViewSuperAdminCompanies.Company"],
        component: () => import("./_views/ViewEditCompany.vue"),
        props: castProps({
          companyId: {
            type: Number,
          },
        }),
        meta: {
          permission: viewNameEnum.ViewSuperAdminCompanies,
        },
      },
    ],
  },
  ...defineRoutesCustomFormsSuperadmin("superadmin/company/"),
  {
    path: "superadmin/feature-flags",
    name: viewNameEnum.ViewSuperAdminFeatureFlags,
    component: () => import("./feature-flags/ViewSuperAdminFeatureFlags.vue"),
    meta: {
      name: "Feature Flags",
      icon: "fa-solid:flag-checkered",
      permission: true,
    },
  },
  {
    path: "superadmin/stripe/payouts",
    name: viewNameEnum.ViewSuperAdminStripePayoutView,
    component: () => import("./_views/ViewStripePayouts.vue"),
    meta: {
      name: "Stripe Payouts",
      permission: true,
      icon: "tabler:brand-stripe",
    },
  }
];
