export const weekdayValues = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday"
];
export const weekdayNumberValues = [0, 1, 2, 3, 4, 5, 6];
