type JWTPayload = {
  exp: number;
  iat: number;
  sub: string;
  aud: string;
  iss: string;
  jti: string;
  [key: string]: any;
}

/**
 * Get the payload of an jwt token
 * @param token JWT Token
 */
export function parseJwt(token: string): JWTPayload {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(""),
  );

  return JSON.parse(jsonPayload);
}

export function isJwtExpired(token: string): boolean {
  const payload = parseJwt(token);
  if (!payload?.exp) {
    return true;
  }
  
  return payload.exp * 1000 < Date.now();
}