import { defineStore } from "pinia";
import { getDownloadUrl } from "./feathers/services/uploads/uploads.helpers";
import type { UploadFrontend } from "@artesa/shared";

export const usePdfStore = defineStore("pdf", () => {
  const currentPdf = ref<{ name: string; url: string } | null>(null);

  function showPDF(file: UploadFrontend) {
    const url = getDownloadUrl(file);
    if (!url) {
      return;
    }

    currentPdf.value = {
      name: file.originalName,
      url,
    };
  }

  function showPDFRaw(url: string, name: string) {
    currentPdf.value = {
      name,
      url,
    };
  }

  function closePDF() {
    currentPdf.value = null;
  }

  const isOpen = computed(() => !!currentPdf.value);

  return {
    currentPdf: readonly(currentPdf),
    showPDF,
    showPDFRaw,
    closePDF,
    isOpen,
  };
});
