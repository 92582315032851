import type { UserBase, UserFrontend } from "@artesa/shared";
import { User } from "@artesa/shared";
import { discard } from "feathers-hooks-common";
import type { EntityWrapper } from "@artesa/feathers-pinia";
import { defineFeathersPiniaStore } from "../../base-store";
import { makeTransformDatesHook } from "../../hooks/transformDates.hook";
import type { Service } from "@feathersjs/feathers";

declare module "../../feathers.client" {
  interface Services {
    users: Service<UserBase>;
  }
}

function removePassword(ref: EntityWrapper<UserFrontend>) {
  if ("password" in ref.entity) {
    // @ts-expect-error Idc
    delete ref.entity.password;
  }
  if (ref.originalData && "password" in ref.originalData) {
    delete ref.originalData.password;
  }
}

export const useUserStore = defineFeathersPiniaStore({
  schema: User,
  servicePath: "users",
  transformHooks: {
    afterPatched: removePassword,
    afterCreated: removePassword,
  },
  setupHook({ service, transformDates }) {
    const transformDatesHook = makeTransformDatesHook(transformDates);
    service.hooks({
      before: {
        patch: [discard("passwordChangedAt")],
      },
      after: {
        find: [transformDatesHook],
        get: [transformDatesHook],
        create: [transformDatesHook],
        update: [transformDatesHook],
        patch: [transformDatesHook],
        remove: [transformDatesHook],
      },
    });
  },
});

export default useUserStore;
