import { defineSchema, Nullable } from "@artesa/model-compiler";
import { Type } from "../../typebox/index.js";
export const Address = defineSchema(
  "addresses",
  {
    /**
     * @example "z.Hd. Frau Mustermann"
     */
    supplement: Type.Optional(Nullable(Type.String())),
    street: Type.Optional(Nullable(Type.String())),
    houseNumber: Type.Optional(Nullable(Type.String())),
    /**
     * @example "Hinterhaus" or "Aufgang links" or "3. Etage"
     */
    clarification: Type.Optional(Nullable(Type.String())),
    zip: Type.Optional(Nullable(Type.String())),
    city: Type.Optional(Nullable(Type.String())),
    state: Type.Optional(Nullable(Type.String())),
    country: Type.Optional(Nullable(Type.String())),
    latitude: Type.Optional(Nullable(Type.Number())),
    longitude: Type.Optional(Nullable(Type.Number())),
    googlePlaceId: Type.Optional(Nullable(Type.String())),
    googleString: Type.Optional(Nullable(Type.String())),
    foreignReference: Type.Optional(Nullable(Type.String())),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date()),
    deletedAt: Type.Optional(Nullable(Type.Date()))
  },
  {
    relations: (r) => ({
      company: r.belongsTo("companies"),
      createdBy: r.belongsTo("users", { nullable: true })
    })
  }
);
