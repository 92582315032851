import castProps from "vue-router-parse-props";
import type { RouteRecordRaw } from "vue-router";
import { viewNameEnum } from "@artesa/shared";

export const defineRoutesCustomFormsSuperadmin = (prepend?: string) => {
  return [
    {
      path: `${prepend ?? ""}:companyId/custom-forms`,
      name: viewNameEnum["ViewSuperAdminCustomForms"],
      component: () => import("./ViewViewCustomForms.vue"),
      props: castProps({
        companyId: {
          type: Number,
        },
      }),
      meta: {
        name: "Custom Forms",
      },
    },
    {
      path: `${prepend ?? ""}:companyId/custom-forms/:id/edit`,
      name: viewNameEnum["ViewSuperAdminCustomForms.CustomForm"],
      component: () => import("./ViewEditCustomFormSuspense.vue"),
      props: castProps({
        companyId: {
          type: Number,
        },
        id: {
          type: Number,
        },
      }),
    },
    {
      path: `${prepend ?? ""}:companyId/custom-forms/:id/preview`,
      name: viewNameEnum["ViewSuperAdminCustomFormPreview"],
      component: () => import("./ViewPreviewCustomForm.vue"),
      props: castProps({
        companyId: {
          type: Number,
        },
        id: {
          type: Number,
        },
      }),
      meta: {
        name: "Preview",
        hideMainMenu: true,
      },
    },
  ] satisfies RouteRecordRaw[];
};
