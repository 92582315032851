import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable, StringEnum } from "../../typebox/index.js";
import { customFormTemplateTypeValues } from "./custom-form-templates.types.js";
export const CustomFormTemplate = defineSchema(
  "custom-form-templates",
  {
    name: Type.String(),
    description: Type.Optional(Nullable(Type.String({ default: null }))),
    group: Type.String(),
    version: Type.Integer({ minimum: 1 }),
    order: Type.Optional(Type.Integer({ minimum: 1, default: 1 })),
    jsonSchema: Type.Any(),
    uiSchema: Type.Any(),
    type: Type.Array(StringEnum(customFormTemplateTypeValues)),
    deactivatedAt: Type.Optional(Nullable(Type.Date({ default: null }))),
    publishedAt: Type.Optional(Nullable(Type.Date({ default: null }))),
    defaultedAt: Type.Optional(Nullable(Type.Date({ default: null }))),
    allowCreateWithoutAssociatedItem: Type.Boolean({ default: false }),
    draftable: Type.Optional(Type.Boolean({ default: false })),
    saveAsPdf: Type.Optional(Type.Boolean({ default: false })),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date()),
    deletedAt: Type.ReadonlyOptional(Nullable(Type.Date()))
  },
  {
    relations: (r) => ({
      company: r.belongsTo("companies")
      // TODO: add hasMany relation for custom-form-templates
    })
  }
);
