import { Type } from "../../typebox/index.js";
export const computeTimeSheetMonthDataSchema = Type.Object(
  {
    userId: Type.Integer({ minimum: 1 }),
    month: Type.Union([Type.Date(), Type.String({ format: "date-time" })])
  },
  { additionalProperties: false }
);
export const computeTimeSheetMonthResultSchema = Type.Object({
  sumTarget: Type.Number()
});
