import "@artesa/model-compiler";
import Ajv from "ajv";
import addFormats from "ajv-formats";
import addErrors from "ajv-errors";
import ajvKeywords from "ajv-keywords";
import { TypeGuard } from "@artesa/model-compiler";
import { Value } from "@artesa/model-compiler";
import { formTemplateSchemas } from "../custom-forms/index.js";
export const jsonSchemas = [...formTemplateSchemas];
function isDate(schemaOf2, value, schema) {
  return schemaOf2 === "Date" && TypeGuard.IsDate(schema) && Value.Check(schema, value);
}
function isUint8Array(schemaOf2, value, schema) {
  return schemaOf2 === "Uint8Array" && TypeGuard.IsUint8Array(schema) && Value.Check(schema, value);
}
function schemaOf(schemaOf2, value, schema) {
  switch (schemaOf2) {
    case "Date":
      return isDate(schemaOf2, value, schema);
    case "Uint8Array":
      return isUint8Array(schemaOf2, value, schema);
    default:
      return false;
  }
}
export function defineAjv() {
  const ajv = addErrors(
    addFormats(new Ajv({ schemas: formTemplateSchemas, allErrors: true, strict: false }), [
      "date-time",
      "time",
      "date",
      "email",
      "hostname",
      "ipv4",
      "ipv6",
      "uri",
      "uri-reference",
      "uuid",
      "uri-template",
      "json-pointer",
      "relative-json-pointer",
      "regex"
    ])
  );
  ajvKeywords(ajv).addKeyword({ type: "object", keyword: "instanceOf", validate: schemaOf });
  return ajv;
}
