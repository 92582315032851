import { Type } from "@artesa/model-compiler";
import { Arrayable } from "../../typebox/index.js";
export const userAbsenceNormsServiceMethods = [
  "find",
  "get",
  "create",
  "update",
  "patch",
  "remove",
  "calculateUserAbsenceNorms",
  "findOrCreateFromAllocationBefore"
];
export const calculateUserAbsenceNormsDataSchema = Type.Object(
  {
    companyId: Type.Integer({ minimum: 1 }),
    year: Arrayable(Type.Integer({ minimum: 1900, maximum: 2200 }), {
      arrayOptions: { maxItems: 2, minItems: 2 }
    }),
    userIds: Type.Array(Type.Integer({ minimum: 1 }), { minItems: 1 }),
    absenceTypeIds: Type.Union([
      Type.Array(Type.Integer({ minimum: 1 }), { minItems: 1 }),
      Type.Literal("*")
    ])
  },
  { additionalProperties: false }
);
export const userAbsenceNormFindOrCreateFromAllocationBeforeDataSchema = Type.Object(
  {
    companyId: Type.Integer({ minimum: 1 }),
    year: Type.Integer({ minimum: 1900, maximum: 2200 }),
    userIds: Type.Array(Type.Integer({ minimum: 1 }), { minItems: 1 }),
    absenceTypeIds: Type.Array(Type.Integer({ minimum: 1 }), { minItems: 1 })
  },
  { additionalProperties: false }
);
