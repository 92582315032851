(function(){"use strict";try{if(typeof document<"u"){var t=document.createElement("style");t.appendChild(document.createTextNode("._wrap_12rlz_1{overflow:hidden;position:relative}._scroll-container_12rlz_6{overflow:auto}._shadow-top_12rlz_10,._shadow-right_12rlz_11,._shadow-bottom_12rlz_12,._shadow-left_12rlz_13{position:absolute;border-radius:.5em;opacity:0;transition:opacity .4s;pointer-events:none}._shadow-top_12rlz_10,._shadow-bottom_12rlz_12{right:0;left:0;height:.5em;border-top-right-radius:0;border-top-left-radius:0;background-image:linear-gradient(#0000002b,#fff0)}._shadow-top_12rlz_10{top:0}._shadow-bottom_12rlz_12{bottom:0;transform:rotate(180deg)}._shadow-right_12rlz_11,._shadow-left_12rlz_13{top:0;bottom:0;width:.5em;border-top-left-radius:0;border-bottom-left-radius:0;background-image:linear-gradient(90deg,#0000002b,#fff0)}._shadow-right_12rlz_11{right:0;transform:rotate(180deg)}._shadow-left_12rlz_13{left:0}._is-active_12rlz_59{opacity:1}")),document.head.appendChild(t)}}catch(o){console.error("vite-plugin-css-injected-by-js",o)}})();
var zt = Object.defineProperty;
var Me = (e) => {
  throw TypeError(e);
};
var Lt = (e, r, t) => r in e ? zt(e, r, { enumerable: !0, configurable: !0, writable: !0, value: t }) : e[r] = t;
var De = (e, r, t) => Lt(e, typeof r != "symbol" ? r + "" : r, t), qe = (e, r, t) => r.has(e) || Me("Cannot " + t);
var g = (e, r, t) => (qe(e, r, "read from private field"), t ? t.call(e) : r.get(e)), x = (e, r, t) => r.has(e) ? Me("Cannot add the same private member more than once") : r instanceof WeakSet ? r.add(e) : r.set(e, t), C = (e, r, t, n) => (qe(e, r, "write to private field"), n ? n.call(e, t) : r.set(e, t), t), $ = (e, r, t) => (qe(e, r, "access private method"), t);
var we = (e, r, t, n) => ({
  set _(i) {
    C(e, r, i, t);
  },
  get _() {
    return g(e, r, n);
  }
});
import { getCurrentScope as Rt, onScopeDispose as Wt, unref as de, ref as E, readonly as H, watch as I, onMounted as st, nextTick as Ne, isRef as Ie, getCurrentInstance as at, shallowRef as q, watchEffect as ot, computed as A, defineComponent as Y, renderSlot as V, openBlock as ve, createElementBlock as ze, normalizeClass as D, normalizeStyle as B, createBlock as ut, resolveDynamicComponent as lt, withCtx as ct, toRef as _e, toValue as k, isReactive as Mt, createCommentVNode as Dt, reactive as fe, createElementVNode as ee, mergeProps as Ft, normalizeProps as Bt, guardReactiveProps as Vt, h as Gt } from "vue";
function W(e) {
  return Rt() ? (Wt(e), !0) : !1;
}
function Ht(e, r, { enumerable: t = !1, unwrap: n = !0 } = {}) {
  for (const [i, s] of Object.entries(r))
    i !== "value" && (Ie(s) && n ? Object.defineProperty(e, i, {
      get() {
        return s.value;
      },
      set(l) {
        s.value = l;
      },
      enumerable: t
    }) : Object.defineProperty(e, i, { value: s, enumerable: t }));
  return e;
}
function U(e) {
  return typeof e == "function" ? e() : de(e);
}
const $e = typeof window < "u" && typeof document < "u";
typeof WorkerGlobalScope < "u" && globalThis instanceof WorkerGlobalScope;
const ft = (e) => e != null, Ut = Object.prototype.toString, Qt = (e) => Ut.call(e) === "[object Object]", Z = () => {
};
function dt(e, r) {
  function t(...n) {
    return new Promise((i, s) => {
      Promise.resolve(e(() => r.apply(this, n), { fn: r, thisArg: this, args: n })).then(i).catch(s);
    });
  }
  return t;
}
const vt = (e) => e();
function Le(e, r = {}) {
  let t, n, i = Z;
  const s = (u) => {
    clearTimeout(u), i(), i = Z;
  };
  return (u) => {
    const a = U(e), c = U(r.maxWait);
    return t && s(t), a <= 0 || c !== void 0 && c <= 0 ? (n && (s(n), n = null), Promise.resolve(u())) : new Promise((o, d) => {
      i = r.rejectOnCancel ? d : o, c && !n && (n = setTimeout(() => {
        t && s(t), n = null, o(u());
      }, c)), t = setTimeout(() => {
        n && s(n), n = null, o(u());
      }, a);
    });
  };
}
function Yt(e = vt) {
  const r = E(!0);
  function t() {
    r.value = !1;
  }
  function n() {
    r.value = !0;
  }
  const i = (...s) => {
    r.value && e(...s);
  };
  return { isActive: H(r), pause: t, resume: n, eventFilter: i };
}
function Xt(e) {
  return at();
}
function Jt(e, r = 200, t = {}) {
  return dt(
    Le(r, t),
    e
  );
}
function ht(e, r = 200, t = {}) {
  const n = E(e.value), i = Jt(() => {
    n.value = e.value;
  }, r, t);
  return I(e, () => i()), n;
}
function mt(e, r, t = {}) {
  const {
    eventFilter: n = vt,
    ...i
  } = t;
  return I(
    e,
    dt(
      n,
      r
    ),
    i
  );
}
function pt(e, r = !0, t) {
  Xt() ? st(e, t) : r ? e() : Ne(e);
}
function yt(e, r = 1e3, t = {}) {
  const {
    immediate: n = !0,
    immediateCallback: i = !1
  } = t;
  let s = null;
  const l = E(!1);
  function u() {
    s && (clearInterval(s), s = null);
  }
  function a() {
    l.value = !1, u();
  }
  function c() {
    const o = U(r);
    o <= 0 || (l.value = !0, i && e(), u(), l.value && (s = setInterval(e, o)));
  }
  if (n && $e && c(), Ie(r) || typeof r == "function") {
    const o = I(r, () => {
      l.value && $e && c();
    });
    W(o);
  }
  return W(a), {
    isActive: l,
    pause: a,
    resume: c
  };
}
function gt(e, r, t = {}) {
  const {
    debounce: n = 0,
    maxWait: i = void 0,
    ...s
  } = t;
  return mt(
    e,
    r,
    {
      ...s,
      eventFilter: Le(n, { maxWait: i })
    }
  );
}
function Zt(e, r, t) {
  let n;
  Ie(t) ? n = {
    evaluating: t
  } : n = t || {};
  const {
    lazy: i = !1,
    evaluating: s = void 0,
    shallow: l = !0,
    onError: u = Z
  } = n, a = E(!i), c = l ? q(r) : E(r);
  let o = 0;
  return ot(async (d) => {
    if (!a.value)
      return;
    o++;
    const h = o;
    let p = !1;
    s && Promise.resolve().then(() => {
      s.value = !0;
    });
    try {
      const f = await e((m) => {
        d(() => {
          s && (s.value = !1), p || m();
        });
      });
      h === o && (c.value = f);
    } catch (f) {
      u(f);
    } finally {
      s && h === o && (s.value = !1), p = !0;
    }
  }), i ? A(() => (a.value = !0, c.value)) : c;
}
const K = $e ? window : void 0;
function G(e) {
  var r;
  const t = U(e);
  return (r = t == null ? void 0 : t.$el) != null ? r : t;
}
function je(...e) {
  let r, t, n, i;
  if (typeof e[0] == "string" || Array.isArray(e[0]) ? ([t, n, i] = e, r = K) : [r, t, n, i] = e, !r)
    return Z;
  Array.isArray(t) || (t = [t]), Array.isArray(n) || (n = [n]);
  const s = [], l = () => {
    s.forEach((o) => o()), s.length = 0;
  }, u = (o, d, h, p) => (o.addEventListener(d, h, p), () => o.removeEventListener(d, h, p)), a = I(
    () => [G(r), U(i)],
    ([o, d]) => {
      if (l(), !o)
        return;
      const h = Qt(d) ? { ...d } : d;
      s.push(
        ...t.flatMap((p) => n.map((f) => u(o, p, f, h)))
      );
    },
    { immediate: !0, flush: "post" }
  ), c = () => {
    a(), l();
  };
  return W(c), c;
}
function Kt() {
  const e = E(!1), r = at();
  return r && st(() => {
    e.value = !0;
  }, r), e;
}
function Ae(e) {
  const r = Kt();
  return A(() => (r.value, !!e()));
}
function er(e, r, t = {}) {
  const { window: n = K, ...i } = t;
  let s;
  const l = Ae(() => n && "MutationObserver" in n), u = () => {
    s && (s.disconnect(), s = void 0);
  }, a = A(() => {
    const h = U(e), p = (Array.isArray(h) ? h : [h]).map(G).filter(ft);
    return new Set(p);
  }), c = I(
    () => a.value,
    (h) => {
      u(), l.value && h.size && (s = new MutationObserver(r), h.forEach((p) => s.observe(p, i)));
    },
    { immediate: !0, flush: "post" }
  ), o = () => s == null ? void 0 : s.takeRecords(), d = () => {
    c(), u();
  };
  return W(d), {
    isSupported: l,
    stop: d,
    takeRecords: o
  };
}
function tr(e, r = {}) {
  const {
    immediate: t = !0,
    fpsLimit: n = void 0,
    window: i = K
  } = r, s = E(!1), l = n ? 1e3 / n : null;
  let u = 0, a = null;
  function c(h) {
    if (!s.value || !i)
      return;
    u || (u = h);
    const p = h - u;
    if (l && p < l) {
      a = i.requestAnimationFrame(c);
      return;
    }
    u = h, e({ delta: p, timestamp: h }), a = i.requestAnimationFrame(c);
  }
  function o() {
    !s.value && i && (s.value = !0, u = 0, a = i.requestAnimationFrame(c));
  }
  function d() {
    s.value = !1, a != null && i && (i.cancelAnimationFrame(a), a = null);
  }
  return t && o(), W(d), {
    isActive: H(s),
    pause: d,
    resume: o
  };
}
function rr(e, r = {}) {
  const { window: t = K } = r, n = Ae(() => t && "matchMedia" in t && typeof t.matchMedia == "function");
  let i;
  const s = E(!1), l = (c) => {
    s.value = c.matches;
  }, u = () => {
    i && ("removeEventListener" in i ? i.removeEventListener("change", l) : i.removeListener(l));
  }, a = ot(() => {
    n.value && (u(), i = t.matchMedia(U(e)), "addEventListener" in i ? i.addEventListener("change", l) : i.addListener(l), s.value = i.matches);
  });
  return W(() => {
    a(), u(), i = void 0;
  }), s;
}
function Ce(e, r, t = {}) {
  const { window: n = K, ...i } = t;
  let s;
  const l = Ae(() => n && "ResizeObserver" in n), u = () => {
    s && (s.disconnect(), s = void 0);
  }, a = A(() => {
    const d = U(e);
    return Array.isArray(d) ? d.map((h) => G(h)) : [G(d)];
  }), c = I(
    a,
    (d) => {
      if (u(), l.value && n) {
        s = new ResizeObserver(r);
        for (const h of d)
          h && s.observe(h, i);
      }
    },
    { immediate: !0, flush: "post" }
  ), o = () => {
    u(), c();
  };
  return W(o), {
    isSupported: l,
    stop: o
  };
}
function Fe(e, r = {}) {
  const {
    reset: t = !0,
    windowResize: n = !0,
    windowScroll: i = !0,
    immediate: s = !0,
    updateTiming: l = "sync"
  } = r, u = E(0), a = E(0), c = E(0), o = E(0), d = E(0), h = E(0), p = E(0), f = E(0);
  function m() {
    const y = G(e);
    if (!y) {
      t && (u.value = 0, a.value = 0, c.value = 0, o.value = 0, d.value = 0, h.value = 0, p.value = 0, f.value = 0);
      return;
    }
    const w = y.getBoundingClientRect();
    u.value = w.height, a.value = w.bottom, c.value = w.left, o.value = w.right, d.value = w.top, h.value = w.width, p.value = w.x, f.value = w.y;
  }
  function v() {
    l === "sync" ? m() : l === "next-frame" && requestAnimationFrame(() => m());
  }
  return Ce(e, v), I(() => G(e), (y) => !y && v()), er(e, v, {
    attributeFilter: ["style", "class"]
  }), i && je("scroll", v, { capture: !0, passive: !0 }), n && je("resize", v, { passive: !0 }), pt(() => {
    s && v();
  }), {
    height: u,
    bottom: a,
    left: c,
    right: o,
    top: d,
    width: h,
    x: p,
    y: f,
    update: v
  };
}
function nr(e, r, t = {}) {
  const {
    root: n,
    rootMargin: i = "0px",
    threshold: s = 0,
    window: l = K,
    immediate: u = !0
  } = t, a = Ae(() => l && "IntersectionObserver" in l), c = A(() => {
    const f = U(e);
    return (Array.isArray(f) ? f : [f]).map(G).filter(ft);
  });
  let o = Z;
  const d = E(u), h = a.value ? I(
    () => [c.value, G(n), d.value],
    ([f, m]) => {
      if (o(), !d.value || !f.length)
        return;
      const v = new IntersectionObserver(
        r,
        {
          root: G(m),
          rootMargin: i,
          threshold: s
        }
      );
      f.forEach((y) => y && v.observe(y)), o = () => {
        v.disconnect(), o = Z;
      };
    },
    { immediate: u, flush: "post" }
  ) : Z, p = () => {
    o(), h(), d.value = !1;
  };
  return W(p), {
    isSupported: a,
    isActive: d,
    pause() {
      o(), d.value = !1;
    },
    resume() {
      d.value = !0;
    },
    stop: p
  };
}
function ir(e = {}) {
  const {
    controls: r = !1,
    interval: t = "requestAnimationFrame"
  } = e, n = E(/* @__PURE__ */ new Date()), i = () => n.value = /* @__PURE__ */ new Date(), s = t === "requestAnimationFrame" ? tr(i, { immediate: !0 }) : yt(i, t, { immediate: !0 });
  return r ? {
    now: n,
    ...s
  } : n;
}
function sr(e = {}) {
  const {
    window: r = K,
    initialWidth: t = Number.POSITIVE_INFINITY,
    initialHeight: n = Number.POSITIVE_INFINITY,
    listenOrientation: i = !0,
    includeScrollbar: s = !0,
    type: l = "inner"
  } = e, u = E(t), a = E(n), c = () => {
    r && (l === "outer" ? (u.value = r.outerWidth, a.value = r.outerHeight) : s ? (u.value = r.innerWidth, a.value = r.innerHeight) : (u.value = r.document.documentElement.clientWidth, a.value = r.document.documentElement.clientHeight));
  };
  if (c(), pt(c), je("resize", c, { passive: !0 }), i) {
    const o = rr("(orientation: portrait)");
    I(o, () => c());
  }
  return { width: u, height: a };
}
const si = /* @__PURE__ */ Y({
  __name: "AsyncComputed",
  props: {
    evaluationCallback: {},
    initialState: {},
    options: {}
  },
  setup(e) {
    const r = e, t = q(!1), n = Zt(r.evaluationCallback, r.initialState, {
      ...r.options,
      evaluating: t
    });
    return (i, s) => V(i.$slots, "default", {
      evaluating: t.value,
      value: de(n)
    });
  }
}), ar = ["href", "target"], ai = /* @__PURE__ */ Y({
  inheritAttrs: !1,
  __name: "ConditionalLink",
  props: {
    href: {},
    target: { default: void 0 },
    fallbackTag: { default: void 0 },
    urlClass: { default: void 0 },
    fallbackClass: { default: void 0 }
  },
  setup(e) {
    const r = e;
    return (t, n) => r.href ? (ve(), ze("a", {
      key: 0,
      href: r.href,
      target: r.target,
      class: D([t.$attrs.class, t.urlClass]),
      style: B(t.$attrs.style)
    }, [
      V(t.$slots, "default")
    ], 14, ar)) : r.fallbackTag ? (ve(), ut(lt(r.fallbackTag), {
      key: 1,
      class: D([t.$attrs.class, t.fallbackClass]),
      style: B(t.$attrs.style)
    }, {
      default: ct(() => [
        V(t.$slots, "default")
      ]),
      _: 3
      /* FORWARDED */
    }, 8, ["class", "style"])) : V(t.$slots, "default", { key: 2 });
  }
}), oi = /* @__PURE__ */ Y({
  __name: "DeclarativeWatch",
  props: {
    getter: { type: Function }
  },
  emits: ["watch"],
  setup(e, { emit: r }) {
    const t = e, n = r, i = A(() => t.getter);
    return I(
      () => i.value(),
      (s, l) => {
        n("watch", s, l);
      }
    ), (s, l) => V(s.$slots, "default");
  }
}), or = (e, r = 200, t) => {
  const { maxWait: n, rejectOnCancel: i, ...s } = t || {}, l = A(e, s), u = ht(l, r, { maxWait: n, rejectOnCancel: i });
  return A(() => u.value);
};
function ui(e, r) {
  if ((r == null ? void 0 : r.onlyIf) === void 0)
    return A(e, r);
  const { onlyIf: t } = r || {};
  let n;
  const i = E();
  let s = !0, l, u = !1;
  return I(() => e(n), (a) => {
    if (n = a, s) {
      i.value = a, s = !1;
      return;
    }
    if (!k(t)) {
      l = a, u = !0;
      return;
    }
    i.value = a;
  }, { flush: "sync", immediate: !0 }), I(_e(t), (a) => {
    u && a && (i.value = l, l = void 0, u = !1);
  }, { flush: "sync" }), A(() => i.value, r);
}
function wt(e, r) {
  const { comparator: t = () => !0 } = r || {};
  return A((n) => {
    const i = e();
    return t(i, n) ? n : i;
  }, r);
}
var ur = Object.getOwnPropertyNames, lr = Object.getOwnPropertySymbols, cr = Object.prototype.hasOwnProperty;
function Be(e, r) {
  return function(n, i, s) {
    return e(n, i, s) && r(n, i, s);
  };
}
function be(e) {
  return function(t, n, i) {
    if (!t || !n || typeof t != "object" || typeof n != "object")
      return e(t, n, i);
    var s = i.cache, l = s.get(t), u = s.get(n);
    if (l && u)
      return l === n && u === t;
    s.set(t, n), s.set(n, t);
    var a = e(t, n, i);
    return s.delete(t), s.delete(n), a;
  };
}
function Ve(e) {
  return ur(e).concat(lr(e));
}
var bt = Object.hasOwn || function(e, r) {
  return cr.call(e, r);
};
function oe(e, r) {
  return e || r ? e === r : e === r || e !== e && r !== r;
}
var Et = "_owner", Ge = Object.getOwnPropertyDescriptor, He = Object.keys;
function fr(e, r, t) {
  var n = e.length;
  if (r.length !== n)
    return !1;
  for (; n-- > 0; )
    if (!t.equals(e[n], r[n], n, n, e, r, t))
      return !1;
  return !0;
}
function dr(e, r) {
  return oe(e.getTime(), r.getTime());
}
function Ue(e, r, t) {
  if (e.size !== r.size)
    return !1;
  for (var n = {}, i = e.entries(), s = 0, l, u; (l = i.next()) && !l.done; ) {
    for (var a = r.entries(), c = !1, o = 0; (u = a.next()) && !u.done; ) {
      var d = l.value, h = d[0], p = d[1], f = u.value, m = f[0], v = f[1];
      !c && !n[o] && (c = t.equals(h, m, s, o, e, r, t) && t.equals(p, v, h, m, e, r, t)) && (n[o] = !0), o++;
    }
    if (!c)
      return !1;
    s++;
  }
  return !0;
}
function vr(e, r, t) {
  var n = He(e), i = n.length;
  if (He(r).length !== i)
    return !1;
  for (var s; i-- > 0; )
    if (s = n[i], s === Et && (e.$$typeof || r.$$typeof) && e.$$typeof !== r.$$typeof || !bt(r, s) || !t.equals(e[s], r[s], s, s, e, r, t))
      return !1;
  return !0;
}
function ue(e, r, t) {
  var n = Ve(e), i = n.length;
  if (Ve(r).length !== i)
    return !1;
  for (var s, l, u; i-- > 0; )
    if (s = n[i], s === Et && (e.$$typeof || r.$$typeof) && e.$$typeof !== r.$$typeof || !bt(r, s) || !t.equals(e[s], r[s], s, s, e, r, t) || (l = Ge(e, s), u = Ge(r, s), (l || u) && (!l || !u || l.configurable !== u.configurable || l.enumerable !== u.enumerable || l.writable !== u.writable)))
      return !1;
  return !0;
}
function hr(e, r) {
  return oe(e.valueOf(), r.valueOf());
}
function mr(e, r) {
  return e.source === r.source && e.flags === r.flags;
}
function Qe(e, r, t) {
  if (e.size !== r.size)
    return !1;
  for (var n = {}, i = e.values(), s, l; (s = i.next()) && !s.done; ) {
    for (var u = r.values(), a = !1, c = 0; (l = u.next()) && !l.done; )
      !a && !n[c] && (a = t.equals(s.value, l.value, s.value, l.value, e, r, t)) && (n[c] = !0), c++;
    if (!a)
      return !1;
  }
  return !0;
}
function pr(e, r) {
  var t = e.length;
  if (r.length !== t)
    return !1;
  for (; t-- > 0; )
    if (e[t] !== r[t])
      return !1;
  return !0;
}
var yr = "[object Arguments]", gr = "[object Boolean]", wr = "[object Date]", br = "[object Map]", Er = "[object Number]", Tr = "[object Object]", Or = "[object RegExp]", Sr = "[object Set]", Cr = "[object String]", Ir = Array.isArray, Ye = typeof ArrayBuffer == "function" && ArrayBuffer.isView ? ArrayBuffer.isView : null, Xe = Object.assign, _r = Object.prototype.toString.call.bind(Object.prototype.toString);
function Ar(e) {
  var r = e.areArraysEqual, t = e.areDatesEqual, n = e.areMapsEqual, i = e.areObjectsEqual, s = e.arePrimitiveWrappersEqual, l = e.areRegExpsEqual, u = e.areSetsEqual, a = e.areTypedArraysEqual;
  return function(o, d, h) {
    if (o === d)
      return !0;
    if (o == null || d == null || typeof o != "object" || typeof d != "object")
      return o !== o && d !== d;
    var p = o.constructor;
    if (p !== d.constructor)
      return !1;
    if (p === Object)
      return i(o, d, h);
    if (Ir(o))
      return r(o, d, h);
    if (Ye != null && Ye(o))
      return a(o, d, h);
    if (p === Date)
      return t(o, d, h);
    if (p === RegExp)
      return l(o, d, h);
    if (p === Map)
      return n(o, d, h);
    if (p === Set)
      return u(o, d, h);
    var f = _r(o);
    return f === wr ? t(o, d, h) : f === Or ? l(o, d, h) : f === br ? n(o, d, h) : f === Sr ? u(o, d, h) : f === Tr ? typeof o.then != "function" && typeof d.then != "function" && i(o, d, h) : f === yr ? i(o, d, h) : f === gr || f === Er || f === Cr ? s(o, d, h) : !1;
  };
}
function qr(e) {
  var r = e.circular, t = e.createCustomConfig, n = e.strict, i = {
    areArraysEqual: n ? ue : fr,
    areDatesEqual: dr,
    areMapsEqual: n ? Be(Ue, ue) : Ue,
    areObjectsEqual: n ? ue : vr,
    arePrimitiveWrappersEqual: hr,
    areRegExpsEqual: mr,
    areSetsEqual: n ? Be(Qe, ue) : Qe,
    areTypedArraysEqual: n ? ue : pr
  };
  if (t && (i = Xe({}, i, t(i))), r) {
    var s = be(i.areArraysEqual), l = be(i.areMapsEqual), u = be(i.areObjectsEqual), a = be(i.areSetsEqual);
    i = Xe({}, i, {
      areArraysEqual: s,
      areMapsEqual: l,
      areObjectsEqual: u,
      areSetsEqual: a
    });
  }
  return i;
}
function xr(e) {
  return function(r, t, n, i, s, l, u) {
    return e(r, t, u);
  };
}
function $r(e) {
  var r = e.circular, t = e.comparator, n = e.createState, i = e.equals, s = e.strict;
  if (n)
    return function(a, c) {
      var o = n(), d = o.cache, h = d === void 0 ? r ? /* @__PURE__ */ new WeakMap() : void 0 : d, p = o.meta;
      return t(a, c, {
        cache: h,
        equals: i,
        meta: p,
        strict: s
      });
    };
  if (r)
    return function(a, c) {
      return t(a, c, {
        cache: /* @__PURE__ */ new WeakMap(),
        equals: i,
        meta: void 0,
        strict: s
      });
    };
  var l = {
    cache: void 0,
    equals: i,
    meta: void 0,
    strict: s
  };
  return function(a, c) {
    return t(a, c, l);
  };
}
var he = X();
X({ strict: !0 });
X({ circular: !0 });
X({
  circular: !0,
  strict: !0
});
X({
  createInternalComparator: function() {
    return oe;
  }
});
X({
  strict: !0,
  createInternalComparator: function() {
    return oe;
  }
});
X({
  circular: !0,
  createInternalComparator: function() {
    return oe;
  }
});
X({
  circular: !0,
  createInternalComparator: function() {
    return oe;
  },
  strict: !0
});
function X(e) {
  e === void 0 && (e = {});
  var r = e.circular, t = r === void 0 ? !1 : r, n = e.createInternalComparator, i = e.createState, s = e.strict, l = s === void 0 ? !1 : s, u = qr(e), a = Ar(u), c = n ? n(a) : xr(a);
  return $r({ circular: t, comparator: a, createState: i, equals: c, strict: l });
}
function li(e, r) {
  return wt(e, {
    ...r,
    comparator: he
  });
}
function ci(e, r) {
  return wt(e, {
    ...r,
    comparator: (t, n) => {
      if (!n || t.length !== n.length)
        return !1;
      for (let i = 0, s = t.length; i < s; i++)
        if (t[i] !== (n == null ? void 0 : n[i]))
          return !1;
      return !0;
    }
  });
}
function fi(e) {
  const r = q(!!e);
  return [r, (n) => {
    const i = A(n), s = A(() => (r.value || (r.value = !0), n()));
    return Ht(s, { bypassed: i });
  }];
}
function jr(e) {
  return typeof e == "boolean" ? !e : Ie(e) ? A(() => !e.value) : () => !e();
}
var ce = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function Re(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
function Pr(e) {
  var r = typeof e;
  return e != null && (r == "object" || r == "function");
}
var Tt = Pr, kr = typeof ce == "object" && ce && ce.Object === Object && ce, Nr = kr, zr = Nr, Lr = typeof self == "object" && self && self.Object === Object && self, Rr = zr || Lr || Function("return this")(), Ot = Rr, Wr = Ot, Mr = function() {
  return Wr.Date.now();
}, Dr = Mr, Fr = /\s/;
function Br(e) {
  for (var r = e.length; r-- && Fr.test(e.charAt(r)); )
    ;
  return r;
}
var Vr = Br, Gr = Vr, Hr = /^\s+/;
function Ur(e) {
  return e && e.slice(0, Gr(e) + 1).replace(Hr, "");
}
var Qr = Ur, Yr = Ot, Xr = Yr.Symbol, St = Xr, Je = St, Ct = Object.prototype, Jr = Ct.hasOwnProperty, Zr = Ct.toString, le = Je ? Je.toStringTag : void 0;
function Kr(e) {
  var r = Jr.call(e, le), t = e[le];
  try {
    e[le] = void 0;
    var n = !0;
  } catch {
  }
  var i = Zr.call(e);
  return n && (r ? e[le] = t : delete e[le]), i;
}
var en = Kr, tn = Object.prototype, rn = tn.toString;
function nn(e) {
  return rn.call(e);
}
var sn = nn, Ze = St, an = en, on = sn, un = "[object Null]", ln = "[object Undefined]", Ke = Ze ? Ze.toStringTag : void 0;
function cn(e) {
  return e == null ? e === void 0 ? ln : un : Ke && Ke in Object(e) ? an(e) : on(e);
}
var fn = cn;
function dn(e) {
  return e != null && typeof e == "object";
}
var vn = dn, hn = fn, mn = vn, pn = "[object Symbol]";
function yn(e) {
  return typeof e == "symbol" || mn(e) && hn(e) == pn;
}
var gn = yn, wn = Qr, et = Tt, bn = gn, tt = NaN, En = /^[-+]0x[0-9a-f]+$/i, Tn = /^0b[01]+$/i, On = /^0o[0-7]+$/i, Sn = parseInt;
function Cn(e) {
  if (typeof e == "number")
    return e;
  if (bn(e))
    return tt;
  if (et(e)) {
    var r = typeof e.valueOf == "function" ? e.valueOf() : e;
    e = et(r) ? r + "" : r;
  }
  if (typeof e != "string")
    return e === 0 ? e : +e;
  e = wn(e);
  var t = Tn.test(e);
  return t || On.test(e) ? Sn(e.slice(2), t ? 2 : 8) : En.test(e) ? tt : +e;
}
var In = Cn, _n = Tt, xe = Dr, rt = In, An = "Expected a function", qn = Math.max, xn = Math.min;
function $n(e, r, t) {
  var n, i, s, l, u, a, c = 0, o = !1, d = !1, h = !0;
  if (typeof e != "function")
    throw new TypeError(An);
  r = rt(r) || 0, _n(t) && (o = !!t.leading, d = "maxWait" in t, s = d ? qn(rt(t.maxWait) || 0, r) : s, h = "trailing" in t ? !!t.trailing : h);
  function p(O) {
    var j = n, N = i;
    return n = i = void 0, c = O, l = e.apply(N, j), l;
  }
  function f(O) {
    return c = O, u = setTimeout(y, r), o ? p(O) : l;
  }
  function m(O) {
    var j = O - a, N = O - c, We = r - j;
    return d ? xn(We, s - N) : We;
  }
  function v(O) {
    var j = O - a, N = O - c;
    return a === void 0 || j >= r || j < 0 || d && N >= s;
  }
  function y() {
    var O = xe();
    if (v(O))
      return w(O);
    u = setTimeout(y, m(O));
  }
  function w(O) {
    return u = void 0, h && n ? p(O) : (n = i = void 0, l);
  }
  function b() {
    u !== void 0 && clearTimeout(u), c = 0, n = a = i = u = void 0;
  }
  function S() {
    return u === void 0 ? l : w(xe());
  }
  function _() {
    var O = xe(), j = v(O);
    if (n = arguments, i = this, a = O, j) {
      if (u === void 0)
        return f(a);
      if (d)
        return clearTimeout(u), u = setTimeout(y, r), p(a);
    }
    return u === void 0 && (u = setTimeout(y, r)), l;
  }
  return _.cancel = b, _.flush = S, _;
}
var jn = $n;
const Pn = /* @__PURE__ */ Re(jn), J = {
  year: 365 * 24 * 60 * 60 * 1e3,
  month: 30 * 24 * 60 * 60 * 1e3,
  date: 24 * 60 * 60 * 1e3,
  hours: 60 * 60 * 1e3,
  minutes: 60 * 1e3,
  seconds: 1e3,
  milliseconds: 1
}, kn = (e, r) => ((e.year ?? 0) * J.year + (e.month ?? 0) * J.month + (e.date ?? 0) * J.date + (e.hours ?? 0) * J.hours + (e.minutes ?? 0) * J.minutes + (e.seconds ?? 0) * J.seconds + (e.milliseconds ?? 0)) / J[r];
function Nn() {
  const e = {
    state: "pending"
  };
  return e.promise = new Promise((r, t) => {
    e.resolve = (n) => {
      e.state = "resolved", r(n);
    }, e.reject = (n) => {
      e.state = "rejected", t(n);
    };
  }), e;
}
let zn = class extends Error {
  constructor(r) {
    super(r), this.name = "TimeoutError";
  }
};
function vi(e, r) {
  const t = Nn(), n = k(e);
  if (n)
    return t.resolve(n), t.promise;
  let i;
  function s() {
    l(), clearTimeout(i);
  }
  (r == null ? void 0 : r.timeout) !== void 0 && (i = setTimeout(() => {
    t.reject(new zn("Timeout")), s();
  }, r.timeout));
  const l = I(e, (u) => {
    u && (s(), t.resolve(u));
  });
  return t.promise.finally(s), t.promise;
}
function hi(e, r, t) {
  return A(
    {
      get() {
        return e[r];
      },
      set(n) {
        e[r] = n;
      }
    },
    t
  );
}
const Ln = (e, r, t) => {
  const n = _e(e), i = ht(n, r, t);
  return A({
    get: () => i.value,
    set: (s) => {
      n.value = s;
    }
  });
};
function mi(e, r, t) {
  const n = A({
    get() {
      return e.value;
    },
    set(i) {
      r(i), e.value = i, t == null || t(i);
    }
  });
  return n.bypass = (i) => {
    e.value = i;
  }, n;
}
function pi(e, r, t = {}) {
  const {
    debounce: n = 0,
    maxWait: i = void 0,
    ...s
  } = t, { eventFilter: l, pause: u, resume: a, isActive: c } = Yt(Le(n, { maxWait: i }));
  return { stop: mt(
    e,
    r,
    {
      ...s,
      eventFilter: l
    }
  ), pause: u, resume: a, isActive: c };
}
const yi = (e, r, t) => {
  const n = Object.entries(e), i = n.map(([l]) => l), s = n.map(([, l]) => l);
  return I(
    s,
    (l, u) => {
      const a = l.reduce(
        (o, d, h) => (o[i[h]] = d, o),
        {}
      ), c = u.reduce(
        (o, d, h) => (o[i[h]] = d, o),
        {}
      );
      r(a, c);
    },
    t
  );
}, gi = (e, r, t) => {
  const n = Object.entries(e), i = n.map(([l]) => l), s = n.map(([, l]) => l);
  return gt(
    s,
    (l, u) => {
      const a = l.reduce(
        (o, d, h) => (o[i[h]] = d, o),
        {}
      ), c = u.reduce(
        (o, d, h) => (o[i[h]] = d, o),
        {}
      );
      r(a, c);
    },
    t
  );
};
function Rn(e, r, t) {
  const { onlyIf: n, compareDeepEqual: i, ...s } = t ?? {};
  if ((t == null ? void 0 : t.onlyIf) === void 0)
    return I(e, r, t);
  let l;
  function u() {
    if (!l) return;
    const p = l;
    l = void 0, p();
  }
  function a(p) {
    l = p;
  }
  const c = E(!0), o = (p, f) => {
    if (c.value = !1, !(i && he(p, f)))
      return u(), r(p, f, a);
  };
  let d;
  const h = () => {
    o(It(e), d);
  };
  return I(_e(n), (p) => {
    c.value && p && h();
  }), I(
    e,
    (p, f) => {
      if (d = f, !k(n)) {
        c.value = !0;
        return;
      }
      o(p, f);
    },
    s
  );
}
function Wn(e, r, t) {
  const { onlyIf: n, debounce: i, maxWait: s, compareDeepEqual: l, ...u } = t ?? {};
  if ((t == null ? void 0 : t.onlyIf) === void 0)
    return gt(e, r, t);
  let a;
  function c() {
    if (!a) return;
    const v = a;
    a = void 0, v();
  }
  function o(v) {
    a = v;
  }
  const d = E(!0), h = (v, y) => {
    if (d.value = !1, !(l && he(v, y)))
      return c(), r(v, y, o);
  };
  let p;
  I(_e(n), (v) => {
    d.value && v && m();
  }, { flush: "sync" });
  const f = A(
    () => Pn(
      (v, y) => {
        h(v, y);
      },
      k(i) ?? 0,
      { maxWait: k(s) }
    )
  ), m = () => {
    f.value(It(e), p);
  };
  return I(
    e,
    (v, y) => {
      if (p = y, !k(n)) {
        d.value = !0;
        return;
      }
      f.value(v, y);
    },
    u
  );
}
function It(e) {
  return Mt(e) ? e : Array.isArray(e) ? e.map((r) => k(r)) : k(e);
}
const wi = /* @__PURE__ */ Y({
  __name: "LazyRender",
  props: {
    minHeight: {},
    unrenderedClass: { default: void 0 }
  },
  setup(e) {
    const r = q(), t = Ln(!1, 100);
    return nr(r, ([{ isIntersecting: n }]) => {
      t.value = n;
    }), (n, i) => (ve(), ze(
      "div",
      {
        ref_key: "target",
        ref: r,
        style: B({ "min-height": typeof n.minHeight == "number" ? `${n.minHeight}px` : n.minHeight }),
        class: D(n.unrenderedClass ? { [n.unrenderedClass]: !de(t) } : {})
      },
      [
        de(t) ? V(n.$slots, "default", { key: 0 }) : Dt("v-if", !0)
      ],
      6
      /* CLASS, STYLE */
    ));
  }
}), Mn = /* @__PURE__ */ Y({
  __name: "ScrollShadowContainer",
  props: {
    zIndex: { default: void 0 }
  },
  setup(e) {
    const r = q(null), t = q(null), n = q(void 0), i = q(void 0), s = fe({
      top: !1,
      right: !1,
      bottom: !1,
      left: !1
    });
    async function l() {
      var a, c;
      n.value = void 0, i.value = void 0, await Ne(), n.value = (a = r.value) == null ? void 0 : a.clientWidth, i.value = (c = r.value) == null ? void 0 : c.clientHeight;
    }
    function u() {
      const a = r.value;
      if (!a)
        return;
      const c = a.clientWidth < a.scrollWidth, o = a.clientHeight < a.scrollHeight, d = a.offsetWidth + a.scrollLeft, h = a.offsetHeight + a.scrollTop, p = a.scrollTop === 0, f = d >= a.scrollWidth - 2, m = h >= a.scrollHeight - 2, v = a.scrollLeft === 0;
      s.top = o && !p, s.right = c && !f, s.bottom = o && !m, s.left = c && !v;
    }
    return Ce(r, () => {
      u();
    }), Ce(t, () => {
      l();
    }), (a, c) => (ve(), ze(
      "div",
      {
        ref_key: "el",
        ref: t,
        class: D(a.$style.wrap)
      },
      [
        ee(
          "div",
          {
            ref_key: "scrollContainer",
            ref: r,
            class: D(a.$style["scroll-container"]),
            style: B({ width: n.value, height: i.value }),
            onScrollPassive: u
          },
          [
            V(a.$slots, "default"),
            ee(
              "span",
              {
                class: D([a.$style["shadow-top"], s.top && a.$style["is-active"]]),
                style: B({ zIndex: a.zIndex })
              },
              null,
              6
              /* CLASS, STYLE */
            ),
            ee(
              "span",
              {
                class: D([a.$style["shadow-right"], s.right && a.$style["is-active"]]),
                style: B({ zIndex: a.zIndex })
              },
              null,
              6
              /* CLASS, STYLE */
            ),
            ee(
              "span",
              {
                class: D([a.$style["shadow-bottom"], s.bottom && a.$style["is-active"]]),
                style: B({ zIndex: a.zIndex })
              },
              null,
              6
              /* CLASS, STYLE */
            ),
            ee(
              "span",
              {
                class: D([a.$style["shadow-left"], s.left && a.$style["is-active"]]),
                style: B({ zIndex: a.zIndex })
              },
              null,
              6
              /* CLASS, STYLE */
            )
          ],
          38
          /* CLASS, STYLE, NEED_HYDRATION */
        )
      ],
      2
      /* CLASS */
    ));
  }
}), Dn = "_wrap_12rlz_1", Fn = {
  wrap: Dn,
  "scroll-container": "_scroll-container_12rlz_6",
  "shadow-top": "_shadow-top_12rlz_10",
  "shadow-right": "_shadow-right_12rlz_11",
  "shadow-bottom": "_shadow-bottom_12rlz_12",
  "shadow-left": "_shadow-left_12rlz_13",
  "is-active": "_is-active_12rlz_59"
}, Bn = (e, r) => {
  const t = e.__vccOpts || e;
  for (const [n, i] of r)
    t[n] = i;
  return t;
}, Vn = {
  $style: Fn
}, bi = /* @__PURE__ */ Bn(Mn, [["__cssModules", Vn]]), Ei = /* @__PURE__ */ Y({
  __name: "StickyContent",
  props: {
    tag: { default: "div" },
    top: { default: 0 },
    bottom: { default: 0 },
    maxHeightScreen: { type: Boolean, default: !1 }
  },
  setup(e) {
    const r = e, t = q(), n = q(), i = fe(sr()), s = fe(
      Fe(t, {
        windowResize: !0,
        windowScroll: !0
      })
    ), l = fe(
      Fe(n, {
        windowResize: !0,
        windowScroll: !0
      })
    ), u = or(
      () => {
        const a = {
          transitionDuration: "75ms",
          height: r.maxHeightScreen ? `${i.height - (s.y > 0 ? s.y : 0) - r.top - r.bottom}px` : void 0,
          overflowY: r.maxHeightScreen ? "auto" : void 0
        }, c = s.y;
        if (c - r.top > 0)
          a.transform = "translateY(0px)";
        else {
          let o = -c + r.top;
          l.height + r.top + r.bottom > s.bottom && (o = s.height - l.height - r.bottom - r.top), a.transform = `translateY(${o < 0 ? 0 : o}px)`;
        }
        return a;
      },
      10,
      { maxWait: 75 }
    );
    return (a, c) => (ve(), ut(
      lt(a.tag),
      Ft({
        ref_key: "refContainer",
        ref: t
      }, a.$attrs),
      {
        default: ct(() => [
          ee(
            "div",
            {
              ref_key: "refChild",
              ref: n,
              style: B(de(u))
            },
            [
              V(a.$slots, "default")
            ],
            4
            /* STYLE */
          )
        ]),
        _: 3
        /* FORWARDED */
      },
      16
      /* FULL_PROPS */
    ));
  }
}), Ti = /* @__PURE__ */ Y({
  __name: "TemplateVars",
  props: {
    vars: {}
  },
  setup(e) {
    return (r, t) => V(r.$slots, "default", Bt(Vt(r.vars)));
  }
}), Oi = (e, r) => {
  const t = typeof (r == null ? void 0 : r.interval) == "object" ? kn(r.interval, "milliseconds") : r == null ? void 0 : r.interval, n = ir({ interval: t }), i = E(e(n.value));
  return I(n, () => {
    const s = e(n.value);
    s.getTime() !== i.value.getTime() && (i.value = s);
  }), H(i);
}, Si = () => {
  const e = q(!1);
  return W(() => {
    e.value = !0;
  }), H(e);
}, Ci = (e) => {
  const r = E(k(e));
  if (!r.value) {
    const t = I(
      () => !!k(e) && !r.value,
      (n) => {
        n && (r.value = !0, t());
      }
    );
  }
  return H(r);
};
var _t = { exports: {} };
/* NProgress, (c) 2013, 2014 Rico Sta. Cruz - http://ricostacruz.com/nprogress
 * @license MIT */
(function(e, r) {
  (function(t, n) {
    e.exports = n();
  })(ce, function() {
    var t = {};
    t.version = "0.2.0";
    var n = t.settings = {
      minimum: 0.08,
      easing: "ease",
      positionUsing: "",
      speed: 200,
      trickle: !0,
      trickleRate: 0.02,
      trickleSpeed: 800,
      showSpinner: !0,
      barSelector: '[role="bar"]',
      spinnerSelector: '[role="spinner"]',
      parent: "body",
      template: '<div class="bar" role="bar"><div class="peg"></div></div><div class="spinner" role="spinner"><div class="spinner-icon"></div></div>'
    };
    t.configure = function(f) {
      var m, v;
      for (m in f)
        v = f[m], v !== void 0 && f.hasOwnProperty(m) && (n[m] = v);
      return this;
    }, t.status = null, t.set = function(f) {
      var m = t.isStarted();
      f = i(f, n.minimum, 1), t.status = f === 1 ? null : f;
      var v = t.render(!m), y = v.querySelector(n.barSelector), w = n.speed, b = n.easing;
      return v.offsetWidth, u(function(S) {
        n.positionUsing === "" && (n.positionUsing = t.getPositioningCSS()), a(y, l(f, w, b)), f === 1 ? (a(v, {
          transition: "none",
          opacity: 1
        }), v.offsetWidth, setTimeout(function() {
          a(v, {
            transition: "all " + w + "ms linear",
            opacity: 0
          }), setTimeout(function() {
            t.remove(), S();
          }, w);
        }, w)) : setTimeout(S, w);
      }), this;
    }, t.isStarted = function() {
      return typeof t.status == "number";
    }, t.start = function() {
      t.status || t.set(0);
      var f = function() {
        setTimeout(function() {
          t.status && (t.trickle(), f());
        }, n.trickleSpeed);
      };
      return n.trickle && f(), this;
    }, t.done = function(f) {
      return !f && !t.status ? this : t.inc(0.3 + 0.5 * Math.random()).set(1);
    }, t.inc = function(f) {
      var m = t.status;
      return m ? (typeof f != "number" && (f = (1 - m) * i(Math.random() * m, 0.1, 0.95)), m = i(m + f, 0, 0.994), t.set(m)) : t.start();
    }, t.trickle = function() {
      return t.inc(Math.random() * n.trickleRate);
    }, function() {
      var f = 0, m = 0;
      t.promise = function(v) {
        return !v || v.state() === "resolved" ? this : (m === 0 && t.start(), f++, m++, v.always(function() {
          m--, m === 0 ? (f = 0, t.done()) : t.set((f - m) / f);
        }), this);
      };
    }(), t.render = function(f) {
      if (t.isRendered()) return document.getElementById("nprogress");
      o(document.documentElement, "nprogress-busy");
      var m = document.createElement("div");
      m.id = "nprogress", m.innerHTML = n.template;
      var v = m.querySelector(n.barSelector), y = f ? "-100" : s(t.status || 0), w = document.querySelector(n.parent), b;
      return a(v, {
        transition: "all 0 linear",
        transform: "translate3d(" + y + "%,0,0)"
      }), n.showSpinner || (b = m.querySelector(n.spinnerSelector), b && p(b)), w != document.body && o(w, "nprogress-custom-parent"), w.appendChild(m), m;
    }, t.remove = function() {
      d(document.documentElement, "nprogress-busy"), d(document.querySelector(n.parent), "nprogress-custom-parent");
      var f = document.getElementById("nprogress");
      f && p(f);
    }, t.isRendered = function() {
      return !!document.getElementById("nprogress");
    }, t.getPositioningCSS = function() {
      var f = document.body.style, m = "WebkitTransform" in f ? "Webkit" : "MozTransform" in f ? "Moz" : "msTransform" in f ? "ms" : "OTransform" in f ? "O" : "";
      return m + "Perspective" in f ? "translate3d" : m + "Transform" in f ? "translate" : "margin";
    };
    function i(f, m, v) {
      return f < m ? m : f > v ? v : f;
    }
    function s(f) {
      return (-1 + f) * 100;
    }
    function l(f, m, v) {
      var y;
      return n.positionUsing === "translate3d" ? y = { transform: "translate3d(" + s(f) + "%,0,0)" } : n.positionUsing === "translate" ? y = { transform: "translate(" + s(f) + "%,0)" } : y = { "margin-left": s(f) + "%" }, y.transition = "all " + m + "ms " + v, y;
    }
    var u = /* @__PURE__ */ function() {
      var f = [];
      function m() {
        var v = f.shift();
        v && v(m);
      }
      return function(v) {
        f.push(v), f.length == 1 && m();
      };
    }(), a = /* @__PURE__ */ function() {
      var f = ["Webkit", "O", "Moz", "ms"], m = {};
      function v(S) {
        return S.replace(/^-ms-/, "ms-").replace(/-([\da-z])/gi, function(_, O) {
          return O.toUpperCase();
        });
      }
      function y(S) {
        var _ = document.body.style;
        if (S in _) return S;
        for (var O = f.length, j = S.charAt(0).toUpperCase() + S.slice(1), N; O--; )
          if (N = f[O] + j, N in _) return N;
        return S;
      }
      function w(S) {
        return S = v(S), m[S] || (m[S] = y(S));
      }
      function b(S, _, O) {
        _ = w(_), S.style[_] = O;
      }
      return function(S, _) {
        var O = arguments, j, N;
        if (O.length == 2)
          for (j in _)
            N = _[j], N !== void 0 && _.hasOwnProperty(j) && b(S, j, N);
        else
          b(S, O[1], O[2]);
      };
    }();
    function c(f, m) {
      var v = typeof f == "string" ? f : h(f);
      return v.indexOf(" " + m + " ") >= 0;
    }
    function o(f, m) {
      var v = h(f), y = v + m;
      c(v, m) || (f.className = y.substring(1));
    }
    function d(f, m) {
      var v = h(f), y;
      c(f, m) && (y = v.replace(" " + m + " ", " "), f.className = y.substring(1, y.length - 1));
    }
    function h(f) {
      return (" " + (f.className || "") + " ").replace(/\s+/gi, " ");
    }
    function p(f) {
      f && f.parentNode && f.parentNode.removeChild(f);
    }
    return t;
  });
})(_t);
var Gn = _t.exports;
const te = /* @__PURE__ */ Re(Gn);
function Hn(e = { clearErrorOnNewExecute: !0 }) {
  const r = E(!1), t = q(null), n = q(null);
  let i = null;
  return {
    isLoading: r,
    hasError: t,
    result: n,
    fn: (l) => (...u) => i || (r.value = !0, e.useNProgress && te.start(), i = l(...u), e.clearErrorOnNewExecute && (t.value = null), e.clearOnNewExecute && (n.value = null), i.then((a) => {
      var c;
      n.value = a, (c = e.onResult) == null || c.call(e, a, ...u);
    }), i.catch((a) => {
      var c;
      t.value = a, (c = e.onError) == null || c.call(e, a, ...u);
    }), i.finally(() => {
      var a;
      e.useNProgress && te.done(), i = null, r.value = !1, (a = e.onFinally) == null || a.call(e, ...u);
    }), i)
  };
}
function Ii(e, r = { clearErrorOnNewExecute: !0 }) {
  const t = Hn(r), n = t.fn(e);
  return {
    ...t,
    execute: n
  };
}
function _i(e, r, t) {
  e.on(r, t), W(() => {
    e.off(r, t);
  });
}
var At = { exports: {} };
(function(e) {
  var r = Object.prototype.hasOwnProperty, t = "~";
  function n() {
  }
  Object.create && (n.prototype = /* @__PURE__ */ Object.create(null), new n().__proto__ || (t = !1));
  function i(a, c, o) {
    this.fn = a, this.context = c, this.once = o || !1;
  }
  function s(a, c, o, d, h) {
    if (typeof o != "function")
      throw new TypeError("The listener must be a function");
    var p = new i(o, d || a, h), f = t ? t + c : c;
    return a._events[f] ? a._events[f].fn ? a._events[f] = [a._events[f], p] : a._events[f].push(p) : (a._events[f] = p, a._eventsCount++), a;
  }
  function l(a, c) {
    --a._eventsCount === 0 ? a._events = new n() : delete a._events[c];
  }
  function u() {
    this._events = new n(), this._eventsCount = 0;
  }
  u.prototype.eventNames = function() {
    var c = [], o, d;
    if (this._eventsCount === 0) return c;
    for (d in o = this._events)
      r.call(o, d) && c.push(t ? d.slice(1) : d);
    return Object.getOwnPropertySymbols ? c.concat(Object.getOwnPropertySymbols(o)) : c;
  }, u.prototype.listeners = function(c) {
    var o = t ? t + c : c, d = this._events[o];
    if (!d) return [];
    if (d.fn) return [d.fn];
    for (var h = 0, p = d.length, f = new Array(p); h < p; h++)
      f[h] = d[h].fn;
    return f;
  }, u.prototype.listenerCount = function(c) {
    var o = t ? t + c : c, d = this._events[o];
    return d ? d.fn ? 1 : d.length : 0;
  }, u.prototype.emit = function(c, o, d, h, p, f) {
    var m = t ? t + c : c;
    if (!this._events[m]) return !1;
    var v = this._events[m], y = arguments.length, w, b;
    if (v.fn) {
      switch (v.once && this.removeListener(c, v.fn, void 0, !0), y) {
        case 1:
          return v.fn.call(v.context), !0;
        case 2:
          return v.fn.call(v.context, o), !0;
        case 3:
          return v.fn.call(v.context, o, d), !0;
        case 4:
          return v.fn.call(v.context, o, d, h), !0;
        case 5:
          return v.fn.call(v.context, o, d, h, p), !0;
        case 6:
          return v.fn.call(v.context, o, d, h, p, f), !0;
      }
      for (b = 1, w = new Array(y - 1); b < y; b++)
        w[b - 1] = arguments[b];
      v.fn.apply(v.context, w);
    } else {
      var S = v.length, _;
      for (b = 0; b < S; b++)
        switch (v[b].once && this.removeListener(c, v[b].fn, void 0, !0), y) {
          case 1:
            v[b].fn.call(v[b].context);
            break;
          case 2:
            v[b].fn.call(v[b].context, o);
            break;
          case 3:
            v[b].fn.call(v[b].context, o, d);
            break;
          case 4:
            v[b].fn.call(v[b].context, o, d, h);
            break;
          default:
            if (!w) for (_ = 1, w = new Array(y - 1); _ < y; _++)
              w[_ - 1] = arguments[_];
            v[b].fn.apply(v[b].context, w);
        }
    }
    return !0;
  }, u.prototype.on = function(c, o, d) {
    return s(this, c, o, d, !1);
  }, u.prototype.once = function(c, o, d) {
    return s(this, c, o, d, !0);
  }, u.prototype.removeListener = function(c, o, d, h) {
    var p = t ? t + c : c;
    if (!this._events[p]) return this;
    if (!o)
      return l(this, p), this;
    var f = this._events[p];
    if (f.fn)
      f.fn === o && (!h || f.once) && (!d || f.context === d) && l(this, p);
    else {
      for (var m = 0, v = [], y = f.length; m < y; m++)
        (f[m].fn !== o || h && !f[m].once || d && f[m].context !== d) && v.push(f[m]);
      v.length ? this._events[p] = v.length === 1 ? v[0] : v : l(this, p);
    }
    return this;
  }, u.prototype.removeAllListeners = function(c) {
    var o;
    return c ? (o = t ? t + c : c, this._events[o] && l(this, o)) : (this._events = new n(), this._eventsCount = 0), this;
  }, u.prototype.off = u.prototype.removeListener, u.prototype.addListener = u.prototype.on, u.prefixed = t, u.EventEmitter = u, e.exports = u;
})(At);
var Un = At.exports;
const Qn = /* @__PURE__ */ Re(Un);
class qt extends Error {
  constructor(r) {
    super(r), this.name = "TimeoutError";
  }
}
class Yn extends Error {
  constructor(r) {
    super(), this.name = "AbortError", this.message = r;
  }
}
const nt = (e) => globalThis.DOMException === void 0 ? new Yn(e) : new DOMException(e), it = (e) => {
  const r = e.reason === void 0 ? nt("This operation was aborted.") : e.reason;
  return r instanceof Error ? r : nt(r);
};
function Xn(e, r) {
  const {
    milliseconds: t,
    fallback: n,
    message: i,
    customTimers: s = { setTimeout, clearTimeout }
  } = r;
  let l;
  const a = new Promise((c, o) => {
    if (typeof t != "number" || Math.sign(t) !== 1)
      throw new TypeError(`Expected \`milliseconds\` to be a positive number, got \`${t}\``);
    if (r.signal) {
      const { signal: h } = r;
      h.aborted && o(it(h)), h.addEventListener("abort", () => {
        o(it(h));
      });
    }
    if (t === Number.POSITIVE_INFINITY) {
      e.then(c, o);
      return;
    }
    const d = new qt();
    l = s.setTimeout.call(void 0, () => {
      if (n) {
        try {
          c(n());
        } catch (h) {
          o(h);
        }
        return;
      }
      typeof e.cancel == "function" && e.cancel(), i === !1 ? c() : i instanceof Error ? o(i) : (d.message = i ?? `Promise timed out after ${t} milliseconds`, o(d));
    }, t), (async () => {
      try {
        c(await e);
      } catch (h) {
        o(h);
      }
    })();
  }).finally(() => {
    a.clear();
  });
  return a.clear = () => {
    s.clearTimeout.call(void 0, l), l = void 0;
  }, a;
}
function Jn(e, r, t) {
  let n = 0, i = e.length;
  for (; i > 0; ) {
    const s = Math.trunc(i / 2);
    let l = n + s;
    t(e[l], r) <= 0 ? (n = ++l, i -= s + 1) : i = s;
  }
  return n;
}
var M;
class Zn {
  constructor() {
    x(this, M, []);
  }
  enqueue(r, t) {
    t = {
      priority: 0,
      ...t
    };
    const n = {
      priority: t.priority,
      run: r
    };
    if (this.size && g(this, M)[this.size - 1].priority >= t.priority) {
      g(this, M).push(n);
      return;
    }
    const i = Jn(g(this, M), n, (s, l) => l.priority - s.priority);
    g(this, M).splice(i, 0, n);
  }
  dequeue() {
    const r = g(this, M).shift();
    return r == null ? void 0 : r.run;
  }
  filter(r) {
    return g(this, M).filter((t) => t.priority === r.priority).map((t) => t.run);
  }
  get size() {
    return g(this, M).length;
  }
}
M = new WeakMap();
var re, ne, Q, me, ie, pe, z, se, P, ye, L, ae, F, ge, T, xt, $t, jt, Pt, kt, Te, Pe, ke, Oe, Nt, Se;
class Kn extends Qn {
  // TODO: The `throwOnTimeout` option should affect the return types of `add()` and `addAll()`
  constructor(t) {
    var n, i;
    super();
    x(this, T);
    x(this, re);
    x(this, ne);
    x(this, Q, 0);
    x(this, me);
    x(this, ie);
    x(this, pe, 0);
    x(this, z);
    x(this, se);
    x(this, P);
    x(this, ye);
    x(this, L, 0);
    // The `!` is needed because of https://github.com/microsoft/TypeScript/issues/32194
    x(this, ae);
    x(this, F);
    x(this, ge);
    /**
        Per-operation timeout in milliseconds. Operations fulfill once `timeout` elapses if they haven't already.
    
        Applies to each future operation.
        */
    De(this, "timeout");
    if (t = {
      carryoverConcurrencyCount: !1,
      intervalCap: Number.POSITIVE_INFINITY,
      interval: 0,
      concurrency: Number.POSITIVE_INFINITY,
      autoStart: !0,
      queueClass: Zn,
      ...t
    }, !(typeof t.intervalCap == "number" && t.intervalCap >= 1))
      throw new TypeError(`Expected \`intervalCap\` to be a number from 1 and up, got \`${((n = t.intervalCap) == null ? void 0 : n.toString()) ?? ""}\` (${typeof t.intervalCap})`);
    if (t.interval === void 0 || !(Number.isFinite(t.interval) && t.interval >= 0))
      throw new TypeError(`Expected \`interval\` to be a finite number >= 0, got \`${((i = t.interval) == null ? void 0 : i.toString()) ?? ""}\` (${typeof t.interval})`);
    C(this, re, t.carryoverConcurrencyCount), C(this, ne, t.intervalCap === Number.POSITIVE_INFINITY || t.interval === 0), C(this, me, t.intervalCap), C(this, ie, t.interval), C(this, P, new t.queueClass()), C(this, ye, t.queueClass), this.concurrency = t.concurrency, this.timeout = t.timeout, C(this, ge, t.throwOnTimeout === !0), C(this, F, t.autoStart === !1);
  }
  get concurrency() {
    return g(this, ae);
  }
  set concurrency(t) {
    if (!(typeof t == "number" && t >= 1))
      throw new TypeError(`Expected \`concurrency\` to be a number from 1 and up, got \`${t}\` (${typeof t})`);
    C(this, ae, t), $(this, T, Oe).call(this);
  }
  async add(t, n = {}) {
    return n = {
      timeout: this.timeout,
      throwOnTimeout: g(this, ge),
      ...n
    }, new Promise((i, s) => {
      g(this, P).enqueue(async () => {
        var l;
        we(this, L)._++, we(this, Q)._++;
        try {
          (l = n.signal) == null || l.throwIfAborted();
          let u = t({ signal: n.signal });
          n.timeout && (u = Xn(Promise.resolve(u), { milliseconds: n.timeout })), n.signal && (u = Promise.race([u, $(this, T, Nt).call(this, n.signal)]));
          const a = await u;
          i(a), this.emit("completed", a);
        } catch (u) {
          if (u instanceof qt && !n.throwOnTimeout) {
            i();
            return;
          }
          s(u), this.emit("error", u);
        } finally {
          $(this, T, jt).call(this);
        }
      }, n), this.emit("add"), $(this, T, Te).call(this);
    });
  }
  async addAll(t, n) {
    return Promise.all(t.map(async (i) => this.add(i, n)));
  }
  /**
  Start (or resume) executing enqueued tasks within concurrency limit. No need to call this if queue is not paused (via `options.autoStart = false` or by `.pause()` method.)
  */
  start() {
    return g(this, F) ? (C(this, F, !1), $(this, T, Oe).call(this), this) : this;
  }
  /**
  Put queue execution on hold.
  */
  pause() {
    C(this, F, !0);
  }
  /**
  Clear the queue.
  */
  clear() {
    C(this, P, new (g(this, ye))());
  }
  /**
      Can be called multiple times. Useful if you for example add additional items at a later time.
  
      @returns A promise that settles when the queue becomes empty.
      */
  async onEmpty() {
    g(this, P).size !== 0 && await $(this, T, Se).call(this, "empty");
  }
  /**
      @returns A promise that settles when the queue size is less than the given limit: `queue.size < limit`.
  
      If you want to avoid having the queue grow beyond a certain size you can `await queue.onSizeLessThan()` before adding a new item.
  
      Note that this only limits the number of items waiting to start. There could still be up to `concurrency` jobs already running that this call does not include in its calculation.
      */
  async onSizeLessThan(t) {
    g(this, P).size < t || await $(this, T, Se).call(this, "next", () => g(this, P).size < t);
  }
  /**
      The difference with `.onEmpty` is that `.onIdle` guarantees that all work from the queue has finished. `.onEmpty` merely signals that the queue is empty, but it could mean that some promises haven't completed yet.
  
      @returns A promise that settles when the queue becomes empty, and all promises have completed; `queue.size === 0 && queue.pending === 0`.
      */
  async onIdle() {
    g(this, L) === 0 && g(this, P).size === 0 || await $(this, T, Se).call(this, "idle");
  }
  /**
  Size of the queue, the number of queued items waiting to run.
  */
  get size() {
    return g(this, P).size;
  }
  /**
      Size of the queue, filtered by the given options.
  
      For example, this can be used to find the number of items remaining in the queue with a specific priority level.
      */
  sizeBy(t) {
    return g(this, P).filter(t).length;
  }
  /**
  Number of running items (no longer in the queue).
  */
  get pending() {
    return g(this, L);
  }
  /**
  Whether the queue is currently paused.
  */
  get isPaused() {
    return g(this, F);
  }
}
re = new WeakMap(), ne = new WeakMap(), Q = new WeakMap(), me = new WeakMap(), ie = new WeakMap(), pe = new WeakMap(), z = new WeakMap(), se = new WeakMap(), P = new WeakMap(), ye = new WeakMap(), L = new WeakMap(), ae = new WeakMap(), F = new WeakMap(), ge = new WeakMap(), T = new WeakSet(), xt = function() {
  return g(this, ne) || g(this, Q) < g(this, me);
}, $t = function() {
  return g(this, L) < g(this, ae);
}, jt = function() {
  we(this, L)._--, $(this, T, Te).call(this), this.emit("next");
}, Pt = function() {
  $(this, T, ke).call(this), $(this, T, Pe).call(this), C(this, se, void 0);
}, kt = function() {
  const t = Date.now();
  if (g(this, z) === void 0) {
    const n = g(this, pe) - t;
    if (n < 0)
      C(this, Q, g(this, re) ? g(this, L) : 0);
    else
      return g(this, se) === void 0 && C(this, se, setTimeout(() => {
        $(this, T, Pt).call(this);
      }, n)), !0;
  }
  return !1;
}, Te = function() {
  if (g(this, P).size === 0)
    return g(this, z) && clearInterval(g(this, z)), C(this, z, void 0), this.emit("empty"), g(this, L) === 0 && this.emit("idle"), !1;
  if (!g(this, F)) {
    const t = !g(this, T, kt);
    if (g(this, T, xt) && g(this, T, $t)) {
      const n = g(this, P).dequeue();
      return n ? (this.emit("active"), n(), t && $(this, T, Pe).call(this), !0) : !1;
    }
  }
  return !1;
}, Pe = function() {
  g(this, ne) || g(this, z) !== void 0 || (C(this, z, setInterval(() => {
    $(this, T, ke).call(this);
  }, g(this, ie))), C(this, pe, Date.now() + g(this, ie)));
}, ke = function() {
  g(this, Q) === 0 && g(this, L) === 0 && g(this, z) && (clearInterval(g(this, z)), C(this, z, void 0)), C(this, Q, g(this, re) ? g(this, L) : 0), $(this, T, Oe).call(this);
}, /**
Executes all queued functions until it reaches the limit.
*/
Oe = function() {
  for (; $(this, T, Te).call(this); )
    ;
}, Nt = async function(t) {
  return new Promise((n, i) => {
    t.addEventListener("abort", () => {
      i(t.reason);
    }, { once: !0 });
  });
}, Se = async function(t, n) {
  return new Promise((i) => {
    const s = () => {
      n && !n() || (this.off(t, s), i());
    };
    this.on(t, s);
  });
};
function Ai(e, r = {}) {
  const t = new Kn({ concurrency: 1 }), n = q(!0), i = q(!1), s = q(null), l = q(null);
  t.on("idle", () => {
    i.value = !1, r.useNProgress && te.done();
  }).on("add", () => {
    i.value = !0, r.useNProgress && te.start();
  }).on("next", () => {
    t.size !== 0 && r.useNProgress && te.inc();
  });
  function u(...a) {
    return t.add(async () => {
      try {
        const c = await e(...a);
        return l.value = c, n.value = !1, c;
      } catch (c) {
        throw t.size === 0 && r.useNProgress && te.done(), s.value = c, c;
      }
    });
  }
  return W(() => {
    t.clear(), t.removeAllListeners();
  }), {
    call: u,
    result: l,
    hasError: s,
    isLoading: H(i),
    isPending: n
  };
}
const ei = {
  s: 1e3,
  m: 1e3 * 60,
  h: 1e3 * 60 * 60,
  d: 1e3 * 60 * 60 * 24,
  w: 1e3 * 60 * 60 * 24 * 7,
  M: 1e3 * 60 * 60 * 24 * 30,
  y: 1e3 * 60 * 60 * 24 * 30 * 12
}, qi = (e, r) => {
  const t = () => e(/* @__PURE__ */ new Date()), n = E(t()), i = ei[r.unit], s = Date.now(), u = e(new Date(s + i)).getTime() - s, a = E(u <= 0 ? i : u);
  return yt(() => {
    n.value = t(), a.value !== i && (a.value = i);
  }, a), H(n);
};
function xi(e) {
  const r = e.debounce ?? 0, t = e.localOnly ?? !1, n = A(() => k(e.items)), i = e.requestedItems ? e.requestedItems : E([]), s = e.haveLoadedItems ? e.haveLoadedItems : E([]), l = A(() => k(e.watchOnlyIf) !== !1 && !k(t)), u = !l.value, a = q(!u && n.value.length > 0), c = q(k(t));
  let o;
  const d = /* @__PURE__ */ new Map();
  function h() {
    i.value = [], d.clear(), s.value = [], a.value = !1, c.value = n.value.length === 0, o == null || o.abort(), o = void 0;
  }
  async function p() {
    if (o && o.abort(), !n.value.length) {
      c.value = !0, a.value = !1;
      return;
    }
    const f = i.value, m = s.value, v = n.value.filter((y) => !f.find((w) => he(y, w)));
    if (!v.length) {
      c.value || (c.value = !0), a.value = !1;
      return;
    }
    i.value.push(...v);
    for (const y of v)
      d.set(y, (d.get(y) ?? 0) + 1);
    o = new AbortController();
    try {
      a.value = !0, await e.action(v, { abortController: o }), await Ne();
    } finally {
      if (o.signal.aborted)
        for (const y of v) {
          let w = d.get(y);
          if (w = w ? w - 1 : 0, !w || w <= 0) {
            const b = i.value.findIndex((S) => he(S, y));
            b !== -1 && i.value.splice(b, 1), d.delete(y);
          } else
            d.set(y, w);
        }
      else
        c.value || (c.value = !0), m.push(...v);
      a.value = !1;
    }
  }
  return Wn(n, p, {
    onlyIf: l,
    debounce: r,
    immediate: !u,
    deep: !0,
    flush: "sync"
  }), e.watchReset && Rn(
    e.watchReset,
    async () => {
      h(), await p();
    },
    {
      onlyIf: jr(t)
    }
  ), {
    requestedItems: i,
    isPending: H(a),
    hasLoaded: H(c),
    reset: h
  };
}
const ti = 16, R = (e) => e * ti, Ee = {
  xs: R(20),
  sm: R(24),
  md: R(28),
  lg: R(32),
  xl: R(36),
  "2xl": R(42),
  "3xl": R(48),
  "4xl": R(56),
  "5xl": R(64),
  "6xl": R(72),
  "7xl": R(80)
};
function ri(e) {
  const r = E({ width: 0, height: 0 });
  Ce(e, (i) => {
    const s = i[0], { width: l, height: u } = s.contentRect;
    r.value = { width: l, height: u };
  });
  const t = A(() => {
    const { width: i } = r.value;
    return Object.keys(Ee).reduce((l, u) => {
      const a = Ee[u];
      return i > a && (l = a), l;
    }, 0);
  });
  I(t, (i) => {
    Object.keys(Ee).forEach((s) => {
      const l = s, u = Ee[l];
      n.value[l] = i >= u;
    });
  });
  const n = E({
    xs: !1,
    sm: !1,
    md: !1,
    lg: !1,
    xl: !1,
    "2xl": !1,
    /** 48 rem */
    "3xl": !1,
    "4xl": !1,
    "5xl": !1,
    "6xl": !1,
    "7xl": !1
  });
  return {
    cq: n
  };
}
const $i = Y({
  name: "UseContainerQuery",
  props: {
    as: {
      type: String,
      default: "div"
    }
  },
  setup(e, { slots: r }) {
    const t = E(), n = fe(ri(t));
    return () => {
      if (r.default) return Gt(e.as, { ref: t }, r.default(n));
    };
  }
});
export {
  si as VuetilsAsyncComputed,
  ai as VuetilsConditionalLink,
  oi as VuetilsDeclarativeWatch,
  wi as VuetilsLazyRender,
  bi as VuetilsScrollShadowContainer,
  Ei as VuetilsStickyContent,
  Ti as VuetilsTemplateVars,
  $i as VuetilsUseContainerQuery,
  li as computedCompareDeepEqual,
  ci as computedCompareShallowArray,
  or as computedDebounced,
  li as computedDeepEqual,
  ui as computedOnlyIf,
  wt as computedWithComparator,
  fi as defineComputedLazy,
  jr as negate,
  vi as promiseWatch,
  hi as reactiveValueToRef,
  Ln as refDebounced,
  mi as refSetTrap,
  ri as useContainerQuery,
  Oi as useDateWithNow,
  Si as useDisposed,
  Ci as useLazyShow,
  Ii as useMutexAction,
  _i as useOnEventListener,
  Ai as useQueuedAction,
  qi as useReactiveDate,
  xi as useRequestOnce,
  Hn as useWrapAction,
  pi as watchDebouncedPausable,
  yi as watchMultiAsObject,
  gi as watchMultiAsObjectDebounced,
  Rn as watchOnlyIf,
  Wn as watchOnlyIfDebounced
};
