import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable } from "../../typebox/index.js";
export const AssignmentEventTask = defineSchema(
  "assignment-event-tasks",
  {
    name: Type.String({ default: "" }),
    order: Type.Optional(Type.Integer({ minimum: 1, default: 1 })),
    completedAt: Type.Optional(Nullable(Type.Date())),
    dueAt: Type.Optional(Nullable(Type.Date())),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  {
    relations: (r) => ({
      company: r.belongsTo("companies"),
      event: r.belongsTo("assignment-events"),
      assignment: r.belongsTo("stairs"),
      completedBy: r.belongsTo("users", { nullable: true }),
      assignedUser: r.belongsTo("users", { nullable: true }),
      createdBy: r.belongsTo("users", { nullable: true })
    })
  }
);
