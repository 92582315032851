import NProgress from "nprogress";
import "nprogress/nprogress.css";

import { isObject } from "@artesa/utils";
import routesGeneral from "../general/routes.general";
import routesOffice from "../office/routes.office";
import routesDoing from "../doing/routes.doing";
import routesCheck from "../check/routes.check";
import routesSuperAdmin from "../superadmin/routes.superadmin";
import { beforeEachMulti } from "./multi-guard";
import { TRANSITIONS } from "@/router/router.utils";
import { useAuthStore } from "@/store/auth.store";
import type { ViewName } from "@artesa/shared";
import { viewNameEnum } from "@artesa/shared";
import { useVueRouterStore } from "@/store/vue-router.store";
import { canViewRoute } from "@/main.plugins/main.casl";

declare module "vue-router" {
  interface Router {
    app: App;
  }

  interface RouteMeta {
    /**
     * The localized name of the route
     */
    name?: string;
    /**
     * The icon of the route
     */
    icon?: string;
    can?(): boolean;
    /**
     * Explicit view name for permission check or 'true' to check the route name
     */
    permission?: ViewName | true;
    hideMainMenu?: boolean;
    hideMainHeader?: boolean;
    auth?: "required" | "none";
    transition?: null | {
      leave?: string;
      enter?: string;
      name?: string;
      // If only the parameter changes, but not the named route, skip the transition
      skipForParameterChange?: boolean;
    };
    onCloseGoLevelUp?: number;
  }
}

const router = createRouter({
  // history: useHistory ? createWebHistory() : createWebHashHistory(),
  history: createWebHistory(),
  routes: [
    {
      path: "/login",
      name: "Login",
      component: () => import("@/general/login/_views/ViewLogin.vue"),
      props: route => ({ redirect: route.query.redirect }),
      meta: {
        auth: "none",
        name: "Login",
        icon: "fa-solid:sign-in-alt",
        transition: {
          leave: TRANSITIONS.ZOOM_IN,
          enter: TRANSITIONS.ZOOM_OUT,
        },
      },
    },
    {
      path: "/",
      name: "Root",
      component: () => import("@/layout/ArtesaLayout.vue"),
      redirect: () => ({ name: "ViewAppDrawer" }),
      children: [
        {
          path: "home",
          name: "ViewAppDrawer",
          component: () => import("@/layout/drawer/ViewAppDrawer.vue"),
          meta: {
            name: "Startseite",
            icon: "fa-solid:tachometer-alt",
            hideMainMenu: true,
            hideMainHeader: true,
            transition: {
              leave: TRANSITIONS.ZOOM_IN,
              enter: TRANSITIONS.ZOOM_OUT,
            },
          },
        },
        ...routesCheck(),
        ...routesDoing(),
        ...routesGeneral(),
        ...routesSuperAdmin(),
        ...routesOffice(),
      ],
    },
    {
      path: "/:pathMatch(.*)*",
      name: "404",
      component: () => import("@/views/general/NotFound.vue"),
    },
  ],
});

router.beforeResolve((to, from, next) => {
  if (
    !to.name ||
    !from?.name ||
    to.name === from.name ||
    to.meta?.transition === null ||
    from.meta?.transition === null
  ) {
    return next();
  }

  NProgress.start();

  return next();
});

router.afterEach(() => {
  NProgress.done();
});

let checkedAuthentication = false;

beforeEachMulti(router, [
  // manufacturing user inactive redirect
  (to, from, next) => {
    if (checkedAuthentication) {
      return next();
    }

    if (
      to.name === viewNameEnum.ViewManufacturingWorkSpace ||
      to.name === viewNameEnum.ViewShopfloorEntrance
    ) {
      return next();
    }

    const isInactive = safeLocalStorage()?.getItem("manufacturingUserInactive") === "true";

    if (!isInactive) {
      return next();
    }

    return next({ name: viewNameEnum.ViewShopfloorEntrance });
  },
  // auth redirect
  (to, from, next) => {
    if (to.name === from.name || checkedAuthentication) {
      return next();
    }

    checkedAuthentication = true;

    const authStore = useAuthStore();

    const isAuthenticated = authStore.isAuthenticated;

    if (to.meta?.auth !== "none") {
      if (isAuthenticated) {
        return canViewRoute(to) ? next() : next({ name: "Root" });
      } else {
        return next({ name: "Login", query: { redirect: to.fullPath } });
      }
    } else {
      if (isAuthenticated) {
        try {
          if (to.name === "Login" && to.query.redirect) {
            const resolved = router.resolve(to.query.redirect as string);
            if (resolved.name === "404") {
              throw new Error("resolved to 404");
            }
            return next(to.query.redirect as string);
          }
          // eslint-disable-next-line no-empty
        } catch {}

        return next({ name: "Root" });
      } else {
        return next();
      }
    }
  },
  // restore-data
  (to, from, next) => {
    const store = useVueRouterStore();
    if (store.skipRestore) {
      return next();
    }

    const restoreStore = useQueryRestoreData().value;

    if (!restoreStore) {
      return next();
    }

    let data;

    for (let i = to.matched.length - 1; i >= 0; i--) {
      const record = to.matched[i];

      if (!record.name) {
        continue;
      }

      data = restoreStore[record.name];
      if (data) {
        break;
      }
    }

    if (!data || !isObject(data) || !Object.keys(data).length) {
      return next();
    }

    let query;

    for (const key in data) {
      if (!(key in to.query)) {
        query ??= { ...to.query };
        query[key] = data[key];
      }
    }

    if (!query) {
      return next();
    }

    store.skipRestore = true;
    return next({ ...to, query });
  },
]);

router.afterEach(() => {
  NProgress.done();
  useVueRouterStore().afterEach();
});

export default router;

const mixRoutes = (
  targetRoute: RouteLocationNormalizedLoaded,
  newRoute: Partial<RouteLocationNormalizedLoaded>,
): RouteLocationRaw => {
  const result: RouteLocationRaw = {};

  if (newRoute.name) {
    result.name = newRoute.name;
  }

  if (newRoute.name === targetRoute.name) {
    result.replace = true;
  }

  if (newRoute.params || targetRoute.params) {
    result.params = Object.assign({}, targetRoute.params, newRoute.params);
    for (const param in result.params) {
      if (!result.params[param]) {
        delete result.params[param];
      }
    }
  }

  if (newRoute.query || targetRoute.query) {
    result.query = Object.assign({}, targetRoute.query, newRoute.query);
    for (const q in result.query) {
      if (!result.query[q]) {
        delete result.query[q];
      }
    }
  }

  return result;
};

export const pushPartialRoute = (data: Partial<RouteLocationNormalizedLoaded>) => {
  const route = mixRoutes(router.currentRoute.value, data);

  return router.push(route);
};
