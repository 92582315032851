import type { Language } from "@artesa/shared";
import useCompanyStore from "@/store/feathers/services/companies/companies.pinia";

/**
 * Returns the locale of the user or the company
 * @returns
 */
export const useLocale = createSharedComposable(() => {
  const user = useUser();
  const companyStore = useCompanyStore().createScope();

  const { data: company } = companyStore.useGet({
    id: computed(() => user.value?.companyId ?? null),
    lazy: true,
  });

  return computed<Language>(() => {
    return user.value?.language ?? company.value?.language ?? "de";
  });
});
