import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable, StringEnum } from "../../typebox/index.js";
import { dateAnchorValues } from "../assignment-event-types/assignment-event-types.types.js";
export const AssignmentEvent = defineSchema(
  "assignment-events",
  {
    // data
    name: Type.Optional(Nullable(Type.String())),
    startsAt: Type.Optional(Nullable(Type.Date())),
    endsAt: Type.Optional(Nullable(Type.Date())),
    startsAtDefaultTime: Type.Optional(Nullable(Type.String())),
    // TODO: regex for time
    workDays: Type.Optional(Type.Integer({ minimum: 0, default: 1 })),
    dateAnchor: Type.Optional(StringEnum(dateAnchorValues, { default: "startsAt" })),
    plannedEmployeeCount: Type.Optional(Type.Integer({ minimum: 0 })),
    workingTimePerDay: Type.Optional(Type.Integer({ minimum: 0, default: 60 * 60 * 8 })),
    days: Type.Optional(Nullable(Type.Array(Type.String()))),
    manPower: Type.Optional(Type.Integer({ minimum: 0, default: 0 })),
    manPowerDrive: Type.Optional(Type.Integer({ minimum: 0, default: 0 })),
    completedAt: Type.Optional(Nullable(Type.Date())),
    confirmedAt: Type.Optional(Nullable(Type.Date())),
    note: Type.Optional(Nullable(Type.String())),
    order: Type.Optional(Type.Integer({ minimum: 1, default: 1 })),
    durationDriveFromStart: Type.Optional(Type.Integer({ minimum: 0 })),
    durationDriveReturnTo: Type.Optional(Type.Integer({ minimum: 0 })),
    isAllDay: Type.Optional(Type.Boolean({ default: true })),
    isOvernightNeeded: Type.Optional(Nullable(Type.Boolean({ default: null }))),
    bookingDoneAt: Type.Optional(Nullable(Type.Date())),
    minUploadCount: Type.Optional(Type.Integer({ minimum: 0, default: 0 })),
    isOutward: Type.Optional(Type.Boolean({ default: false })),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  {
    relations: (r) => ({
      company: r.belongsTo("companies"),
      assignment: r.belongsTo("stairs"),
      type: r.belongsTo("assignment-event-types", { nullable: true }),
      group: r.belongsTo("assignment-event-groups", { nullable: true }),
      addressStartFrom: r.belongsTo("addresses", { nullable: true }),
      addressDestination: r.belongsTo("addresses", { nullable: true }),
      addressReturnTo: r.belongsTo("addresses", { nullable: true }),
      completedBy: r.belongsTo("users", { nullable: true }),
      createdBy: r.belongsTo("users", { nullable: true }),
      confirmedBy: r.belongsTo("users", { nullable: true }),
      // hasMany
      assignedUserEvents: r.hasMany("assignment-event-users", "eventId")
    }),
    query: {
      additionalProperties: {
        "assignment.name": { type: "string", profile: "server" },
        "assignment.number": { type: "string", profile: "server" },
        "$assignedUserEvents.userId$": { type: "number", profile: "server" },
        "$assignment.statusId$": { type: "number", profile: "server" },
        "$assignment.number$": { type: "string", profile: "server" },
        "$assignment.name$": { type: "string", profile: "server" },
        "$addressDestination.street$": { type: "string", profile: "server" },
        "$addressDestination.zip$": { type: "string", profile: "server" },
        "$addressDestination.city$": { type: "string", profile: "server" }
      }
    }
  }
);
