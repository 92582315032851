import { library } from "@fortawesome/fontawesome-svg-core";

import { FontAwesomeIcon, FontAwesomeLayers } from "@fortawesome/vue-fontawesome";

import {
  faFileInvoice,
  faAddressBook,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowsAltV,
  faAt,
  faBackspace,
  faBan,
  faBars,
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
  faBed,
  faBriefcase,
  faBuilding,
  faCalendarAlt,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCheck,
  faCheckCircle,
  faClipboardList,
  faClipboardCheck,
  faCogs,
  faCog,
  faCopy,
  faDice,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faEnvelopeOpen,
  faEnvelopeOpenText,
  faEye,
  faEyeSlash,
  faExclamation,
  faExclamationCircle,
  faFax,
  faFileImport,
  faFilter,
  faFolderOpen,
  faHome,
  faHotel,
  faInfo,
  faLink,
  faList,
  faListAlt,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMinus,
  faMobileAlt,
  faPaperclip,
  faPencilAlt,
  faPhone,
  faPhoneSquare,
  faPlus,
  faPlusCircle,
  faSearch,
  faSearchPlus,
  faSignOutAlt,
  faSpinner,
  faStickyNote,
  faTasks,
  faTimes,
  faThumbsUp,
  faTrashAlt,
  faTruck,
  faUser,
  faUserCog,
  faUserEdit,
  faUserFriends,
  faUserPlus,
  faFileExport,
  faHandshake,
  faIndustry,
  faRulerCombined,
  faPowerOff,
  faPause,
  faArrowLeft,
  faArrowRight,
  faStopwatch,
  faUserSlash,
  faUsers,
  faPlay,
  faSave,
  faCalendarWeek,
  faCalendarDay,
  faBusinessTime,
  faTable,
  faFlagCheckered,
  faTachometerAlt,
  faChartBar,
  faChalkboardTeacher,
  faImages,
  faCircle,
  faEdit,
  faPlusSquare,
  faSort,
  faUndo,
  faRedo,
  faBug,
  faHammer,
  faQuestion,
  faGlobeEurope,
  faSyncAlt,
  faSortNumericDown,
  faSortNumericUp,
  faSignInAlt,
  faLongArrowAltRight,
  faLongArrowAltLeft,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faArrowsAltH,
  faCheckSquare,
  faLock,
  faLockOpen,
  faImage,
  faBinoculars,
  faHourglassStart,
  faBorderAll,
  faTools,
  faArrowDown,
  faArrowUp,
  faExpandAlt,
  faExternalLinkAlt,
  faBell,
  faCalendarCheck,
  faCalendar,
  faStop,
  faTheaterMasks,
  faCalculator,
  faSlash,
  faWrench,
  faHourglassHalf,
  faThList,
  faHashtag,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faUndoAlt,
  faRedoAlt,
  faPrint,
  faExchangeAlt,
  faAddressCard,
  faExpand,
  faPlayCircle,
  faStopCircle,
  faGripHorizontal,
  faFolder,
  faClock,
  faBox,
  faUnlock,
  faPenAlt,
  faLocationArrow,
  faColumns,
  faMap,
  faSortAmountDown,
  faSortAmountUp,
  faGlobe,
  faEquals,
} from "@fortawesome/free-solid-svg-icons";

import {
  faAddressCard as farAddressCard,
  faClock as farClock,
  faClone as farClone,
  faEnvelope as farEnvelope,
  faFileAlt as farFileAlt,
  faSave as farSave,
  faCalendarAlt as farCalendarAlt,
  faMinusSquare as farMinusSquare,
  faSquare as farSquare,
  faSmile as farSmile,
  faCheckSquare as farCheckSquare,
  faImages as farImages,
  faImage as farImage,
  faThumbsUp as farThumbsUp,
  faTrashAlt as farTrashAlt,
  faFolderOpen as farFolderOpen,
  faCalendarPlus as farCalendarPlus,
  faCalendarTimes as farCalendarTimes,
  faComments as farComments,
  faComment as farComment,
  faCopy as farCopy,
} from "@fortawesome/free-regular-svg-icons";

library.add(
  // @ts-expect-error - this is a bug in the types
  faImage,
  farImage,
  faAddressBook,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowLeft,
  faArrowRight,
  faArrowsAltV,
  faArrowDown,
  faArrowUp,
  faAt,
  faBackspace,
  faBan,
  faBars,
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
  faBed,
  faBell,
  faFileInvoice,
  faBriefcase,
  faBuilding,
  faBusinessTime,
  faCalculator,
  faCalendarAlt,
  faCalendarWeek,
  faCalendarDay,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faClipboardCheck,
  faClipboardList,
  faCogs,
  faCog,
  faCopy,
  farCopy,
  faDice,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faEnvelopeOpen,
  faEnvelopeOpenText,
  faEye,
  faEyeSlash,
  faExclamation,
  faExclamationCircle,
  faFax,
  faFileExport,
  faFileImport,
  faFilter,
  faFlagCheckered,
  faFolderOpen,
  faHandshake,
  faHome,
  faHotel,
  faIndustry,
  faInfo,
  faLink,
  faList,
  faListAlt,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMinus,
  faMobileAlt,
  faPaperclip,
  faPause,
  faPenAlt,
  faPencilAlt,
  faPhone,
  faPhoneSquare,
  faPlay,
  faPlus,
  faPlusCircle,
  faPowerOff,
  farAddressCard,
  farCalendarAlt,
  farCheckSquare,
  farClock,
  farClone,
  farEnvelope,
  farFileAlt,
  farImages,
  farSave,
  faRulerCombined,
  faSave,
  faSearch,
  faSearchPlus,
  faSignOutAlt,
  faSpinner,
  faStickyNote,
  faStopwatch,
  faTable,
  faTachometerAlt,
  faTasks,
  faThumbsUp,
  faTimes,
  faExpandAlt,
  faTrashAlt,
  faExternalLinkAlt,
  faTruck,
  faUser,
  faUserCog,
  faUserEdit,
  faUserFriends,
  faUserPlus,
  faUsers,
  faChartBar,
  faChalkboardTeacher,
  faImages,
  faCircle,
  faEdit,
  faPlusSquare,
  farMinusSquare,
  faSort,
  faUndo,
  farSquare,
  faBug,
  faHammer,
  faQuestion,
  faGlobeEurope,
  faSlash,
  faSyncAlt,
  faSortNumericDown,
  faSortNumericUp,
  faSignInAlt,
  faTools,
  faLongArrowAltRight,
  faLongArrowAltLeft,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faArrowsAltH,
  farSmile,
  faLock,
  faLockOpen,
  faBinoculars,
  faHourglassStart,
  faBorderAll,
  farThumbsUp,
  farTrashAlt,
  farFolderOpen,
  farCalendarPlus,
  faCalendarCheck,
  farCalendarTimes,
  faCalendar,
  faStop,
  faTheaterMasks,
  faUserSlash,
  faWrench,
  faHourglassHalf,
  farComments,
  farComment,
  faThList,
  faHashtag,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faUndoAlt,
  faRedoAlt,
  faRedo,
  faPrint,
  faExchangeAlt,
  faAddressCard,
  faExpand,
  faPlayCircle,
  faStopCircle,
  faGripHorizontal,
  faFolder,
  faClock,
  faBox,
  faUnlock,
  faLocationArrow,
  faColumns,
  faMap,
  faSortAmountDown,
  faSortAmountUp,
  faGlobe,
  faEquals,
);

export default {
  install: (app: App) => {
    app.component("FontAwesomeIcon", FontAwesomeIcon);
    app.component("FontAwesomeLayers", FontAwesomeLayers);
  },
};
