import { Type } from "@artesa/model-compiler";
export const ASSIGNMENT_EVENT_WORK_PERIOD_METHODS = [
  "find",
  "get",
  "create",
  "update",
  "patch",
  "remove",
  "sum",
  "sumGroupBy",
  "adjustedSumForAssignment"
];
export const assignmentEventWorkPeriodAdjustedSumDataSchema = Type.Object({
  assignmentId: Type.Integer({ minimum: 1 })
});
