import parseProps from "vue-router-parse-props";
import type { ViewName} from "@artesa/shared";
import { viewNameEnum } from "@artesa/shared";
import { TRANSITIONS } from "@/router/router.utils";
import { defineRoutesCustomForms } from "@/components/custom-forms/routes";
import type { RouteMeta } from "vue-router";

function idOrNewToNumberOrNull (value: string) {
  return value === "new" ? null : Number(value);
}

const propsDetailedAssignment = parseProps({
  assignmentId: idOrNewToNumberOrNull,
});

function detailedAssignment(path: string, name: ViewName, meta?: RouteMeta) {
  return {
    path,
    name,
    component: () => import("./assignments/DialogDetailedAssignment.vue"),
    props: propsDetailedAssignment,
    meta: {
      name: "Auftragsakte",
      permission: viewNameEnum.ViewAssignment,
      transition: null,
      ...meta
    } satisfies RouteMeta,
  }
}

export default (): RouteRecordRaw[] => [
  {
    path: "office",
    redirect: () => {
      return { name: viewNameEnum.ViewPlanningBoard };
    },
  },
  {
    path: "office/board",
    name: viewNameEnum.ViewPlanningBoard,
    component: () => import("./board/ViewPlanningBoard.vue"),
    meta: {
      name: "Plantafel",
      icon: "fa-solid:chalkboard-teacher",
      permission: true,
    },
    children: [
      detailedAssignment(":assignmentId", viewNameEnum["ViewPlanningBoard.ViewAssignment"]),
    ],
  },
  {
    path: "office/scheduler",
    name: viewNameEnum.ViewDepartmentScheduler,
    component: () => import("./gantt/ViewGantt.vue"),
    meta: {
      name: "Arbeitsbereichstafel",
      icon: "fa-regular:calendar-alt",
      permission: true,
    },
    children: [
      detailedAssignment(":assignmentId", viewNameEnum["ViewDepartmentScheduler.ViewAssignment"]),
    ]
  },
  {
    path: "office/assignment/create",
    name: viewNameEnum.ViewAssignmentCreate,
    component: () => import("./_views/ViewCreateAssignment.vue"),
    meta: {
      name: "Auftrag anlegen",
      icon: "fa-solid:plus-circle",
      permission: true,
      transition: {
        leave: TRANSITIONS.SLIDE_TO_LEFT,
        enter: TRANSITIONS.SLIDE_TO_LEFT,
      },
    },
  },
  {
    path: "office/assignments",
    name: viewNameEnum.ViewAssignments,
    component: () => import("./assignments/ViewAssignments.vue"),
    meta: {
      name: "Aufträge",
      icon: "fa-solid:th-list",
      permission: true,
    },
    alias: "office/search",
    children: [
      detailedAssignment(":assignmentId", viewNameEnum["ViewAssignments.ViewAssignment"]),
    ],
  },
  {
    path: "office/assignment-events",
    name: viewNameEnum.ViewAssignmentEvents,
    component: () => import("./_views/ViewAssignmentEvents.vue"),
    meta: {
      name: "Arbeitsschritte",
      icon: "fa-check-square",
      permission: true,
    },
    children: [
      detailedAssignment(":assignmentId", viewNameEnum["ViewAssignmentEvents.ViewAssignment"]),
    ],
  },
  {
    path: "office/projects",
    name: viewNameEnum.ViewProjects,
    component: () => import("./projects/ViewProjects.vue"),
    meta: {
      name: "Projekte",
      icon: "fa-folder-open",
      permission: true,
    },
    children: [
      {
        path: ":projectId",
        name: viewNameEnum["ViewProjects.ViewProject"],
        component: () => import("./projects/DialogDetailedProject.vue"),
        props: parseProps({
          projectId: idOrNewToNumberOrNull,
        }),
        meta: {
          name: "Projektakte",
          permission: viewNameEnum.ViewProject,
        },
        children: [
          detailedAssignment("assignment/:assignmentId", viewNameEnum["ViewProjects.ViewProject.ViewAssignment"]),
        ]
      }
      // detailedAssignment(":assignmentId", viewNameEnum["ViewProjects.ViewProject.ViewAssignment"]),
    ],
  },
  {
    path: "office/chats",
    name: viewNameEnum.ViewChatRooms,
    component: () => import("./_views/ChatRoomsView.vue"),
    meta: {
      name: "Chats",
      icon: "fa-regular:comments",
      permission: true,
    },
  },
  {
    path: "office/customers",
    name: viewNameEnum.ViewCustomers,
    component: () => import("./customers/ViewCustomers.vue"),
    meta: {
      name: "Kunden",
      icon: "fa-regular:address-card",
      permission: true,
    },
    children: [
      {
        path: ":customerId",
        name: viewNameEnum["ViewCustomers.ViewCustomer"],
        component: () => import("./customers/DialogDetailedCustomer.vue"),
        props: parseProps({
          customerId: idOrNewToNumberOrNull,
        }),
        meta: {
          name: "Kundenakte",
          permission: viewNameEnum.ViewCustomer,
        },
        children: [
          detailedAssignment("assignment/:assignmentId", viewNameEnum["ViewCustomers.ViewCustomer.ViewAssignment"]),
        ]
      }
    ],
  },
  {
    path: "office/customers/csv",
    name: viewNameEnum["ViewCustomer.CSV"],
    component: () => import("./customers/CSVCustomerImport.vue"),
    meta: {
      name: "CSV",
    },
  },
  ...defineRoutesCustomForms("office/"),
];
