import type { ICustomerService } from "@artesa/shared";
import { Customer, CUSTOMERS_METHODS } from "@artesa/shared";
import { defineFeathersPiniaStore } from "../../base-store";
import { makeTransformDatesHook } from "../../hooks/transformDates.hook";

declare module "../../feathers.client" {
  interface Services {
    customers: ICustomerService;
  }
}

export const useCustomerStore = defineFeathersPiniaStore({
  schema: Customer,
  servicePath: "customers",
  methods: CUSTOMERS_METHODS,
  setupHook({ service, transformDates }) {
    const transformDatesHook = makeTransformDatesHook(transformDates);
    service.hooks({
      after: {
        find: [transformDatesHook],
        get: [transformDatesHook],
        create: [transformDatesHook],
        update: [transformDatesHook],
        patch: [transformDatesHook],
        remove: [transformDatesHook],
      },
    });
  },
});

export default useCustomerStore;
