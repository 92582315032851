import { Type, Nullable } from "../typebox/index.js";
export const printSettings = Type.Optional(
  Nullable(
    Type.Object({
      margin: Type.Union([
        Type.Number(),
        Type.String(),
        Type.Object({
          x: Type.Optional(Type.Union([Type.Number(), Type.String()])),
          y: Type.Optional(Type.Union([Type.Number(), Type.String()]))
        }),
        Type.Object({
          left: Type.Optional(Type.Union([Type.Number(), Type.String()])),
          right: Type.Optional(Type.Union([Type.Number(), Type.String()])),
          top: Type.Optional(Type.Union([Type.Number(), Type.String()])),
          bottom: Type.Optional(Type.Union([Type.Number(), Type.String()]))
        })
      ]),
      headerTemplate: Type.Optional(Type.String()),
      footerTemplate: Type.Optional(Type.String())
    })
  )
);
const defaultMargins = {
  left: "2cm",
  right: "2cm",
  top: "2cm",
  bottom: "2cm"
};
export function printSettingMarginToRem(settings) {
  const margins = settings?.margin;
  if (!margins) {
    return { ...defaultMargins };
  }
  if (typeof margins === "string" || typeof margins === "number") {
    return {
      left: margins,
      right: margins,
      top: margins,
      bottom: margins
    };
  } else if ("x" in margins || "y" in margins) {
    return {
      left: margins.x ?? defaultMargins.left,
      right: margins.x ?? defaultMargins.right,
      top: margins.y ?? defaultMargins.top,
      bottom: margins.y ?? defaultMargins.bottom
    };
  } else if ("left" in margins || "right" in margins || "top" in margins || "bottom" in margins) {
    return {
      left: margins.left ?? defaultMargins.left,
      right: margins.right ?? defaultMargins.right,
      top: margins.top ?? defaultMargins.top,
      bottom: margins.bottom ?? defaultMargins.bottom
    };
  }
  return { ...defaultMargins };
}
