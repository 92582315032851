import { defineStore } from 'pinia';
import { tryOnScopeDispose, reactify } from '@vueuse/core';
import _sift, { createEqualsOperation } from 'sift';
import debounce from 'lodash/debounce';
import objectHash from 'object-hash';
import groupBy from 'lodash/groupBy';
import { shallowReactive, shallowRef, computed, nextTick, reactive, markRaw, ref, toValue, watch, shallowReadonly, readonly, unref, toRef } from 'vue';
import { promiseWithResolvers, noop, setDeleteHas, setAddHas, isNonNullable, asyncDebounce, isInteger } from '@artesa/utils';
import { isSchemaUnion, Type, isSchemaVirtual, isSchemaFrontendOnly, isSchemaReadonly, Kind, isSchemaIntersect } from '@artesa/shared';
import copy from 'fast-copy';
import { uid } from 'uid';
import { deepEqual } from 'fast-equals';
import { computedCompareShallowArray, useDisposed, defineComputedLazy, computedDeepEqual, watchOnlyIf, computedOnlyIf, useMutexAction, useRequestOnce } from '@artesa/vuetils';
import { setQueryKeySafely } from 'feathers-utils';
import { sorter } from '@feathersjs/adapter-commons';
import { alterItems } from 'feathers-hooks-common';

function like(value, search, regexOptions = "g") {
  const specials = ["/", ".", "*", "+", "?", "|", "(", ")", "[", "]", "{", "}", "\\"];
  search = search.replace(new RegExp(`(\\${specials.join("|\\")})`, regexOptions), "\\$1");
  search = search.replace(/%/g, ".*").replace(/_/g, ".");
  return RegExp(`^${search}$`, regexOptions).test(value);
}
function iLike(str, search) {
  return like(str, search, "ig");
}
function $like(params, ownerQuery, options) {
  return createEqualsOperation((value) => like(value, params), ownerQuery, options);
}
function $notLike(params, ownerQuery, options) {
  return createEqualsOperation((value) => !like(value, params), ownerQuery, options);
}
function $iLike(params, ownerQuery, options) {
  return createEqualsOperation((value) => iLike(value, params), ownerQuery, options);
}
function $notILike(params, ownerQuery, options) {
  return createEqualsOperation((value) => !iLike(value, params), ownerQuery, options);
}

function dateString(value, search, lessGreater, include) {
  const date = new Date(value);
  const searchDate = new Date(search);
  if (lessGreater === "lt") {
    return include ? date <= searchDate : date < searchDate;
  } else {
    return include ? date >= searchDate : date > searchDate;
  }
}
function $ltDateString(params, ownerQuery, options) {
  return createEqualsOperation(
    (value) => dateString(value, params, "lt", false),
    ownerQuery,
    options
  );
}
function $lteDateString(params, ownerQuery, options) {
  return createEqualsOperation(
    (value) => dateString(value, params, "lt", true),
    ownerQuery,
    options
  );
}
function $gtDateString(params, ownerQuery, options) {
  return createEqualsOperation(
    (value) => dateString(value, params, "gt", false),
    ownerQuery,
    options
  );
}
function $gteDateString(params, ownerQuery, options) {
  return createEqualsOperation(
    (value) => dateString(value, params, "gt", true),
    ownerQuery,
    options
  );
}

function $contains(params, ownerQuery, options) {
  return createEqualsOperation(
    (value) => Array.isArray(value) && value.includes(params),
    ownerQuery,
    options
  );
}

const operations = {
  $like,
  $notLike,
  $iLike,
  $notILike,
  $ltDateString,
  $lteDateString,
  $gtDateString,
  $gteDateString,
  $contains
};
function sift(query, options) {
  return _sift(query, { ...options, operations: { ...operations, ...options?.operations } });
}

function defineAllowsMulti(options) {
  const perMethod = {
    find: true,
    get: false,
    update: false
  };
  if (Array.isArray(options?.multi)) {
    options.multi.forEach((method) => {
      perMethod[method] = true;
    });
  }
  function allowsMulti(method) {
    if (method in perMethod) {
      return perMethod[method];
    }
    if (options?.multi === false) {
      return false;
    }
    return true;
  }
  return allowsMulti;
}

function applyLimitAndSkip(array, { $skip, $limit }) {
  if ($limit === 0) {
    return [];
  }
  const length = array.length;
  if ($limit === void 0 || $limit === -1 || $limit === "-1") {
    $limit = length;
  }
  if (!$skip && $limit >= length) {
    return array;
  }
  return array.slice($skip, ($skip ?? 0) + ($limit ?? length));
}

function defineGet(service, servicePath, debounceTime, store) {
  const queuedIds = /* @__PURE__ */ new Set();
  const callbackForId = /* @__PURE__ */ new Map();
  async function triggerGet() {
    if (queuedIds.size === 0) {
      return;
    }
    const idsToFetch = [...new Set(queuedIds)];
    const callbacks = new Map(callbackForId);
    queuedIds.clear();
    callbackForId.clear();
    try {
      const fromServer = idsToFetch.length === 1 ? await service.get(idsToFetch[0]).then((result) => result ? [result] : []) : await service.find({
        query: {
          id: {
            $in: idsToFetch
          },
          $limit: -1
        }
      }).then((fromServer2) => {
        const items2 = Array.isArray(fromServer2) ? fromServer2 : fromServer2.data;
        return items2;
      });
      let items = [];
      try {
        store.isConstructingCount.value++;
        items = fromServer.map((item) => store.scopelessConstruct(item));
      } finally {
        store.isConstructingCount.value--;
      }
      for (const [id, cbs] of callbacks.entries()) {
        const item = items.find((item2) => item2.id === id);
        if (item) {
          cbs.forEach((cb) => cb(null, item));
        } else {
          cbs.forEach((cb) => cb(new Error(`Item with id ${id} not found for ${servicePath}`)));
        }
      }
    } catch (error) {
      for (const [, cbs] of callbacks.entries()) {
        cbs.forEach((cb) => cb(error));
      }
    }
  }
  const debouncedTrigger = debounce(triggerGet, debounceTime);
  function enqueueGet(id) {
    queuedIds.add(id);
    return new Promise((resolve, reject) => {
      const callbacks = callbackForId.get(id) ?? [];
      callbacks.push((error, item) => {
        if (!error && item) {
          return resolve(item);
        }
        reject(error);
      });
      callbackForId.set(id, callbacks);
      debouncedTrigger();
    });
  }
  function get(id, options) {
    if (!options?.debounce || options?.params) {
      return service.get(id, options?.params ?? void 0).then((result) => store.scopelessConstruct(result));
    } else {
      return enqueueGet(id);
    }
  }
  return {
    get,
    triggerGet,
    enqueueGet
  };
}

function defineCreate(service, servicePath, debounceTime) {
  const queuedCreateData = /* @__PURE__ */ new Set();
  async function triggerCreate() {
    if (queuedCreateData.size === 0) {
      return;
    }
    const toCreate = [...new Set(queuedCreateData)];
    queuedCreateData.clear();
    const data = toCreate.length === 1 ? toCreate[0].data : toCreate.map((value) => value.data);
    try {
      const result = await service.create(data);
      const items = Array.isArray(result) ? result : [result];
      for (const [index, item] of items.entries()) {
        const callback = toCreate[index]?.callback;
        callback?.(
          null,
          item,
          items.map((value) => value.id)
        );
      }
    } catch (error) {
      for (const value of toCreate) {
        value.callback(error);
      }
    }
  }
  const debouncedTrigger = debounce(triggerCreate, debounceTime);
  function enqueueCreate(data) {
    return new Promise((resolve, reject) => {
      queuedCreateData.add({
        data,
        callback: (error, item, idsToSkip) => {
          if (error || !item) {
            reject(error);
            return;
          }
          if (item) {
            resolve(item);
          }
        }
      });
      debouncedTrigger();
    });
  }
  function create(data, options) {
    if (!options?.debounce) {
      return service.create(data);
    } else {
      return enqueueCreate(data);
    }
  }
  return {
    create,
    enqueueCreate,
    triggerCreate
  };
}

function definePatch(service, servicePath, debounceTime) {
  const queuedPatchData = /* @__PURE__ */ new Set();
  async function triggerPatch() {
    if (queuedPatchData.size === 0) {
      return;
    }
    const toPatch = [...new Set(queuedPatchData)];
    queuedPatchData.clear();
    const items = [];
    try {
      const data = toPatch.map((value) => value.data);
      const groupedByChanges = groupBy(data, ({ id, ...changes }) => objectHash(changes));
      for (const changeHash in groupedByChanges) {
        const changes = groupedByChanges[changeHash];
        const [completeFirstChange] = changes;
        const { id: _, ...data2 } = completeFirstChange;
        const args = [
          changes.length === 1 ? changes[0].id : null,
          data2,
          changes.length !== 1 ? {
            query: {
              id: {
                $in: changes.map((item) => item.id)
              }
            }
          } : void 0
        ];
        const result = await service.patch(...args);
        items.push(...Array.isArray(result) ? result : [result]);
      }
      for (const [index, item] of items.entries()) {
        const callback = toPatch[index]?.callback;
        callback?.(
          null,
          item,
          items.map((value) => value.id)
        );
      }
    } catch (error) {
      for (const value of toPatch) {
        value.callback(error);
      }
    }
  }
  const debouncedTrigger = debounce(triggerPatch, debounceTime);
  function enqueuePatch(data) {
    return new Promise((resolve, reject) => {
      queuedPatchData.add({
        data,
        callback: (error, item, idsToSkip) => {
          if (error || !item) {
            reject(error);
            return;
          }
          if (item) {
            resolve(item);
          }
        }
      });
      debouncedTrigger();
    });
  }
  function patch(id, data, options) {
    if (options?.useUpdate) {
      return service.update(id, data, options?.params ?? void 0);
    } else if (!options?.debounce || options?.params) {
      return service.patch(id, data, options?.params ?? void 0);
    } else {
      return enqueuePatch({ id, ...data });
    }
  }
  return {
    patch,
    enqueuePatch,
    triggerPatch
  };
}

function defineRemove(service, servicePath, debounceTime) {
  const queuedRemoveIds = /* @__PURE__ */ new Map();
  async function triggerRemove() {
    if (queuedRemoveIds.size === 0) {
      return;
    }
    const callbacks = new Map(queuedRemoveIds);
    queuedRemoveIds.clear();
    const ids = [...new Set(callbacks.keys())];
    try {
      const items = ids.length === 1 ? await service.remove(ids[0]).then((item) => item ? [item] : []).catch(() => []) : await service.remove(null, {
        query: {
          id: {
            $in: ids
          }
        }
      });
      for (const [id, cbs] of callbacks.entries()) {
        const item = items.find((item2) => item2.id === id);
        if (item) {
          cbs.forEach((cb) => cb(null, item));
        } else {
          cbs.forEach((cb) => cb(null, void 0));
        }
      }
    } catch (error) {
      for (const [, item] of callbacks.entries()) {
        item.forEach((cb) => cb(error));
      }
    }
  }
  const debouncedTrigger = debounce(triggerRemove, debounceTime);
  function enqueueRemove(id) {
    return new Promise((resolve, reject) => {
      const callbacks = queuedRemoveIds.get(id) ?? [];
      callbacks.push((error, item) => {
        if (!error) {
          return resolve(item);
        }
        return reject(error);
      });
      queuedRemoveIds.set(id, callbacks);
      debouncedTrigger();
    });
  }
  function remove(id, options) {
    if (!options?.debounce) {
      return service.remove(id);
    } else {
      return enqueueRemove(id);
    }
  }
  return {
    remove,
    triggerRemove,
    enqueueRemove
  };
}

function createDataDebounce(service, servicePath, _debounceFor, store) {
  function debounceFor(key) {
    if (typeof _debounceFor === "number") {
      return _debounceFor;
    }
    return _debounceFor[key] ?? 150;
  }
  return {
    ...defineGet(service, servicePath, debounceFor("get"), store),
    ...defineCreate(service, servicePath, debounceFor("create")),
    ...definePatch(service, servicePath, debounceFor("patch")),
    ...defineRemove(service, servicePath, debounceFor("remove"))
  };
}

var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
class SimpleSentrySpan {
  constructor(options) {
    __publicField(this, "span", null);
    __publicField(this, "name");
    this.name = options.name;
    if (options.Sentry) {
      const span = options.Sentry().startInactiveSpan(
        {
          name: this.name,
          op: "feathers_pinia",
          forceTransaction: true,
          attributes: options.attributes
        }
      );
      this.span = span;
    }
  }
  fail(error) {
    if (typeof error === "string") {
      this.span?.setStatus({ code: 2, message: error });
    } else if (typeof error === "object") {
      this.span?.setStatus({ code: 2, message: `${error.name}: ${error.message}` });
    }
    this.span?.end();
    this.span = null;
  }
  end() {
    this.span?.setStatus({ code: 1 });
    this.span?.end();
    this.span = null;
  }
  addEvent(name, attributes) {
    this.span?.addEvent(`${this.name}: ${name}`, attributes);
  }
}

const defineGlobals = (options) => {
  const servicePathRegister = /* @__PURE__ */ new Map();
  const storeRegister = {};
  function getStoreByName(service) {
    return storeRegister[service];
  }
  function servicePathForObject(object) {
    if (object.uuid && servicePathRegister.has(object.uuid)) {
      return servicePathRegister.get(object.uuid);
    }
  }
  function isOfType(object, servicePath) {
    return servicePathForObject(object) === servicePath;
  }
  const globalUuidToIdMapper = shallowReactive(/* @__PURE__ */ new Map());
  function toServerId(id) {
    return globalUuidToIdMapper.get(id);
  }
  function sentrySpan(_options) {
    return new SimpleSentrySpan({
      Sentry: options.Sentry,
      ..._options
    });
  }
  return {
    servicePathRegister,
    servicePathForObject,
    isOfType,
    globalUuidToIdMapper,
    toServerId,
    app: options.app,
    connection: options.connection ?? (() => void 0),
    transformDateByServicePath: {},
    storeRegister,
    getStoreByName,
    Sentry: options.Sentry,
    sentrySpan
  };
};

function definePublisher() {
  const subscribers = /* @__PURE__ */ new Set();
  const publish = (...args) => {
    subscribers.forEach((subscriber) => subscriber(...args));
  };
  const on = (subscriber) => {
    const fn = (...args) => subscriber(...args);
    subscribers.add(fn);
    tryOnScopeDispose(() => {
      subscribers.delete(fn);
    });
  };
  return [publish, on];
}

function defineServiceEventTransformed(service, servicePath, eventName, cb, options) {
  const subscribers = /* @__PURE__ */ new Set();
  const silentSubscribers = /* @__PURE__ */ new Set();
  const handler = async (_item) => {
    const item = await cb(_item) ?? _item;
    if (item === false) {
      return;
    }
    subscribers.forEach((subscriber) => subscriber(item));
  };
  let subscribed = false;
  function listen(active = true) {
    if (active === subscribed) {
      return;
    }
    if (active) {
      subscribed = true;
      service.on(eventName, handler);
    } else {
      subscribed = false;
      service.off(eventName, handler);
    }
  }
  const on = (subscriber) => {
    listen();
    if (subscriber) {
      const fn = (item) => subscriber(item);
      subscribers.add(fn);
      const stop = () => {
        subscribers.delete(fn);
        if (!subscribers.size && !silentSubscribers.size) {
          listen(false);
        }
      };
      tryOnScopeDispose(stop);
      return stop;
    } else {
      const id = {};
      silentSubscribers.add(id);
      const stop = () => {
        silentSubscribers.delete(id);
        if (!subscribers.size && !silentSubscribers.size) {
          listen(false);
        }
      };
      tryOnScopeDispose(stop);
      return stop;
    }
  };
  if (options?.immediate) {
    listen();
    tryOnScopeDispose(() => {
      listen(false);
    });
  }
  return on;
}

function defineRequesting({
  servicePath,
  eventName
}) {
  const currentlyRequesting = shallowRef(0);
  const isRequesting = computed(() => currentlyRequesting.value > 0);
  let requestedById = void 0;
  function add(entity) {
    if (!entity || !("id" in entity)) {
      return;
    }
    if (!requestedById) {
      requestedById = {};
    }
    requestedById[entity.id] = entity;
  }
  const result = {
    isRequesting,
    request,
    deferred: void 0
  };
  function request(cb) {
    if (!result.deferred) {
      result.deferred = promiseWithResolvers();
    }
    currentlyRequesting.value++;
    const promise = cb().then((value) => {
      add(value);
      return value;
    }).finally(() => {
      nextTick().then(() => {
        currentlyRequesting.value--;
        if (currentlyRequesting.value <= 0) {
          result.deferred?.resolve({ ...requestedById ?? {} });
          requestedById = void 0;
          result.deferred = void 0;
        }
      });
    });
    return promise;
  }
  return result;
}

function getPropertyInfo(input) {
  const {
    properties: _properties,
    relations: _relations = {},
    virtual = {}
  } = isSchemaUnion(input) ? Type.Composite(input.anyOf) : input;
  const properties = _properties;
  const relations = _relations;
  if (isSchemaUnion(input)) {
    for (const variation of input.anyOf) {
      if (variation.relations)
        Object.assign(relations, variation.relations);
    }
  }
  const dateKeySet = /* @__PURE__ */ new Set();
  const virtualKeySet = /* @__PURE__ */ new Set();
  const nonVirtualKeySet = /* @__PURE__ */ new Set();
  const readonlyKeySet = /* @__PURE__ */ new Set();
  const primitiveKeySet = /* @__PURE__ */ new Set();
  const complexKeySet = /* @__PURE__ */ new Set();
  for (const key in properties) {
    const propertyInfo = properties[key];
    const type = getPropertyType(propertyInfo);
    if (type === "Date") {
      dateKeySet.add(key);
    } else if (type === "string" || type === "number" || type === "integer" || type === "boolean" || type === "RegExp") {
      primitiveKeySet.add(key);
    } else {
      complexKeySet.add(key);
    }
    if (isSchemaVirtual(propertyInfo) || isSchemaFrontendOnly(propertyInfo)) {
      virtualKeySet.add(key);
    } else {
      nonVirtualKeySet.add(key);
    }
    if (isSchemaReadonly(propertyInfo)) {
      readonlyKeySet.add(key);
    }
  }
  const nonVirtualNonReadonlyKeySet = new Set(
    [...nonVirtualKeySet].filter((key) => !readonlyKeySet.has(key))
  );
  return {
    properties,
    relations,
    relationKeys: Object.keys(relations),
    belongsToKeys: Object.keys(relations).filter((key) => relations[key].asArray === false),
    hasManyKeys: Object.keys(relations).filter((key) => relations[key].asArray === true),
    virtual,
    dateKeySet,
    dateKeys: [...dateKeySet],
    virtualKeySet,
    virtualKeys: [...virtualKeySet],
    readonlyKeySet,
    readonlyKeys: [...readonlyKeySet],
    primitiveKeySet,
    primitiveKeys: [...primitiveKeySet],
    complexKeySet,
    complexKeys: [...complexKeySet],
    nonVirtual: {
      keys: [...nonVirtualKeySet],
      primitives: [...primitiveKeySet].filter((key) => nonVirtualKeySet.has(key)),
      dates: [...dateKeySet].filter((key) => nonVirtualKeySet.has(key)),
      complex: [...complexKeySet].filter((key) => nonVirtualKeySet.has(key))
    },
    nonVirtualNonReadonlyKeySet,
    nonVirtualNonReadonlyKeys: [...nonVirtualNonReadonlyKeySet]
  };
}
function hasDefaultValue(schema) {
  if ("default" in schema) {
    return true;
  }
  if (isSchemaUnion(schema)) {
    return schema.anyOf.some((value) => "default" in value);
  }
  return false;
}
function getDefaultValue(schema) {
  if ("default" in schema) {
    return schema.default;
  }
  if (isSchemaUnion(schema)) {
    const schemaWithDefault = schema.anyOf.find((value) => "default" in value);
    return schemaWithDefault?.default;
  }
}
function getPropertyType(schema) {
  if (schema.type === "string" || schema.type === "number" || schema.type === "integer" || schema.type === "boolean" || schema.type === "array" || schema.type === "object" || schema.type === "RegExp") {
    return schema.type;
  }
  if (schema[Kind] === "Date" || schema.type === "string" && schema.format === "date-time") {
    return "Date";
  }
  if (schema[Kind] === "Any") {
    return "Any";
  }
  if (isSchemaUnion(schema)) {
    for (const subSchema of schema.anyOf) {
      const type = getPropertyType(subSchema);
      if (type) {
        return type;
      }
    }
  } else if (isSchemaIntersect(schema)) {
    for (const subSchema of schema.allOf) {
      const type = getPropertyType(subSchema);
      if (type) {
        return type;
      }
    }
  }
  return void 0;
}

function uuidForServerId(name, id) {
  return id ? `${name}-${id}` : uid();
}
function getScopeFromFeathersPiniaStore(store) {
  if ("createScope" in store) {
    return store.createScope();
  } else {
    return store;
  }
}

function createTransformData(servicePath, globalUuidToIdMapper, propertyInfo, getStoreByName) {
  const {
    properties,
    relations,
    dateKeySet,
    dateKeys,
    virtual,
    primitiveKeys,
    complexKeys,
    nonVirtual
  } = propertyInfo;
  const relationKeys = Object.keys(relations ?? {});
  const relationBelongsToKeys = relationKeys.filter((key) => !relations[key].asArray);
  const relationHasManyKeys = relationKeys.filter((key) => relations[key].asArray);
  const virtualKeys = Object.keys(virtual);
  const keysWithDefaults = [];
  for (const key in properties) {
    const propertyInfo2 = properties[key];
    if (hasDefaultValue(propertyInfo2)) {
      keysWithDefaults.push(key);
    }
  }
  function newItem(data, uuid, scope) {
    return shallowReactive({
      entity: reactive(_newItem({ uuid, ...data }, scope)),
      originalData: reactive(data)
    });
  }
  function _newItem(_item, scope) {
    const item = copy(_item);
    const hasId = item.id !== void 0;
    if (!hasId) {
      for (let i = 0, n = keysWithDefaults.length; i < n; i++) {
        const key = keysWithDefaults[i];
        if (key in item) {
          continue;
        }
        const propertyInfo2 = properties[key];
        const defaultValue = getDefaultValue(propertyInfo2);
        item[key] = dateKeySet.has(key) ? defaultValue && new Date(defaultValue) : defaultValue;
      }
    }
    for (let i = 0, n = relationBelongsToKeys.length; i < n; i++) {
      const foreignKey = relationBelongsToKeys[i];
      const relationMeta = relations[foreignKey];
      const idKey = relationMeta.keyHere;
      const uuidKey = `${foreignKey}Uuid`;
      const foreignService = relationMeta.service;
      let value = item[foreignKey];
      const useRelationStore = getStoreByName(foreignService);
      if (!useRelationStore) {
        continue;
      }
      const relationStore = useRelationStore();
      const getAndUpdateUuidForServerId = (id) => {
        if (!id) {
          return null;
        }
        const uuid = uuidForServerId(relationMeta.service, id);
        if (!relationStore.uuidToIdMapper.has(uuid) || !globalUuidToIdMapper.has(uuid)) {
          relationStore.updateIdForUuid(uuid, id);
        }
        return uuid;
      };
      if (value) {
        if (!value.uuid) {
          relationStore.transformDates(value);
          const populateData = copy(value);
          value = relationStore.scopelessConstruct(populateData, { scope });
        }
        item[uuidKey] = value.uuid;
        if (value.id) {
          item[idKey] = value.id;
        }
      } else if (item[idKey]) {
        item[uuidKey] = getAndUpdateUuidForServerId(item[idKey]);
      }
      if (!item[uuidKey]) {
        item[uuidKey] = null;
      }
      Object.defineProperty(item, idKey, {
        get() {
          if (this[uuidKey] === null) {
            return null;
          }
          return relationStore.uuidToIdMapper.get(this[uuidKey]) ?? void 0;
        },
        set(newId) {
          this[uuidKey] = getAndUpdateUuidForServerId(newId);
        }
      });
      Object.defineProperty(item, foreignKey, {
        get() {
          const id = this[idKey];
          if (id === null) {
            return null;
          }
          const uuid = this[uuidKey];
          const item2 = (uuid && relationStore.localFind(uuid)) ?? (id && relationStore.localFind(id)) ?? (id ? void 0 : null);
          if (item2) {
            if (scope && !scope.has(item2)) {
              scope.add(item2);
            }
            return item2;
          }
          if (id) {
            relationStore.enqueueGet(id).catch(() => {
            });
          }
        },
        set(newRelationValue) {
          if (newRelationValue === null) {
            this[uuidKey] = null;
            this[idKey] = null;
          } else if ("id" in newRelationValue || "uuid" in newRelationValue) {
            this[uuidKey] = newRelationValue.uuid ? newRelationValue.uuid : getAndUpdateUuidForServerId(newRelationValue.id);
          }
        }
      });
    }
    for (let i = 0, n = virtualKeys.length; i < n; i++) {
      const key = virtualKeys[i];
      const propertyInfo2 = virtual[key];
      const populateData = copy(propertyInfo2.getPopulateData(item));
      if (!populateData) {
        item[key] = propertyInfo2.asArray ? [] : null;
        continue;
      }
      const useRelationStore = getStoreByName(propertyInfo2.service);
      if (!useRelationStore) {
        continue;
      }
      const relationStore = useRelationStore();
      if (Array.isArray(populateData) || propertyInfo2.asArray) {
        const arrayPopulateData = Array.isArray(populateData) ? populateData : [];
        arrayPopulateData.forEach((item2) => {
          if (!item2.id || typeof item2.id !== "number")
            return;
          if (!relationStore.localFind(item2.id)) {
            relationStore.scopelessConstruct(item2, { scope });
          }
        });
        Object.defineProperty(item, key, {
          get() {
            return arrayPopulateData.map(
              (item2) => item2.id && typeof item2.id === "number" ? relationStore.localFind(item2.id) : ""
            ).filter((value) => !!value);
          },
          configurable: true
        });
      } else if (populateData) {
        if (!populateData.id || typeof populateData.id !== "number")
          continue;
        if (!relationStore.localFind(populateData.id)) {
          relationStore.scopelessConstruct(populateData, { scope });
        }
        Object.defineProperty(item, key, {
          get() {
            return populateData.id && typeof populateData.id === "number" ? relationStore.localFind(populateData.id) : populateData.id;
          },
          configurable: true
        });
      }
    }
    return item;
  }
  function update(entityRef, _fromServer) {
    const entity = entityRef.entity;
    const original = entityRef.originalData;
    const fromServer = _fromServer;
    for (let i = 0, n = primitiveKeys.length; i < n; i++) {
      const key = primitiveKeys[i];
      const value = fromServer[key];
      if (key in fromServer && entity[key] !== value) {
        entity[key] = value;
      }
      if (original && key in fromServer && original[key] !== value) {
        original[key] = value;
      }
    }
    for (let i = 0, n = dateKeys.length; i < n; i++) {
      const key = dateKeys[i];
      try {
        const value = fromServer[key]?.getTime();
        if (key in fromServer && entity[key]?.getTime() !== value) {
          entity[key] = value && new Date(value);
        }
        if (original && key in fromServer && original[key]?.getTime() !== value) {
          original[key] = new Date(value);
        }
      } catch (e) {
        console.error(servicePath, key, "original", original[key], "entity", entity[key]);
        throw e;
      }
    }
    for (let i = 0, n = complexKeys.length; i < n; i++) {
      const key = complexKeys[i];
      const value = fromServer[key];
      if (key in fromServer && !deepEqual(entity[key], value)) {
        entity[key] = copy(value);
      }
      if (original && key in fromServer && !deepEqual(original[key], value)) {
        original[key] = copy(value);
      }
    }
    for (let i = 0, n = relationHasManyKeys.length; i < n; i++) {
      const key = relationHasManyKeys[i];
      if (key in fromServer) {
        entity[key] = fromServer[key];
      }
    }
    if (!original) {
      entityRef.originalData = fromServer;
    }
    return entityRef;
  }
  function reset(entityRef) {
    if (!entityRef.originalData) {
      return entityRef;
    }
    const entity = entityRef.entity;
    const original = entityRef.originalData;
    for (let i = 0, n = primitiveKeys.length; i < n; i++) {
      const key = primitiveKeys[i];
      if (!(key in original)) {
        continue;
      }
      const value = original[key];
      if (entity[key] !== value) {
        entity[key] = value;
      }
    }
    for (let i = 0, n = dateKeys.length; i < n; i++) {
      const key = dateKeys[i];
      if (!(key in original)) {
        continue;
      }
      const value = original[key]?.getTime();
      if (entity[key]?.getTime() !== value) {
        entity[key] = value && new Date(value);
      }
    }
    for (let i = 0, n = complexKeys.length; i < n; i++) {
      const key = complexKeys[i];
      if (!(key in original)) {
        continue;
      }
      const value = original[key];
      if (!deepEqual(entity[key], value)) {
        entity[key] = copy(value);
      }
    }
    return entityRef;
  }
  return {
    new: newItem,
    update,
    reset
  };
}

function createHasChanges(servicePath, propertyInfo, getRefFor) {
  const { nonVirtual } = propertyInfo;
  return function hasChanges(_entity, options) {
    const explainValue = (msg, value, ...meta) => {
      return options?.explain && value ? msg : value;
    };
    if (!_entity)
      return explainValue("No entity provided", false);
    if (!_entity.id) {
      return explainValue("Entity has no id and therefore is not in the db", true);
    }
    const entityRef = getRefFor(_entity.uuid);
    if (!entityRef || !entityRef?.originalData) {
      return explainValue("EntityRef is falsy or originalData is falsy", false);
    }
    const original = entityRef.originalData;
    const entity = _entity;
    for (let i = 0, len = nonVirtual.primitives.length; i < len; i++) {
      const key = nonVirtual.primitives[i];
      if (original[key] !== entity[key]) {
        return explainValue(`${key} is not equal to original primitive`, true, {
          original: original[key],
          current: entity[key]
        });
      }
    }
    for (let i = 0, len = nonVirtual.dates.length; i < len; i++) {
      const key = nonVirtual.dates[i];
      try {
        if (original[key]?.getTime() !== entity[key]?.getTime()) {
          return explainValue(`${key} is not equal to original date`, true, {
            original: original[key],
            current: entity[key]
          });
        }
      } catch (e) {
        console.error(servicePath ?? "", key, "original", original, "entity", entity);
        throw e;
      }
    }
    for (let i = 0, len = nonVirtual.complex.length; i < len; i++) {
      const key = nonVirtual.complex[i];
      if (!deepEqual(original[key], entity[key])) {
        return explainValue(`${key} is not equal to original complex`, true, {
          original: original[key],
          current: entity[key]
        });
      }
    }
    return explainValue(`No changes detected. End of function`, false);
  };
}

const makeLogger = (debug, ...prepend) => {
  return {
    log: debug ? console.log.bind(console, ...prepend) : noop,
    warn: debug ? console.warn.bind(console, ...prepend) : noop,
    error: console.error.bind(console, ...prepend),
    makeLogger: (debug2, prepend2) => makeLogger(debug || debug2, ...prepend, prepend2)
  };
};

function createTransformDates(servicePath, transformDateByServicePath, propertyInfo) {
  const { dateKeys, relations, belongsToKeys, hasManyKeys } = propertyInfo;
  const transformer = (item) => {
    for (let i = 0, n = dateKeys.length; i < n; i++) {
      const key = dateKeys[i];
      if (item[key]) {
        item[key] = new Date(item[key]);
      }
    }
    for (let i = 0, n = belongsToKeys.length; i < n; i++) {
      const key = belongsToKeys[i];
      if (!(key in item)) {
        continue;
      }
      const relationPath = relations[key].service;
      if (relationPath in transformDateByServicePath) {
        transformDateByServicePath[relationPath](item[key]);
      } else {
        console.error(`No date transformer found for ${relationPath} from ${servicePath}:${key}`);
      }
    }
    for (let i = 0, n = hasManyKeys.length; i < n; i++) {
      const key = hasManyKeys[i];
      if (!(key in item)) {
        continue;
      }
      const relationPath = relations[key].service;
      if (relationPath in transformDateByServicePath) {
        const transform = transformDateByServicePath[relationPath];
        for (let j = 0, m = item[key].length; j < m; j++) {
          transform(item[key][j]);
        }
      } else {
        console.error(`No date transformer found for ${relationPath} from ${servicePath}:${key}`);
      }
    }
  };
  transformDateByServicePath[servicePath] = transformer;
  return transformer;
}

const defineStoreItems = ({
  globals,
  options
}) => {
  const app = globals.app();
  const connection = globals.connection();
  if (connection) {
    const { servicePath } = options;
    app.use(servicePath, connection.service(servicePath), {
      methods: options.methods ?? ["find", "get", "create", "update", "patch", "remove"]
    });
  }
  const service = markRaw(
    app.service(options.servicePath)
  );
  const propertyInfo = getPropertyInfo(options.schema);
  const logger = makeLogger(options.debug, options.servicePath);
  const allowsMulti = defineAllowsMulti({ multi: options.multi });
  const transformDates = createTransformDates(
    options.servicePath,
    globals.transformDateByServicePath,
    propertyInfo
  );
  options.setupHook?.({
    app,
    service,
    path: options.servicePath,
    schema: options.schema,
    transformDates
  });
  const items = shallowReactive(/* @__PURE__ */ new Set());
  const itemsByIdAndUuid = shallowReactive(/* @__PURE__ */ new Map());
  const itemsNotToRemove = computedCompareShallowArray(() => {
    const result2 = [];
    for (const ref2 of items) {
      const entityRef = ref2.deref();
      if (entityRef && !itemsToRemove.has(ref2)) {
        result2.push(entityRef);
      }
    }
    return result2;
  });
  function add(ref2) {
    if (items.has(ref2))
      return;
    items.add(ref2);
    const entityRef = ref2.deref();
    if (entityRef) {
      const { id, uuid } = entityRef.entity;
      if (id) {
        itemsByIdAndUuid.set(id, ref2);
      }
      if (uuid) {
        itemsByIdAndUuid.set(uuid, ref2);
      }
    }
  }
  function del(ref2) {
    setDeleteHas(items, ref2);
    const toRemove = /* @__PURE__ */ new Set();
    for (const [key, value] of itemsByIdAndUuid) {
      if (value === ref2) {
        toRemove.add(key);
      }
    }
    for (const key of toRemove) {
      itemsByIdAndUuid.delete(key);
    }
  }
  const scopes = shallowReactive(/* @__PURE__ */ new Set());
  const itemsToRemove = shallowReactive(/* @__PURE__ */ new Set());
  const _itemsToRemoveArray = computed(() => [...itemsToRemove]);
  const idsToRemove = computed(() => {
    const ids = /* @__PURE__ */ new Set();
    for (const item of itemsToRemove) {
      const id = item.deref()?.entity.id;
      if (id) {
        ids.add(id);
      }
    }
    return ids;
  });
  function _cleanStore() {
    for (const ref2 of items) {
      if (!ref2.deref()) {
        del(ref2);
      }
    }
    for (const ref2 of itemsToRemove) {
      if (!ref2.deref()) {
        itemsToRemove.delete(ref2);
      }
    }
  }
  const cleanStore = debounce(_cleanStore, 100);
  function localFind(idOrIds) {
    if (Array.isArray(idOrIds)) {
      const ids = idOrIds;
      const result2 = [];
      for (const id of ids) {
        const entity = itemsByIdAndUuid.get(id)?.deref()?.entity;
        if (entity) {
          result2.push(entity);
        }
      }
      return result2;
    } else {
      return itemsByIdAndUuid.get(idOrIds)?.deref()?.entity;
    }
  }
  function getRefFor(id, weakRef) {
    const result2 = weakRef ? itemsByIdAndUuid.get(id) : itemsByIdAndUuid.get(id)?.deref();
    return result2;
  }
  function markForRemove(entitiesOrEntity) {
    function forItem(item) {
      const weakRef = getRefFor(item.uuid, true);
      if (!weakRef) {
        return "notFound";
      }
      const entity = weakRef.deref();
      if (entity?.entity && !entity.entity.id || !entity) {
        removeWeakRefFromStore(weakRef);
        return "removed";
      } else {
        setAddHas(itemsToRemove, weakRef);
        return "marked";
      }
    }
    return Array.isArray(entitiesOrEntity) ? entitiesOrEntity.map(forItem) : forItem(entitiesOrEntity);
  }
  function removeFromStore(entitiesOrEntity) {
    function forItem(item) {
      const weakRef = getRefFor(item.uuid, true);
      if (!weakRef) {
        return "notFound";
      }
      removeWeakRefFromStore(weakRef);
      return "removed";
    }
    return Array.isArray(entitiesOrEntity) ? entitiesOrEntity.map(forItem) : forItem(entitiesOrEntity);
  }
  const [publishDeleteEntity, onDeletedEntityRef] = definePublisher();
  function removeWeakRefFromStore(weakRef) {
    del(weakRef);
    itemsToRemove.delete(weakRef);
    const entity = weakRef.deref();
    if (entity) {
      for (const scope of scopes) {
        setDeleteHas(scope, entity);
      }
      publishDeleteEntity(entity);
    }
  }
  const uuidToIdMapper = shallowReactive(/* @__PURE__ */ new Map());
  function updateIdForUuid(uuid, newId) {
    uuidToIdMapper.set(uuid, newId);
    globals.globalUuidToIdMapper.set(uuid, newId);
    const ref2 = itemsByIdAndUuid.get(uuid);
    if (ref2 && itemsByIdAndUuid.get(newId) !== ref2) {
      itemsByIdAndUuid.set(newId, ref2);
    }
  }
  function toServerId(id) {
    return uuidToIdMapper.get(id);
  }
  const registry = new FinalizationRegistry((cleanup) => {
    cleanup();
  });
  function _scopelessConstruct(data, constructOptions) {
    logger.log("scopelessConstruct", data, constructOptions);
    if (data.id != null) {
      const entityRef2 = getRefFor(data.id);
      if (entityRef2) {
        transform.update(entityRef2, data);
        if (constructOptions?.scope) {
          setAddHas(constructOptions?.scope, entityRef2);
        }
        return entityRef2;
      }
    }
    const uuid = uuidForServerId(options.servicePath, data.id);
    if (data.id != null) {
      updateIdForUuid(uuid, data.id);
    }
    const entityRef = transform.new(data, uuid, constructOptions?.scope);
    logger.log("scopelessConstruct", entityRef);
    const { entity } = entityRef;
    globals.servicePathRegister.set(entity.uuid, options.servicePath);
    const ref2 = new WeakRef(entityRef);
    if (!constructOptions?.dontAddToStore) {
      logger.log("Add ref to store", ref2);
      add(ref2);
      registry.register(entity, () => {
        del(ref2);
        globals.servicePathRegister.delete(entity.uuid);
        cleanStore();
      });
      if (constructOptions?.scope) {
        setAddHas(constructOptions.scope, entityRef);
      }
    }
    return entityRef;
  }
  function scopelessConstruct(data, constructOptions) {
    return _scopelessConstruct(data, constructOptions).entity;
  }
  const isConstructingCount = ref(0);
  const isConstructing = computed(() => isConstructingCount.value > 0);
  const debounced = createDataDebounce(service, options.servicePath, {
    get: 180,
    create: 100,
    patch: 66,
    remove: 66
  }, {
    isConstructingCount,
    scopelessConstruct
  });
  const creating = defineRequesting({ servicePath: options.servicePath, eventName: "created" });
  const updating = defineRequesting({ servicePath: options.servicePath, eventName: "updated" });
  const removing = defineRequesting({ servicePath: options.servicePath, eventName: "removed" });
  function promiseManipulating() {
    const promises = [];
    if (creating.deferred) {
      promises.push(creating.deferred.promise);
    }
    if (updating.deferred) {
      promises.push(updating.deferred.promise);
    }
    if (removing.deferred) {
      promises.push(removing.deferred.promise);
    }
    if (!promises.length) {
      return;
    }
    return Promise.all(promises).then(noop).catch(noop);
  }
  const isSaving = computed(
    () => creating.isRequesting.value || updating.isRequesting.value || removing.isRequesting.value
  );
  const transform = createTransformData(
    options.servicePath,
    globals.globalUuidToIdMapper,
    propertyInfo,
    globals.getStoreByName
  );
  const hasChanges = createHasChanges(options.servicePath, propertyInfo, getRefFor);
  const onCreated = defineServiceEventTransformed(
    service,
    options.servicePath,
    "created",
    async (item) => {
      if (creating.deferred) {
        const byId = await creating.deferred.promise;
        const entity = byId[item.id];
        if (entity) {
          return entity;
        }
      }
      transformDates(item);
      return scopelessConstruct(item);
    },
    { immediate: true }
  );
  const onPatched = defineServiceEventTransformed(
    service,
    options.servicePath,
    "patched",
    async (item) => {
      if (creating.deferred) {
        await creating.deferred.promise;
      }
      if (updating.deferred) {
        const byId = await updating.deferred.promise;
        const entity = byId[item.id];
        if (entity) {
          return entity;
        }
      }
      transformDates(item);
      let entityRef = getRefFor(item.id);
      if (entityRef && options.transformHooks?.beforePatched) {
        options.transformHooks.beforePatched(entityRef);
      }
      entityRef = entityRef ? transform.update(entityRef, item) : _scopelessConstruct(item);
      if (options.transformHooks?.afterPatched) {
        options.transformHooks.afterPatched(entityRef);
      }
      return entityRef.entity;
    },
    { immediate: true }
  );
  const onUpdated = defineServiceEventTransformed(
    service,
    options.servicePath,
    "updated",
    async (item) => {
      if (updating.deferred) {
        const byId = await updating.deferred.promise;
        const entity = byId[item.id];
        if (entity) {
          return entity;
        }
      }
      transformDates(item);
      let entityRef = getRefFor(item.id);
      if (entityRef && options.transformHooks?.beforeUpdated) {
        options.transformHooks.beforeUpdated(entityRef);
      }
      entityRef = entityRef ? transform.update(entityRef, item) : _scopelessConstruct(item);
      if (options.transformHooks?.afterUpdated) {
        options.transformHooks?.afterUpdated?.(entityRef);
      }
      return entityRef.entity;
    },
    { immediate: true }
  );
  const onRemoved = defineServiceEventTransformed(
    service,
    options.servicePath,
    "removed",
    async (item) => {
      if (removing.deferred) {
        const byId = await removing.deferred.promise;
        const entity = byId[item.id];
        if (entity) {
          return;
        }
      }
      const weakRef = getRefFor(item.id, true);
      if (!weakRef)
        return;
      removeWeakRefFromStore(weakRef);
    },
    { immediate: true }
  );
  async function save(itemOrItems, opt) {
    if (!itemOrItems) {
      return false;
    }
    const targetEntities = Array.isArray(itemOrItems) ? [...itemOrItems] : [itemOrItems];
    if (opt?.deleteUnspecifiedItems) {
      for (const ref2 of itemsToRemove) {
        const entityRef = ref2.deref();
        if (entityRef) {
          targetEntities.push(entityRef.entity);
        }
      }
    }
    if (targetEntities.length === 0) {
      return false;
    }
    async function prepareSaving(entity) {
      const saveOptions = {
        update: false,
        deep: false,
        deepSilentFail: true,
        deleteUnspecifiedItems: false,
        ...opt
      };
      if (saveOptions._relationDependencySet && saveOptions._relationDependencySet.has(entity)) {
        logger.warn("Probably recursive relation deep save detected for entity", entity.id);
        return false;
      }
      const isNewEntity = !entity.id;
      const weakRef = getRefFor(entity.uuid, true);
      const entityRef = weakRef?.deref();
      if (!weakRef || !entityRef || !entityRef?.originalData && !saveOptions.update && !isNewEntity) {
        return false;
      }
      const shouldItemGetDeleted = itemsToRemove.has(weakRef);
      if (shouldItemGetDeleted) {
        if (!isNewEntity) {
          return {
            weakRef,
            entityRef,
            entity,
            method: "remove",
            data: {}
          };
        } else {
          return false;
        }
      }
      const data2 = {};
      if (saveOptions.deep) {
        for (const Key in options.schema.relations) {
          const propertyOptions = options.schema.relations[Key];
          if (propertyOptions.asArray || !propertyOptions.keyHere) {
            continue;
          }
          const relation = entity[Key];
          const uuidKey = `${Key}Uuid`;
          if (relation) {
            const useRelationStore = globals.getStoreByName(propertyOptions.service);
            const relationStore = useRelationStore?.();
            if (relationStore) {
              if (
                // not already in dependencySet
                !saveOptions._relationDependencySet?.has(relation) && // is new or has changes
                (!relation.id || relationStore.hasChanges(relation))
              ) {
                const relationSet = saveOptions._relationDependencySet ?? /* @__PURE__ */ new Set();
                setAddHas(relationSet, entity);
                const saveResult = await relationStore.save(entity[Key], {
                  deep: saveOptions.deep,
                  deepSilentFail: saveOptions.deepSilentFail,
                  _relationDependencySet: relationSet
                }).catch((error) => {
                  if (saveOptions.deepSilentFail) {
                    logger.warn(
                      `Failed to save deep relation ${propertyOptions.keyHere} (entity: ${entity.id})`,
                      error
                    );
                    return false;
                  }
                  throw new Error(
                    `Failed to save deep relation for "${options.servicePath}" relation ${propertyOptions.keyHere} (entity: ${entity.id})`,
                    { cause: error }
                  );
                });
                if (saveResult === false && !saveOptions.deepSilentFail) {
                  throw new Error(
                    `Failed to save deep relation for "${options.servicePath}" relation ${propertyOptions.keyHere} (entity: ${entity.id})`
                  );
                }
              }
            } else {
              logger.warn(
                `Store for relation ${propertyOptions.keyHere} not found while trying to determine if a deep save is needed`,
                entity.id
              );
            }
          }
          if (isNewEntity || saveOptions.update || entityRef?.originalData && entityRef.originalData[propertyOptions.keyHere] !== entity[propertyOptions.keyHere]) {
            data2[propertyOptions.keyHere] = entity[propertyOptions.keyHere];
          }
          if (relation && !entity[propertyOptions.keyHere] && entity[uuidKey]) {
            logger.warn(
              `Relation ${Key} won't be save, because it is a unsaved object without server id`,
              entity.id
            );
          }
        }
      }
      for (let i = 0, n = propertyInfo.nonVirtualNonReadonlyKeys.length; i < n; i++) {
        const key = propertyInfo.nonVirtualNonReadonlyKeys[i];
        if (!(key in entity)) {
          continue;
        }
        if (isNewEntity || saveOptions.update || entityRef?.originalData && !deepEqual(
          entityRef.originalData[key],
          entity[key]
        )) {
          data2[key] = entity[key];
        }
      }
      return {
        weakRef,
        entityRef,
        entity,
        data: data2,
        skipSave: Object.keys(data2).length === 0,
        method: isNewEntity ? "create" : saveOptions.update ? "update" : "patch"
      };
    }
    let calledCreate = false;
    let calledPatch = false;
    let calledRemove = false;
    const debounceCreate = opt?.debounce && allowsMulti("create");
    const debouncePatch = opt?.debounce && allowsMulti("patch");
    const debounceRemove = opt?.debounce && allowsMulti("remove");
    function saveEntity(ctx) {
      if (!ctx) {
        return false;
      }
      if (ctx.skipSave) {
        return Promise.resolve(ctx.entity);
      }
      const { method, data: data2, weakRef, entity, entityRef } = ctx;
      const saveOptions = {
        update: false,
        deep: false,
        deepSilentFail: true,
        deleteUnspecifiedItems: false,
        ...opt
      };
      if (method === "create") {
        logger.log("create item", data2);
        calledCreate = true;
        return creating.request(
          () => debounced.create(data2, {
            debounce: allowsMulti("create")
          }).then((item) => {
            updateIdForUuid(entity.uuid, item.id);
            transform.update(entityRef, item);
            return entity;
          })
        );
      } else if (method === "update" || method === "patch") {
        logger.log("patch item", entity.id, data2);
        if (!saveOptions.update) {
          calledPatch = true;
        }
        return updating.request(
          () => debounced.patch(entity.id, data2, {
            debounce: allowsMulti("patch"),
            useUpdate: saveOptions.update
          }).then((item) => {
            transform.update(entityRef, item);
            return entity;
          })
        );
      } else if (method === "remove") {
        logger.log("remove item", entity.id);
        calledRemove = true;
        return removing.request(
          () => debounced.remove(entity.id, {
            debounce: allowsMulti("remove")
          }).then((item) => {
            removeWeakRefFromStore(weakRef);
            return entity;
          })
        );
      }
      return false;
    }
    const data = await Promise.all(targetEntities.map(async (e) => await prepareSaving(e)));
    const promises = data.map((e) => saveEntity(e));
    if (calledCreate && !debounceCreate) {
      debounced.triggerCreate();
    }
    if (calledPatch && !debouncePatch) {
      debounced.triggerPatch();
    }
    if (calledRemove && !debounceRemove) {
      debounced.triggerRemove();
    }
    const result2 = await Promise.all(promises);
    if (result2.some((value) => value === false)) {
      const failedEntities = result2.map((value, index) => value === false ? index : false).filter((value) => value !== false).map((index) => targetEntities[index]);
      logger.warn(
        `The following entities failed to save: ${failedEntities.map((value) => value.uuid).join(", ")}`
      );
      return false;
    }
    const entities = result2.map((value) => value).filter((value) => value !== false);
    if (Array.isArray(itemOrItems)) {
      return entities;
    }
    return entities[0] ?? false;
  }
  async function create(dataOrArray) {
    const constructed = Array.isArray(dataOrArray) ? dataOrArray.map((data) => scopelessConstruct(data)) : scopelessConstruct(dataOrArray);
    return save(constructed).catch((error) => {
      removeFromStore(constructed);
      throw error;
    });
  }
  function reset(entity) {
    if (!entity)
      return;
    function _reset(entity2) {
      const weakRef = getRefFor(entity2.uuid, true);
      const entityRef = weakRef?.deref();
      if (!weakRef || !entityRef || !entityRef?.originalData) {
        return;
      }
      setDeleteHas(itemsToRemove, weakRef);
      transform.reset(entityRef);
    }
    if (Array.isArray(entity)) {
      for (const e of entity) {
        _reset(e);
      }
    } else {
      _reset(entity);
    }
  }
  async function remove(entityOrEntities) {
    if (!entityOrEntities) {
      return void 0;
    }
    const isArray = Array.isArray(entityOrEntities);
    const entities = isArray ? entityOrEntities : [entityOrEntities];
    entities.forEach((entity) => {
      if (!entity.id) {
        removeFromStore(entity);
      }
    });
    const promises = Promise.all(
      entities.map(async (entity) => {
        if (!entity.id) {
          return entity;
        }
        const weakRef = getRefFor(entity.uuid, true);
        return removing.request(
          () => debounced.remove(entity.id, {
            debounce: allowsMulti("remove")
          }).then((item) => {
            if (weakRef) {
              removeWeakRefFromStore(weakRef);
            }
            return entity;
          })
        );
      })
    );
    debounced.triggerRemove();
    const result2 = (await promises).filter(isNonNullable);
    return isArray ? result2 : result2.at(0);
  }
  function resetOrRemove(entity) {
    if (!entity)
      return;
    if (entity.id != null) {
      reset(entity);
    } else {
      markForRemove(entity);
    }
  }
  const result = {
    service,
    servicePath: options.servicePath,
    schemaMeta: propertyInfo,
    scopes,
    items,
    itemsByIdAndUuid,
    itemsNotToRemove,
    add,
    delete: del,
    itemsToRemove,
    _itemsToRemoveArray,
    idsToRemove,
    cleanStore,
    localFind,
    getRefFor,
    create,
    // remove
    onDeletedEntityRef,
    markForRemove,
    removeFromStore,
    removeWeakRefFromStore,
    remove,
    reset,
    resetOrRemove,
    uuidToIdMapper,
    updateIdForUuid,
    toServerId,
    debounced,
    scopelessConstruct,
    _scopelessConstruct,
    // save
    save,
    isSaving,
    // events
    onCreated,
    onPatched,
    onUpdated,
    onRemoved,
    promiseManipulating,
    hasChanges,
    transformDates,
    defaultParams: options.defaultParams,
    isConstructingCount,
    isConstructing,
    // debug
    logger
  };
  return result;
};

function filterQuery(providedQuery) {
  const { $select, $limit, $skip, $sort, ...query } = providedQuery ?? {};
  return {
    $select,
    $limit,
    $skip,
    $sort,
    query
  };
}

const defineScope = ({
  store
}) => {
  const scope = /* @__PURE__ */ new Set();
  store.scopes.add(scope);
  function add(item) {
    if (scope.has(item)) {
      return;
    }
    scope.add(item);
  }
  function clear() {
    store.logger.log("Scope dispose");
    scope.clear();
    if (store.scopes.has(scope)) {
      store.scopes.delete(scope);
    }
  }
  const isDisposed = useDisposed();
  tryOnScopeDispose(() => {
    clear();
    nextTick().then(() => {
      if (store.scopes.size === 0) {
        store.cleanStore();
      }
    });
  });
  function construct(data, constructOptions) {
    return store.scopelessConstruct(data, {
      ...constructOptions,
      scope
    });
  }
  return {
    scope,
    construct,
    isDisposed,
    add
  };
};

const withDefaultParams = (defaultParams, params) => {
  if (params.skipDefaultParams) {
    return params;
  }
  return {
    ...defaultParams,
    ...params,
    query: {
      ...defaultParams?.query,
      ...params.query
    }
  };
};

const defineFind = ({
  store,
  scope,
  globals
}) => {
  return async function find(params, findOptions) {
    const span = globals.sentrySpan({ name: `find@${store.servicePath}` });
    const logger = store.logger.makeLogger(findOptions?.debug, "find");
    if (findOptions?.signal?.aborted || scope.isDisposed.value) {
      if (findOptions?.signal?.aborted)
        span.addEvent("Aborted");
      if (scope.isDisposed.value)
        span.addEvent("Scope disposed");
      span.end();
      return {
        data: [],
        total: 0,
        skip: 0,
        limit: 0
      };
    }
    params = withDefaultParams(store.defaultParams?.find, params);
    span.addEvent("Query feathers store");
    params.span = span.span ?? void 0;
    const findResult = await store.service.find(params);
    logger.log("findResult", findResult, params);
    const itemsFromFind = Array.isArray(findResult) ? findResult : "data" in findResult ? findResult.data : [findResult];
    span.addEvent("Query feathers store result", {
      length: itemsFromFind.length
    });
    if (findOptions?.signal?.aborted || scope.isDisposed.value) {
      if (findOptions?.signal?.aborted)
        span.addEvent("Aborted");
      if (scope.isDisposed.value)
        span.addEvent("Scope disposed");
      span.end();
      return {
        data: [],
        total: 0,
        skip: 0,
        limit: 0
      };
    }
    const result = [];
    span.addEvent("Add to store");
    try {
      store.isConstructingCount.value++;
      for (let i = 0, n = itemsFromFind.length; i < n; i++) {
        const item = itemsFromFind[i];
        if (!item.id)
          continue;
        const entity = scope.construct(item);
        result.push(entity);
      }
    } finally {
      store.isConstructingCount.value--;
    }
    span.end();
    return {
      data: result,
      total: "total" in findResult ? findResult.total : result.length,
      skip: "skip" in findResult ? findResult.skip : params.query?.$skip ?? 0,
      limit: "limit" in findResult ? findResult.limit : params.query?.$limit ?? 10
    };
  };
};

const defineUseCount = ({
  store,
  useFind
}) => {
  return function useCount(countOptions) {
    const logger = store.logger.makeLogger(countOptions.debug, "useCount");
    const params = computed(() => {
      const localParams = toValue(countOptions.params);
      if (localParams === null)
        return null;
      return {
        ...localParams,
        query: {
          ...localParams?.query,
          $limit: 0
        }
      };
    });
    const fetchParams = computed(() => {
      const fp = toValue(countOptions.fetchParams);
      if (fp === null) {
        return null;
      }
      if (fp === void 0) {
        return params.value;
      }
      return {
        ...fp,
        query: {
          ...fp.query,
          $limit: 0
        }
      };
    });
    const {
      isPending,
      state,
      totalServer: serverCount,
      totalStore: storeCount,
      fetch
    } = useFind({
      ...countOptions,
      params,
      fetchParams,
      reevaluateAfter: countOptions.reevaluateAfter ?? ["created", "patched", "updated", "removed"]
    });
    const count = computed(() => {
      return serverCount.value ?? storeCount.value;
    });
    const result = {
      storeCount,
      serverCount,
      count,
      isPending,
      state,
      fetch
    };
    logger.log("result", result, countOptions);
    return result;
  };
};

function createSorter($sort) {
  const sorter$1 = sorter($sort);
  return (a, b) => sorter$1(a.entity, b.entity);
}
const defineUseFind = ({
  store,
  scope,
  find
}) => {
  return function useFind(findOptions) {
    const logger = store.logger.makeLogger(findOptions?.debug, "useFind");
    const reevaluateAfter = findOptions.reevaluateAfter;
    const doLocalOnly = computed(() => toValue(findOptions.localOnly) ?? false);
    const [started, computedStarted] = defineComputedLazy(!findOptions.lazy);
    const params = computedDeepEqual(() => {
      const params2 = toValue(findOptions.params) ?? null;
      if (!params2)
        return params2;
      const idsToRemove = store.idsToRemove.value;
      const p2 = idsToRemove.size ? setQueryKeySafely(params2, "id", [...idsToRemove], "$nin") : params2;
      return withDefaultParams(store.defaultParams?.find, p2);
    });
    const fetchParams = computedDeepEqual(() => {
      const fp = toValue(findOptions.fetchParams);
      if (fp === null) {
        return null;
      }
      if (fp === void 0) {
        return params.value;
      }
      const idsToRemove = store.idsToRemove.value;
      const fp2 = idsToRemove.size ? setQueryKeySafely(fp, "id", [...idsToRemove], "$nin") : fp;
      return withDefaultParams(store.defaultParams?.find, fp2);
    });
    const canFetch = !doLocalOnly.value && !!fetchParams.value && toValue(findOptions.watchOnlyIf) !== false;
    const fetchImmediate = (findOptions.immediate ?? true) && canFetch;
    const state = shallowRef(
      fetchImmediate && started.value ? "pending" : canFetch ? "idle" : "success"
    );
    const haveLoaded = shallowRef(doLocalOnly.value);
    const lastError = shallowRef(null);
    const totalFromServer = shallowRef(void 0);
    const fetchResult = shallowRef([]);
    async function _fetchFromServer(params2, signal) {
      if (params2 === void 0 || params2 === null) {
        if (!signal.aborted)
          state.value = "success";
        return;
      }
      if (signal.aborted || scope.isDisposed.value) {
        return;
      }
      state.value = "pending";
      try {
        params2 = withDefaultParams(store.defaultParams?.find, params2);
        logger.log("fetch service.find", params2);
        const result = await find(params2, {
          signal,
          debug: findOptions.debug
        });
        if (signal.aborted || scope.isDisposed.value) {
          return;
        }
        if ("total" in result) {
          totalFromServer.value = result.total;
        } else {
          totalFromServer.value = null;
        }
        logger.log("fetchResult", result, findOptions);
        fetchResult.value = result.data;
        state.value = "success";
        if (!haveLoaded.value) {
          nextTick(() => haveLoaded.value = true);
        }
        return result;
      } catch (err) {
        console.warn(err);
        if (!signal.aborted) {
          state.value = "error";
          lastError.value = err;
          logger.error(err);
        }
      }
    }
    const debounceTime = computed(
      () => toValue(fetchParams)?.debounce ?? toValue(params)?.debounce ?? findOptions.debounce ?? 50
    );
    let _fetchFromServerDebounced = debounceTime.value ? asyncDebounce(_fetchFromServer, debounceTime.value, { leading: false, trailing: true }) : _fetchFromServer;
    watch(debounceTime, (time) => {
      logger.log("debounceTime changed", time);
      _fetchFromServerDebounced = time ? asyncDebounce(_fetchFromServer, time, { leading: false, trailing: true }) : _fetchFromServer;
    });
    let abortController = void 0;
    function fetch(_fetchParams) {
      const fp = _fetchParams ?? fetchParams.value;
      abortController?.abort();
      if (fp === void 0 || fp === null) {
        logger.log("fetchParams is null or undefined", fp);
        state.value = "success";
        return Promise.resolve(void 0);
      } else {
        state.value = "pending";
      }
      abortController = new AbortController();
      return _fetchFromServerDebounced(copy(fp), abortController.signal);
    }
    const resultFrom = computed(
      () => params.value?.resultFrom ?? fetchParams.value?.resultFrom ?? toValue(findOptions.resultFrom) ?? "client"
    );
    let oldFetchParams = false;
    watchOnlyIf(
      [doLocalOnly, fetchParams],
      ([doLocalOnly2, fetchParams2]) => {
        if (doLocalOnly2 || !fetchParams2 || deepEqual(fetchParams2, oldFetchParams)) {
          return;
        }
        oldFetchParams = fetchParams2;
        fetch(fetchParams2);
      },
      { immediate: fetchImmediate, onlyIf: () => toValue(findOptions.watchOnlyIf) !== false && started.value }
    );
    const filterFunctions = computed(() => {
      if (params.value === null) {
        return null;
      }
      const { $limit, $skip, $sort, query } = filterQuery(params.value?.query);
      const siftFunction = resultFrom.value === "server" ? void 0 : sift(query);
      const sorter$1 = !$sort || resultFrom.value === "server" ? void 0 : sorter($sort);
      const sorterWrapper = !$sort || resultFrom.value === "server" ? void 0 : createSorter($sort);
      return {
        $limit,
        $skip,
        $sort,
        sorter: sorter$1,
        sorterWrapper,
        siftFunction
      };
    });
    const isNotConstructing = computed(() => !store.isConstructing.value);
    const filteredArrays = computedOnlyIf((oldResult) => {
      const result = {
        allItems: [],
        newItems: []
      };
      if (params.value === null || !filterFunctions.value?.siftFunction) {
        if (oldResult && !oldResult.allItems.length) {
          return oldResult;
        }
        return result;
      }
      const { siftFunction } = filterFunctions.value;
      const items = store.itemsNotToRemove.value;
      for (let i = 0, n = items.length; i < n; i++) {
        const entityRef = items[i];
        const { entity } = entityRef;
        const matchesSiftFunction = !!siftFunction(entity);
        if (!matchesSiftFunction) {
          continue;
        }
        result.allItems.push(entityRef);
        if (!entity.id) {
          result.newItems.push(entity);
        }
      }
      if (!oldResult) {
        return result;
      }
      if (oldResult.allItems.length !== result.allItems.length || oldResult.newItems.length !== result.newItems.length) {
        return result;
      }
      for (let i = 0, n = result.allItems.length; i < n; i++) {
        if (result.allItems[i] !== oldResult.allItems[i]) {
          return result;
        }
      }
      return oldResult;
    }, {
      onlyIf: isNotConstructing
    });
    const allItems = computedCompareShallowArray(() => {
      if (!filterFunctions.value?.sorterWrapper) {
        return filteredArrays.value.allItems;
      }
      return [...filteredArrays.value.allItems].sort(filterFunctions.value.sorterWrapper);
    });
    const newItems = computedCompareShallowArray(() => {
      if (!filterFunctions.value?.sorter) {
        return filteredArrays.value.newItems;
      }
      return [...filteredArrays.value.newItems].sort(filterFunctions.value.sorter);
    });
    const dataServer = computedCompareShallowArray(() => {
      const idsToRemove = store.idsToRemove.value;
      if (idsToRemove.size === 0) {
        return fetchResult.value;
      }
      const result = [];
      for (const item of fetchResult.value) {
        if (!item.id || idsToRemove.has(item.id))
          continue;
        result.push(item);
      }
      return result;
    });
    const dataServerSortedOnClient = computedCompareShallowArray(() => {
      if (!filterFunctions.value) {
        return dataServer.value;
      }
      const { $limit, sorter } = filterFunctions.value;
      const addNewItems = $limit === void 0 || $limit === -1 ? Number.MAX_SAFE_INTEGER : $limit > 0 ? $limit - dataServer.value.length : 0;
      const items = [
        ...dataServer.value,
        ...addNewItems > 0 ? addNewItems > newItems.value.length ? newItems.value : newItems.value.slice(0, addNewItems) : []
      ];
      if (sorter) {
        items.sort(sorter);
      }
      return items;
    });
    const dataStore = computedCompareShallowArray(() => {
      if (!filterFunctions.value || !allItems.value.length) {
        return [];
      }
      const { $skip, $limit } = filterFunctions.value;
      let items = allItems.value;
      items = applyLimitAndSkip(items, { $limit, $skip });
      return items.map((ref) => {
        scope.add(ref);
        return ref.entity;
      });
    });
    store.onCreated((item) => {
      logger.log("created event", item, findOptions);
      if (!doLocalOnly.value && reevaluateAfter?.includes("created")) {
        logger.log("reevaluateAfter", item, findOptions);
        setTimeout(() => fetch(), 500);
      }
    });
    store.onPatched(() => {
      if (!doLocalOnly.value && reevaluateAfter?.includes("patched")) {
        setTimeout(() => fetch(), 100);
      }
    });
    store.onUpdated(() => {
      if (!doLocalOnly.value && reevaluateAfter?.includes("updated")) {
        setTimeout(() => fetch(), 100);
      }
    });
    store.onRemoved((item) => {
      let reEvaluate = false;
      if (item.id) {
        fetchResult.value = fetchResult.value.filter((value) => {
          const isItem = value.id === item.id;
          if (isItem)
            reEvaluate = true;
          return !isItem;
        });
      }
      if (!doLocalOnly.value && reevaluateAfter?.includes("removed") || reEvaluate) {
        setTimeout(() => fetch(), 100);
      }
    });
    const data = computedStarted(() => {
      if (resultFrom.value === "server") {
        return dataServer.value;
      } else if (resultFrom.value === "serverSortOnClient") {
        return dataServerSortedOnClient.value;
      }
      return dataStore.value;
    });
    const totalServer = computed(() => {
      if (totalFromServer.value === void 0) {
        return null;
      }
      return (totalFromServer.value ?? dataServer.value.length) + newItems.value.length - store.idsToRemove.value.size;
    });
    const totalStore = computed(() => allItems.value.length);
    const total = computedStarted(() => doLocalOnly.value ? totalStore.value : totalServer.value);
    const skip = computedStarted(() => filterFunctions.value?.$skip ?? 0);
    const limit = computedStarted(() => filterFunctions.value?.$limit ?? 0);
    const toReturn = {
      data,
      total,
      limit,
      skip,
      totalServer,
      totalStore,
      state: shallowReadonly(state),
      lastError: shallowReadonly(lastError),
      isPending: computedStarted(() => state.value === "pending"),
      haveLoaded: computedStarted(() => haveLoaded.value),
      // lazy
      started,
      // Maybe helpful for debugging
      params,
      fetchParams: params,
      allItems,
      newItems,
      fetch
    };
    logger.log("return", toReturn, findOptions);
    return toReturn;
  };
};

const defineUseGet = ({
  store,
  scope
}) => {
  return function useGet(getOptions) {
    const logger = store.logger.makeLogger(getOptions.debug, "useGet");
    const id = computed(() => toValue(getOptions.id));
    const fetchParams = computed(() => toValue(getOptions.fetchParams) ?? null);
    const state = shallowRef("idle");
    const lastError = shallowRef(null);
    const _hasLoaded = shallowRef(typeof id.value === "string");
    const debounceTime = getOptions.debounce ?? "data-fetcher";
    const [started, computedStarted] = defineComputedLazy(!getOptions.lazy);
    async function _fetchFromServer(idValue, fetchParams2, signal) {
      if (idValue === void 0 || idValue === null || typeof idValue === "string") {
        if (!signal.aborted)
          state.value = "success";
        return;
      }
      if (getOptions.lazy) {
        const item = store.localFind(idValue);
        if (item) {
          logger.log("Found entity in store", item);
          if (!signal.aborted) {
            state.value = "success";
            if (!_hasLoaded.value) {
              _hasLoaded.value = true;
            }
          }
          return;
        }
      }
      if (signal.aborted || scope.isDisposed.value) {
        return;
      }
      state.value = "pending";
      try {
        logger.log("Fetch from server", idValue, fetchParams2);
        const result2 = await store.debounced.get(idValue, {
          params: fetchParams2,
          debounce: debounceTime === "data-fetcher"
        });
        logger.log("Fetched from server", result2);
        if (signal.aborted || scope.isDisposed.value) {
          return;
        }
        state.value = "success";
        if (!_hasLoaded.value) {
          _hasLoaded.value = true;
        }
        return;
      } catch (err) {
        console.warn(err);
        if (!signal.aborted) {
          state.value = "error";
          if (!_hasLoaded.value) {
            _hasLoaded.value = true;
          }
          lastError.value = err;
          logger.error(err);
        }
      }
    }
    const fetchFromServer = debounceTime && debounceTime !== "data-fetcher" ? debounce(_fetchFromServer, debounceTime) : _fetchFromServer;
    if (!getOptions.localOnly) {
      let abortController = void 0;
      let lastFetch = void 0;
      watchOnlyIf(
        [id, started, fetchParams],
        ([_id, started2, fetchParams2]) => {
          const id2 = typeof _id === "number" ? _id : isInteger(_id) ? Number(_id) : _id;
          if (!id2 || !started2 || lastFetch && id2 === lastFetch?.id && deepEqual(fetchParams2, lastFetch?.params)) {
            return;
          }
          if (getOptions.lazy) {
            const storeResult = store.localFind(id2);
            if (storeResult) {
              logger.log("useGet", "Found entity in store - skip fetch", storeResult);
              state.value = "success";
              _hasLoaded.value = true;
              return;
            }
          }
          abortController?.abort();
          abortController = new AbortController();
          lastFetch = { id: id2, params: fetchParams2 };
          fetchFromServer(id2, fetchParams2, abortController.signal);
        },
        { immediate: getOptions.immediate ?? true, onlyIf: getOptions.watchOnlyIf }
      );
    }
    const result = computedStarted(() => {
      if (!id.value)
        return void 0;
      const entityRef = store.getRefFor(id.value);
      if (!entityRef)
        return void 0;
      scope.add(entityRef);
      return entityRef.entity;
    });
    if (result.bypassed) {
      _hasLoaded.value = true;
    }
    return {
      data: result,
      state: readonly(state),
      lastError: readonly(lastError),
      isPending: computedStarted(() => state.value === "pending"),
      hasLoaded: computedStarted(() => _hasLoaded.value),
      started
    };
  };
};

const defineUseFeathersItems = ({
  store,
  useFind
}) => {
  return function useFeathersItems(itemsOptions) {
    const logger = store.logger.makeLogger(itemsOptions.debug, "useFeathersItems");
    logger.log(itemsOptions);
    const defaultParams = computed(() => ({
      query: {
        $sort: {
          createdAt: 1,
          id: 1
        },
        ...toValue(itemsOptions.limit) !== void 0 ? { $limit: toValue(itemsOptions.limit) } : {}
      }
    }));
    const params = computed(() => {
      const p = toValue(itemsOptions.params);
      if (p === null)
        return null;
      return {
        ...defaultParams.value,
        ...p,
        query: {
          ...defaultParams.value.query,
          ...p?.query
        }
      };
    });
    const fetchParams = computed(() => {
      const fp = toValue(itemsOptions.fetchParams);
      if (fp === null) {
        return null;
      }
      if (fp === void 0) {
        return params.value;
      }
      return {
        ...defaultParams.value,
        ...fp,
        query: {
          ...defaultParams.value.query,
          ...fp.query
        }
      };
    });
    const useFindResult = useFind({
      params,
      fetchParams,
      localOnly: itemsOptions.localOnly,
      reevaluateAfter: itemsOptions.reevaluateAfter ?? [],
      resultFrom: itemsOptions.resultFrom,
      debounce: itemsOptions.debounce,
      debug: itemsOptions.debug,
      immediate: itemsOptions.immediate,
      watchOnlyIf: itemsOptions.watchOnlyIf,
      lazy: itemsOptions.lazy
    });
    const { newItems } = useFindResult;
    const _currentDataAndBefore = reactive(/* @__PURE__ */ new Set());
    watch(
      // use bypassed to not trigger immediately
      () => useFindResult.data.bypassed,
      (data) => {
        for (let i = 0, n = data.length; i < n; i++) {
          setAddHas(_currentDataAndBefore, data[i]);
        }
      },
      { immediate: true, flush: "sync" }
    );
    store.onDeletedEntityRef((entityRef) => {
      const { entity } = entityRef;
      if (_currentDataAndBefore.has(entity)) {
        _currentDataAndBefore.delete(entity);
      }
    });
    const _dataSplittedByToRemove = computed(() => {
      const current = [];
      const deleted = [];
      const idsToRemove = store.idsToRemove.value;
      if (!idsToRemove.size) {
        return { current: [..._currentDataAndBefore], deleted: [] };
      }
      for (const entity of _currentDataAndBefore) {
        if (entity.id && idsToRemove.has(entity.id)) {
          deleted.push(entity);
        } else {
          current.push(entity);
        }
      }
      return { current, deleted };
    });
    const currentDataAndBefore = computed(() => _dataSplittedByToRemove.value.current);
    const deletedItems = computed(() => _dataSplittedByToRemove.value.deleted);
    const hasNew = computed(() => !!newItems.value.length);
    const hasDeleted = computed(() => !!deletedItems.value.length);
    const _hasChanges = computed(() => {
      if (hasNew.value)
        return true;
      if (hasDeleted.value)
        return true;
      const data = currentDataAndBefore.value;
      if (!data.length) {
        return false;
      }
      const hasChanged = data.some((value) => store.hasChanges(value));
      return hasChanged;
    });
    if (itemsOptions.debug) {
      watch(
        _hasChanges,
        () => {
          logger.log("hasNew", newItems.value);
          logger.log("hasDeleted", deletedItems.value);
          const changed = [];
          currentDataAndBefore.value.forEach((value) => {
            const c = store.hasChanges(value, { explain: true });
            if (c) {
              changed.push(c, value);
            }
          });
          logger.log("hasChanges", changed);
        },
        {
          immediate: true
        }
      );
    }
    function _reset() {
      const items = /* @__PURE__ */ new Set([...newItems.value, ...currentDataAndBefore.value, ...deletedItems.value]);
      for (const item of items) {
        store.resetOrRemove(item);
      }
    }
    const { execute: _save, isLoading: isSaving } = useMutexAction(
      async (saveOptions) => {
        const saveNew = saveOptions?.saveNew ?? true;
        const saveChanged = saveOptions?.saveChanged ?? true;
        const saveRemoved = saveOptions?.saveRemoved ?? true;
        const itemsNew = saveNew ? currentDataAndBefore.value.filter((item) => !item.id) : [];
        const itemsChanged = saveChanged ? currentDataAndBefore.value.filter((item) => !!item.id && store.hasChanges(item)) : [];
        const itemsRemoved = saveRemoved ? [...deletedItems.value] : [];
        if (!itemsNew.length && !itemsChanged.length && !itemsRemoved.length) {
          logger.log("useFeathersItems save nothing");
          return;
        }
        logger.log("useFeathersItems save new", itemsNew);
        logger.log("useFeathersItems save changed", itemsChanged);
        logger.log("useFeathersItems save removed", itemsRemoved);
        if (itemsOptions.beforeSave) {
          [...itemsNew, ...itemsChanged].forEach((item) => {
            itemsOptions.beforeSave?.(item);
          });
        }
        await store.save([...itemsNew, ...itemsChanged, ...itemsRemoved], {
          ...saveOptions,
          deleteUnspecifiedItems: false
        });
      }
    );
    return {
      ...useFindResult,
      hasNew,
      hasDeleted,
      hasChanges: _hasChanges,
      reset: _reset,
      markForRemove: store.markForRemove,
      resetOrRemove: store.resetOrRemove,
      remove: store.remove,
      cancel: _reset,
      save: _save,
      isSaving: readonly(isSaving),
      params,
      fetchParams
    };
  };
};

const defineUseFindOnce = ({
  store,
  find,
  useFeathersItems
}) => {
  return function useFindOnce(findOnceOptions) {
    const logger = store.logger.makeLogger(findOnceOptions.debug, "useFindOnce");
    const requestedItems = findOnceOptions.requestedItems ?? ref([]);
    const haveLoadedItems = findOnceOptions.haveLoadedItems ?? ref([]);
    const {
      isPending,
      hasLoaded,
      reset: resetRequestOnce
    } = useRequestOnce({
      items: findOnceOptions.items,
      localOnly: findOnceOptions.localOnly,
      action: async (items, { abortController }) => {
        const isManipulating = store.promiseManipulating();
        if (isManipulating) {
          logger.log("is manipulating");
          await isManipulating;
          logger.log("is manipulating done");
        }
        const params = findOnceOptions.fetchParams(items);
        if (params) {
          logger.log("find", params);
          const result = await find(withDefaultParams(store.defaultParams?.find, params), {
            debug: findOnceOptions.debug,
            signal: abortController.signal
          });
          logger.log("find result", result, params);
        } else {
          logger.log("skip find", "params", params);
        }
      },
      debounce: findOnceOptions.debounce,
      requestedItems,
      haveLoadedItems,
      watchOnlyIf: findOnceOptions.watchOnlyIf
    });
    const { data, cancel, save, reset, hasChanges } = useFeathersItems({
      params: findOnceOptions.params,
      localOnly: true,
      debug: findOnceOptions.debug,
      limit: -1
    });
    return {
      data,
      cancel,
      reset,
      save,
      hasChanges,
      requestedItems,
      haveLoadedItems,
      isPending,
      hasLoaded,
      resetRequestOnce
    };
  };
};

const defineGetFromStore = ({
  store,
  scope
}) => {
  const getFromStore = (id) => computed(() => {
    if (!id) {
      return void 0;
    }
    const ref = store.getRefFor(id);
    if (ref?.entity) {
      scope.add(ref);
      return ref.entity;
    }
    store.debounced.get(id, { debounce: true }).then((result) => {
      if (!result.id) {
        return void 0;
      }
      return scope.construct(result);
    }).catch((err) => {
      store.logger.error(err);
      return void 0;
    });
    return void 0;
  });
  return getFromStore;
};

const defineFindInStore = ({
  store,
  scope,
  globals
}) => {
  return function findInStore(params) {
    const span = globals.sentrySpan({ name: `findInStore@${store.servicePath}` });
    if (params === null) {
      span.addEvent("params === null");
      span.end();
      return [];
    }
    params = withDefaultParams(store.defaultParams?.find, params);
    const { query, $sort, $limit, $skip } = filterQuery(params.query ?? {});
    const siftFunction = sift(query);
    let filteredItems = [];
    const map = /* @__PURE__ */ new Map();
    for (const item of store.items) {
      const entityRef = item.deref();
      if (!entityRef)
        continue;
      if (store.itemsToRemove.has(item))
        continue;
      if (!siftFunction(entityRef.entity))
        continue;
      filteredItems.push(entityRef.entity);
      map.set(entityRef.entity, entityRef);
    }
    if (!filteredItems.length) {
      span.addEvent("!filteredItems.length");
      span.end();
      return filteredItems;
    }
    span.addEvent("Sort");
    if ($sort) {
      filteredItems.sort(sorter($sort));
    }
    span.addEvent("Limit and Skip");
    filteredItems = applyLimitAndSkip(filteredItems, { $limit, $skip });
    span.addEvent("Add to scope");
    for (let i = 0, n = filteredItems.length; i < n; i++) {
      const entityRef = map.get(filteredItems[i]);
      if (entityRef) {
        scope.add(entityRef);
      }
    }
    span.end();
    return filteredItems;
  };
};

const defineUseGetMulti = ({
  store,
  findInStore
}) => {
  return function useGetMulti(getMultiOptions) {
    const logger = store.logger.makeLogger(getMultiOptions.debug, "useGetMulti");
    const ids = computed(() => toValue(getMultiOptions.ids));
    const idsInStore = store.localFind(ids.value.filter(isNonNullable)).map((item) => item.id).filter(isNonNullable);
    const requestedItems = ref(idsInStore);
    const data = computedCompareShallowArray(() => findInStore(toValue(getMultiOptions.params)));
    if (getMultiOptions.debug) {
      watch(data, (data2) => {
        logger.log("data", data2);
      });
    }
    const { hasLoaded, isPending } = useRequestOnce({
      items: ids,
      action: async (ids2) => {
        logger.log("fetching", ids2);
        const result = await Promise.all(
          ids2.map((id) => {
            if (!id) {
              return;
            }
            return store.debounced.enqueueGet(id).catch((error) => {
              console.error(error);
            });
          })
        );
        logger.log("fetched", result);
      },
      requestedItems
    });
    return { data, hasLoaded, isPending };
  };
};

function makeDefineFeathersPiniaStore(globalOptions) {
  const globals = defineGlobals(globalOptions);
  function defineFeathersPiniaStore(defineOptions) {
    const useBaseStore = defineStore(
      defineOptions.servicePath,
      () => {
        const store = defineStoreItems({
          globals,
          options: defineOptions
        });
        const useHasChanges = reactify(store.hasChanges);
        const sharedForStoreAndScope = {
          service: store.service,
          servicePath: defineOptions.servicePath,
          schema: markRaw(defineOptions.schema),
          schemaMeta: markRaw(store.schemaMeta),
          create: store.create,
          hasChanges: store.hasChanges,
          isSaving: store.isSaving,
          localFind: store.localFind,
          markForRemove: store.markForRemove,
          onCreated: store.onCreated,
          onPatched: store.onPatched,
          onRemoved: store.onRemoved,
          onUpdated: store.onUpdated,
          enqueueGet: store.debounced.enqueueGet,
          remove: store.remove,
          resetOrRemove: store.resetOrRemove,
          removeFromStore: store.removeFromStore,
          transformDates: store.transformDates,
          reset: store.reset,
          save: store.save,
          toServerId: store.toServerId,
          useHasChanges
        };
        function createScope() {
          const scope = defineScope({
            store
          });
          const getFromStore = defineGetFromStore({
            store,
            scope
          });
          const findInStore = defineFindInStore({
            store,
            scope,
            globals
          });
          const find = defineFind({
            store,
            scope,
            globals
          });
          const useGet = defineUseGet({
            store,
            scope
          });
          const useGetMulti = defineUseGetMulti({
            store,
            findInStore
          });
          const useFind = defineUseFind({
            globals,
            store,
            scope,
            find
          });
          const useCount = defineUseCount({
            globals,
            store,
            useFind
          });
          const useFeathersItems = defineUseFeathersItems({
            globals,
            store,
            useFind
          });
          const useFindOnce = defineUseFindOnce({
            store,
            find,
            useFeathersItems
          });
          const _save = (entity, opt) => {
            const newOpt = opt ?? {};
            newOpt._scope = scope.scope;
            return store.save(entity, newOpt);
          };
          return {
            ...sharedForStoreAndScope,
            scope: scope.scope,
            construct: scope.construct,
            useGet,
            useFind,
            useGetMulti,
            useCount,
            useFindOnce,
            useFeathersItems,
            find,
            getFromStore,
            findInStore,
            save: _save
          };
        }
        return {
          ...sharedForStoreAndScope,
          items: store.items,
          itemsToRemove: store.itemsToRemove,
          createScope,
          scopes: store.scopes,
          cleanStore: store.cleanStore,
          updateIdForUuid: store.updateIdForUuid,
          uuidToIdMapper: store.uuidToIdMapper,
          toServerId: store.toServerId,
          getRefFor: store.getRefFor,
          scopelessConstruct: store.scopelessConstruct
        };
      }
    );
    globals.storeRegister[defineOptions.servicePath] = useBaseStore;
    return useBaseStore;
  }
  return {
    ...globals,
    defineFeathersPiniaStore
  };
}

const useFeathersMToNItems = (options) => {
  const singleObjectSecondaryKey = `${options.singleObjectRelation}Id`;
  const singleObjectSecondaryUuid = `${options.singleObjectRelation}Uuid`;
  const multiObjectSecondaryKey = `${options.multiObjectRelation}Id`;
  const multiObjectSecondaryUuid = `${options.multiObjectRelation}Uuid`;
  const makeParams = (fetch) => {
    let paramsToUse;
    const params2 = unref(options.params);
    const fetchParams2 = unref(options.fetchParams);
    if (fetch && fetchParams2 != null) {
      paramsToUse = fetchParams2;
    } else {
      paramsToUse = params2;
    }
    if (paramsToUse) {
      paramsToUse = copy(paramsToUse);
    } else {
      return { query: {} };
    }
    return paramsToUse;
  };
  const params = computed(() => {
    const obj = toValue(options.singleObject);
    if (!obj) {
      return null;
    }
    const result = makeParams(false);
    result.query[singleObjectSecondaryUuid] = obj.uuid;
    return result;
  });
  const fetchParams = computed(() => {
    const obj = toValue(options.singleObject);
    if (!obj?.id) {
      return null;
    }
    const result = makeParams(false);
    result.query[singleObjectSecondaryKey] = obj.id;
    return result;
  });
  const {
    data: items,
    hasChanges,
    save,
    cancel,
    markForRemove: removeTemp,
    reset
  } = options.mnStore.useFeathersItems({
    params,
    fetchParams,
    limit: options.limit,
    localOnly: options.localOnly
  });
  const manyIdsFromItems = computed(() => {
    return [...new Set(items.value.map((x) => x[multiObjectSecondaryKey]))];
  });
  const manyItems = computed(() => {
    return options.multiStore.localFind(manyIdsFromItems.value);
  });
  function addItem(item) {
    const obj = toValue(options.singleObject);
    if (!obj) {
      return;
    }
    let newData = {
      ...options.data,
      ...obj.id ? { [singleObjectSecondaryKey]: obj.id } : { [singleObjectSecondaryUuid]: obj.uuid },
      ...item.id ? { [multiObjectSecondaryKey]: item.id } : { [multiObjectSecondaryUuid]: item.uuid }
    };
    if (options.beforeAdd) {
      newData = options.beforeAdd(newData, { mnItems: items.value });
    }
    options.mnStore.construct(newData);
  }
  return {
    mnItems: items,
    manyItems,
    removeTemp,
    hasChanges,
    save,
    cancel,
    reset,
    addItem
  };
};

function useFindIfNotInStore(options) {
  const ids = toRef(options.ids);
  const scope = getScopeFromFeathersPiniaStore(options.store);
  const fetchedIds = ref([]);
  watch(ids, (_ids) => {
    if (!_ids.length) {
      return;
    }
    const idsToFetch = [];
    _ids.forEach((id) => {
      const item = scope.localFind(id);
      if (!item && !fetchedIds.value.includes(id)) {
        idsToFetch.push(id);
      }
    });
    if (idsToFetch.length) {
      scope.find({
        query: {
          id: {
            $in: idsToFetch
          },
          $limit: idsToFetch.length
        }
      });
      fetchedIds.value = [...fetchedIds.value, ...idsToFetch];
    }
  });
  const items = computedCompareShallowArray(() => {
    const result = [];
    const items2 = scope.findInStore({
      query: {
        id: {
          $in: ids.value
        }
      }
    });
    ids.value.forEach((id) => {
      const item = items2.find((item2) => item2.id === id);
      if (item) {
        result.push(item);
      }
    });
    return result;
  });
  return {
    items
  };
}

function useIdArrayObjectMapper(ref, store) {
  const _store = getScopeFromFeathersPiniaStore(store);
  const params = computedDeepEqual(
    () => ref.value?.length ? {
      query: {
        "id": {
          $in: ref.value
        },
        $limit: 100
      }
    } : null
  );
  const { data: _data, isPending, hasLoaded } = _store.useGetMulti({
    ids: ref,
    params
  });
  const data = computed({
    get() {
      return ref.value?.length ? _data.value : [];
    },
    set(newValue) {
      ref.value = newValue ? newValue.map((value) => value.id).filter(isNonNullable) : [];
    }
  });
  return {
    data,
    isPending,
    hasLoaded
  };
}

function useIdObjectMapper(id, store) {
  const _store = getScopeFromFeathersPiniaStore(store);
  const { data: result } = _store.useGet({
    id,
    lazy: true
  });
  return computed({
    get() {
      if (!id.value)
        return id.value;
      return result.value;
    },
    set(newValue) {
      if (newValue) {
        id.value = newValue.id ? newValue.id : newValue.uuid;
      } else {
        id.value = newValue;
      }
    }
  });
}

function usePagination(options) {
  const explicitLimit = options.limit !== void 0 ? toRef(options.limit) : ref();
  const skip = options.skip !== void 0 ? toRef(options.skip) : ref(0);
  const limit = computed({
    get() {
      if (toValue(options.disablePagination)) {
        return -1;
      }
      if (explicitLimit.value != null) {
        return explicitLimit.value;
      }
      const fp = toValue(options.fetchParams);
      if (fp?.query?.$limit != null) {
        return fp.query.$limit;
      }
      const p = toValue(options.params);
      if (p?.query?.$limit != null) {
        return p.query.$limit;
      }
      return void 0;
    },
    set(val) {
      explicitLimit.value = val;
    }
  });
  const params = computed(() => {
    const p = toValue(options.params);
    if (!p) {
      return null;
    }
    return {
      ...p,
      query: {
        ...p.query ?? {},
        ...limit.value !== void 0 ? { $limit: limit.value } : {},
        $skip: skip.value
      }
    };
  });
  const fetchParams = computed(() => {
    const fp = toValue(options.fetchParams);
    if (fp === null) {
      return null;
    }
    const p = fp ?? toValue(options.params);
    if (!p) {
      return null;
    }
    return {
      ...p,
      query: {
        ...p.query ?? {},
        ...limit.value !== void 0 ? { $limit: limit.value } : {},
        $skip: skip.value
      }
    };
  });
  function applyPagination(total) {
    const validPagination = computed(() => {
      const valid = total.value != null && limit.value !== void 0 && limit.value > 0 && skip.value >= 0;
      return valid;
    });
    const pageCount = computed(() => {
      return validPagination.value ? Math.ceil((total.value ?? 0) / (limit.value ?? 1)) : 1;
    });
    function getPageNumber(num) {
      if (num < 0) {
        return pageCount.value + num + 1;
      }
      return num;
    }
    const currentPage = computed({
      get() {
        return validPagination.value ? pageCount.value === 0 ? 0 : skip.value / (limit.value ?? 1) + 1 : 1;
      },
      set(pageNumber) {
        if (!validPagination.value) {
          skip.value = 0;
          return;
        }
        if (pageCount.value <= 1) {
          return;
        }
        pageNumber = getPageNumber(pageNumber);
        if (pageNumber < 0) {
          pageNumber = pageCount.value + pageNumber + 1;
        }
        if (pageNumber < 1) {
          pageNumber = 1;
        } else if (pageNumber > pageCount.value) {
          pageNumber = pageCount.value;
        }
        const $skip = (limit.value ?? 0) * (pageNumber - 1);
        if ($skip === skip.value) {
          return;
        }
        skip.value = $skip;
      }
    });
    watch(
      () => pageCount.value,
      () => {
        if (currentPage.value > pageCount.value) {
          currentPage.value = pageCount.value;
        }
      }
    );
    const canPrev = computed(() => {
      return currentPage.value - 1 > 0;
    });
    const canNext = computed(() => {
      return currentPage.value < pageCount.value;
    });
    function toStart() {
      currentPage.value = 1;
    }
    function toEnd() {
      currentPage.value = pageCount.value;
    }
    function toPage(pageNumber) {
      pageNumber = getPageNumber(pageNumber);
      if (currentPage.value === pageNumber) {
        return false;
      }
      currentPage.value = pageNumber;
      return currentPage.value === pageNumber;
    }
    function canGoToPage(pageNumber) {
      return pageNumber >= 1 && pageNumber <= pageCount.value;
    }
    function next() {
      currentPage.value++;
    }
    function prev() {
      currentPage.value--;
    }
    return {
      pageCount,
      currentPage,
      canPrev,
      canNext,
      toStart,
      toEnd,
      toPage,
      canGoToPage,
      next,
      prev,
      limit,
      skip
    };
  }
  return {
    limit,
    skip,
    params,
    fetchParams,
    applyPagination
  };
}

const addLiteralPercentage = (str, append = true, prepend = false) => `${prepend ? "%" : ""}${str}${append ? "%" : ""}`;
const makeILike = (str, append = false, prepend = false) => ({
  $iLike: addLiteralPercentage(str, append, prepend)
});
const makeLike = (str, append = false, prepend = false) => ({
  $like: addLiteralPercentage(str, append, prepend)
});

const useParamsWithFilter = (options) => {
  const { params: _params, fetchParams: _fetchParams } = options;
  const queryForSearchField = computed(() => {
    if (!options.search) {
      return {};
    }
    const searchValue = toValue(options.search.value);
    const searchField = toValue(options.search.field);
    if (!searchValue || !searchField) {
      return {};
    }
    const prepend = toValue(options.search.prepend);
    const append = toValue(options.search.append);
    const ignoreCase = toValue(options.search.ignoreCase);
    return {
      [searchField]: ignoreCase ? makeLike(searchValue, append, prepend) : makeILike(searchValue, append, prepend)
    };
  });
  const params = computed(() => {
    const params2 = toValue(_params);
    if (!params2) {
      return params2;
    }
    const result = {
      debounce: 350,
      ...params2,
      query: {
        ...params2?.query,
        ...queryForSearchField.value,
        ...params2.query?.$limit !== void 0 || options.limit === void 0 ? { $limit: params2.query?.$limit ?? toValue(options.limit) } : {}
      }
    };
    return result;
  });
  const fetchParams = computed(() => {
    const params2 = toValue(_fetchParams) !== void 0 ? toValue(_fetchParams) : toValue(_params);
    if (!params2) {
      return null;
    }
    if (!toValue(options.search?.useSearchOnServer)) {
      return params2 ?? null;
    }
    const result = {
      debounce: 350,
      ...params2,
      query: {
        ...params2?.query,
        ...queryForSearchField.value,
        ...params2.query?.$limit !== void 0 || options.limit === void 0 ? { $limit: params2.query?.$limit ?? toValue(options.limit) } : {}
      }
    };
    return result;
  });
  return {
    params,
    fetchParams
  };
};

const makeTransformDatesHook = (transformDates) => {
  return alterItems(transformDates);
};

export { addLiteralPercentage, getScopeFromFeathersPiniaStore, makeDefineFeathersPiniaStore, makeILike, makeLike, makeTransformDatesHook, useFeathersMToNItems, useFindIfNotInStore, useIdArrayObjectMapper, useIdObjectMapper, usePagination, useParamsWithFilter };
