import VueGoogleMaps from "@fawmi/vue-google-maps";

export default {
  install: (app: App) => {
    app.use(VueGoogleMaps, {
      load: {
        key: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
        libraries: "places",
      },
      installComponents: false,
    });

    return app;
  },
};
