import { Nullable, defineSchema } from "@artesa/model-compiler";
import { Type, StringEnum } from "../../typebox/index.js";
import { dateAnchorValues } from "./assignment-event-types.types.js";
export const AssignmentEventType = defineSchema(
  "assignment-event-types",
  {
    name: Type.String(),
    color: Type.Optional(
      Nullable(Type.RegExp(/^#[0-9a-fA-F]{8}$|#[0-9a-fA-F]{6}$|#[0-9a-fA-F]{4}$|#[0-9a-fA-F]{3}$/))
    ),
    icon: Type.Optional(Nullable(Type.String())),
    shortName: Type.Optional(Nullable(Type.String())),
    order: Type.Optional(Type.Integer({ minimum: 1, default: 1 })),
    manPowerOfferPerDay: Type.Optional(Type.Integer({ minimum: 1, default: 1 })),
    defaultEmployeeCount: Type.Optional(Type.Integer({ minimum: 1, default: 1 })),
    defaultIsOutward: Type.Optional(Type.Boolean()),
    defaultDateAnchor: Type.Optional(StringEnum(dateAnchorValues, { default: "startsAt" })),
    defaultManPower: Type.Optional(Type.Integer({ minimum: 1, default: 1 })),
    defaultDurationDrive: Type.Optional(Type.Integer({ minimum: 1, default: 1 })),
    defaultIsAllDay: Type.Optional(Type.Boolean()),
    defaultStartsAtTime: Type.Optional(Nullable(Type.String({ default: "06:00:00" }))),
    // TODO: regex for time
    defaultMinUploadCount: Type.Optional(Type.Integer({ minimum: 1, default: 1 })),
    defaultWorkDays: Type.Optional(Type.Number({ default: 1 })),
    defaultWorkingTimePerDay: Type.Optional(Type.Number({ default: 60 * 60 * 8 })),
    hideInfoInDoing: Type.Optional(Type.Boolean({ default: false })),
    deactivatedAt: Type.Optional(Nullable(Type.Date({ default: null }))),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  {
    relations: (r) => ({
      company: r.belongsTo("companies"),
      defaultGroupType: r.belongsTo("assignment-event-group-types", { nullable: true })
    })
  }
);
