import "@artesa/model-compiler";
import {
  Type,
  isSchemaObject,
  isSchemaUnion,
  isSchemaIntersect
} from "@artesa/model-compiler";
export {
  Type,
  Kind,
  Hint,
  TypeGuard,
  Readonly,
  Optional,
  ReadonlyKind,
  Value,
  StringEnum,
  NumberEnum,
  TypeCompiler,
  FrontendOnlyKind,
  OnlyNotCreateKind,
  Nullable,
  defineSchema,
  isSchemaFrontendOnly,
  isSchemaIntersect,
  isSchemaNullable,
  isSchemaObject,
  isSchemaReadonly,
  isSchemaUnion,
  isSchemaVirtual
} from "@artesa/model-compiler";
export * from "./relations.js";
export const DateSchema = (options) => Type.Unsafe({ ...options, type: "object", instanceof: "Date", format: "date-time" });
export const ObjectIdSchema = () => Type.Union([Type.String({ objectid: true }), Type.Object({}, { additionalProperties: false })]);
export const Arrayable = (schema, options) => Type.Union([schema, Type.Array(schema, options?.arrayOptions)], options?.unionOptions);
export const FullOptional = (schema) => Type.Optional(Type.Union([schema, Type.Null()]));
export const TypeObjectExplicit = (properties, options) => Type.Object(properties, { ...options, additionalProperties: false });
export function schemaHasProperty(schema, property) {
  if (isSchemaObject(schema)) {
    return property in schema.properties;
  } else if (isSchemaUnion(schema)) {
    return schema.anyOf.some((s) => schemaHasProperty(s, property));
  } else if (isSchemaIntersect(schema)) {
    return schema.allOf.some((s) => schemaHasProperty(s, property));
  }
  return false;
}
