import { AssignmentCustomAttributeDefinition } from '@artesa/shared';
import { defineFeathersPiniaStore } from "../../base-store";

export const useAssignmentCustomAttributeDefinitionsStore = defineFeathersPiniaStore({
  schema: AssignmentCustomAttributeDefinition,
  servicePath: 'assignment-custom-attribute-definitions',
  setupHook({ service, transformDates }) {
    const transformDatesHook = makeTransformDatesHook(transformDates);
    service.hooks({
      after: {
        find: [transformDatesHook],
        get: [transformDatesHook],
        create: [transformDatesHook],
        update: [transformDatesHook],
        patch: [transformDatesHook],
        remove: [transformDatesHook],
      },
    });
  },
});

export default useAssignmentCustomAttributeDefinitionsStore;