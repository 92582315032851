import { abilitiesPlugin } from "@casl/vue";
import { Ability, createAliasResolver } from "@casl/ability";
import { caslAliasResolvers, caslAnySubjectType } from "@artesa/shared";
import { servicePathForObject } from "@/store/feathers/base-store";

const detectSubjectType = (subject: any) => {
  if (typeof subject === "string") {
    return subject;
  }
  if (isReactive(subject)) {
    const sp = servicePathForObject(subject);
    if (sp) {
      return sp;
    }
  }

  return "";
};

const resolveAction = createAliasResolver(caslAliasResolvers);

export const ability = new Ability([], {
  detectSubjectType,
  resolveAction,
  anySubjectType: caslAnySubjectType,
});

export function unset() {
  ability.update([]);
}

export function setRules(rules: any[]) {
  ability.update(rules);
}

export default {
  install: (app: App) => {
    app.use(abilitiesPlugin, ability, {
      useGlobalProperties: true,
    });

    return app;
  },
};

export function canViewRoute(route: RouteRecordNormalized | RouteLocationNormalized): boolean {
  if (!route.meta?.permission) {
    return true;
  }
  const name = route.meta.permission === true ? route.name : route.meta.permission;
  if (!name) {
    return true;
  }

  return ability.can("view", name as string);
}
