import { Nullable, Type } from "@artesa/model-compiler";
export const customerImportObject = Type.Object({
  number: Type.Optional(Nullable(Type.String())),
  companyName: Type.Optional(Nullable(Type.String())),
  /**
   * The title of the customer.
   * @example 'Dr.' | 'Prof.'
   */
  title: Type.Optional(Nullable(Type.String())),
  /**
   * The salutation of the customer.
   * @example 'Herr' | 'Frau'
   */
  salutation: Type.Optional(Nullable(Type.String())),
  fullName: Type.Optional(Nullable(Type.String())),
  telephone: Type.Optional(Nullable(Type.String())),
  mobile: Type.Optional(Nullable(Type.String())),
  fax: Type.Optional(Nullable(Type.String())),
  email: Type.Optional(Nullable(Type.String())),
  url: Type.Optional(Nullable(Type.String())),
  foreignReference: Type.Optional(Nullable(Type.String())),
  address: Type.Optional(
    Type.Object({
      /**
       * @example "z.Hd. Frau Mustermann"
       */
      supplement: Type.Optional(Nullable(Type.String())),
      street: Type.Optional(Nullable(Type.String())),
      houseNumber: Type.Optional(Nullable(Type.String())),
      /**
       * @example "Hinterhaus" or "Aufgang links" or "3. Etage"
       */
      clarification: Type.Optional(Nullable(Type.String())),
      zip: Type.Optional(Nullable(Type.String())),
      city: Type.Optional(Nullable(Type.String())),
      country: Type.Optional(Nullable(Type.String()))
    })
  )
});
export const customerImportType = Type.Object({
  items: Type.Array(customerImportObject, { maxItems: 20 }),
  importReference: Type.Optional(Type.String()),
  mode: Type.Optional(
    Type.Union([
      Type.Literal("DO_NOTHING"),
      Type.Object({
        type: Type.Union([Type.Literal("MERGE"), Type.Literal("OVERWRITE")]),
        identifier: Type.Union([Type.Literal("number"), Type.Literal("companyName")])
      })
    ])
  )
});
