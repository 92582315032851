/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { h, Fragment } from "vue";
import { ElNotification, ElButton } from "element-plus";
import { app } from "./feathers/feathers.client";
import { useUserSettingStore } from "./feathers/services/user-settings/user-settings.pinia";
import serviceWorkerPath from "./web-push/web-push-service-worker?worker&url";
import { askForNotificationPermission } from "./web-push/utils/ask-for-permissions";
import { useGeneralStore } from "./general.store";
import _debounce from "lodash/debounce";
import { Sentry } from "@/main.plugins/main.sentry";

export const usePushNotificationStore = defineStore("push-notifications", () => {
  const userSettingsStore = useUserSettingStore().createScope();
  const generalStore = useGeneralStore();

  const isPushFFActive = computed(() => !generalStore.skipServiceWorker);

  const user = useUser();

  const { data: userSetting } = userSettingsStore.useGet({
    id: computed(() => (isPushFFActive.value ? user.value?.id : null)),
    lazy: true,
  });

  const hasPushEnabled = computed<boolean>(() => {
    if (!isPushFFActive.value) {
      return false;
    }
    if (!userSetting.value?.notifications) {
      return false;
    }
    const notificationSettings = userSetting.value.notifications;
    for (const Key in notificationSettings) {
      const setting = notificationSettings[Key];
      if (!setting) {
        continue;
      }
      if (setting.push) {
        return true;
      }
    }
    return false;
  });

  const serviceWorkerRegistration = shallowRef<ServiceWorkerRegistration | null>(null);
  const serviceWorkerIsRegistered = computed(() => serviceWorkerRegistration.value !== null);

  const shouldEnablePush = computed<boolean>(
    () => hasPushEnabled.value && serviceWorkerIsRegistered.value,
  );

  // MARK: Service worker registration

  if ("serviceWorker" in navigator && "PushManager" in window && isPushFFActive.value) {
    navigator.serviceWorker
      .register(serviceWorkerPath, { type: "module" })
      .then(registration => {
        serviceWorkerRegistration.value = registration;
      })
      .catch(async error => {
        Sentry.captureException(error);
        return Promise.resolve();
      });
  }

  async function updateServiceWorker(): Promise<void> {
    if (serviceWorkerRegistration.value) {
      await serviceWorkerRegistration.value.unregister();
      serviceWorkerRegistration.value = null;
    }
  }

  // MARK: get applicationServerKey

  const applicationServerKey = shallowRef<null | string>(null);

  whenever(shouldEnablePush, () => {
    app
      .service("notifications")
      .getApplicationServerKey()
      .then(value => {
        applicationServerKey.value = value.applicationServerKey;
      });
  });

  // MARK: User permissions

  const initialNotificationPermission = (): NotificationPermission => {
    try {
      return Notification.permission;
      // eslint-disable-next-line unused-imports/no-unused-vars
    } catch (error) {
      return "denied"; // Browser probably doesn't support notification feature
    }
  };

  const hasPushNotificationPermissions = shallowRef<NotificationPermission>(
    initialNotificationPermission(),
  );

  const shouldAcquirePermissions = computed(
    () =>
      shouldEnablePush.value &&
      !!applicationServerKey.value &&
      hasPushNotificationPermissions.value === "default",
  );

  const pushPermissionsDenied = computed(() => hasPushNotificationPermissions.value === "denied");

  const { t } = useI18n();

  function showPermissionBox() {
    whenever(shouldAcquirePermissions, () => {
      const notification = ElNotification.warning({
        title: t("notification.permissionNotification.title"),
        message: h(Fragment, [
          h("p", {}, t("notification.permissionNotification.description")),
          h(
            ElButton,
            {
              onClick: () => {
                notification.close();
                askForNotificationPermission()
                  .then(() => {
                    hasPushNotificationPermissions.value = "granted";
                  })
                  .catch(error => {
                    hasPushNotificationPermissions.value = "denied";
                    ElNotification.warning({
                      title: t("notification.permissionNotification.title"),
                      message: t("notification.permissionNotification.deniedDescription"),
                    });
                  });
              },
            },
            t("notification.permissionNotification.givePermissions"),
          ),
        ]),
        duration: 0,
      });
    });
  }

  // MARK: create subscription

  const pushSub = shallowRef<PushSubscription | null>(null);

  const shouldCreateSubscription = computed(
    () =>
      hasPushNotificationPermissions.value === "granted" &&
      serviceWorkerIsRegistered.value &&
      !!applicationServerKey.value &&
      hasPushEnabled.value,
  );

  whenever(shouldCreateSubscription, async () => {
    try {
      let subscription = await serviceWorkerRegistration.value!.pushManager.getSubscription();

      if (!subscription) {
        subscription = await serviceWorkerRegistration.value!.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(applicationServerKey.value!),
        });
      }

      if (subscription) {
        pushSub.value = subscription;
        const pushAlreadySave = userSetting.value?.webPush?.find(
          value => value.endpoint === subscription.endpoint,
        );
        if (!pushAlreadySave && userSetting.value) {
          userSetting.value.webPush ||= [];
          userSetting.value.webPush.push(subscription.toJSON() as any /* Our types are correct */);
          save();
        }
      }
    } catch (error) {
      console.error(error);
    }
  });

  // MARK: save

  const save = _debounce(() => {
    if (userSetting.value) {
      userSettingsStore.save(userSetting.value);
    }
  }, 250);

  // MARK: unsub

  const shouldDisableSubscription = computed(
    () => userSetting.value && !hasPushEnabled.value && pushSub.value,
  );

  whenever(shouldDisableSubscription, () => {
    disableSubscription(false);
  });

  async function disableSubscription(_save = true) {
    if (!userSetting.value?.webPush) {
      return;
    }
    const endpoint = pushSub.value?.toJSON().endpoint;
    pushSub.value?.unsubscribe();
    pushSub.value = null;
    const indexOfSub = userSetting.value.webPush.findIndex(value => value.endpoint === endpoint);
    if (indexOfSub >= 0) {
      userSetting.value!.webPush.splice(indexOfSub, 1);
    }
    if (userSetting.value && _save) save();
  }

  return {
    serviceWorkerIsRegistered,
    serviceWorkerRegistration,
    hasPushNotificationPermissions: computed(
      () => hasPushNotificationPermissions.value === "granted",
    ),
    shouldEnablePush,
    pushPermissionsDenied,
    applicationServerKey,
    shouldAcquirePermissions,
    updateServiceWorker,
    showPermissionBox,
    disableSubscription,
  };
});

function urlBase64ToUint8Array(base64String: string) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
