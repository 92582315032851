import { defineSchema } from "@artesa/model-compiler";
import { weekdayNumberValues } from "../../utils.js";
import { Type, Nullable, NumberEnum } from "../../typebox/index.js";
export const WorkingTimeModelWeekday = defineSchema(
  "working-time-model-weekdays",
  {
    weekday: NumberEnum(weekdayNumberValues),
    start: Type.Optional(Nullable(Type.Integer({ minimum: 0, maximum: 24 * 60 * 60 - 1 }))),
    end: Type.Optional(Nullable(Type.Integer({ minimum: 0, maximum: 24 * 60 * 60 - 1 }))),
    pause: Type.Optional(Nullable(Type.Integer({ minimum: 0, maximum: 24 * 60 * 60 - 1 }))),
    duration: Type.Optional(
      Nullable(Type.Integer({ minimum: 0, maximum: 24 * 60 * 60 - 1, default: 0 }))
    ),
    isActive: Type.Optional(Type.Boolean({ default: false })),
    startMin: Type.Optional(
      Nullable(Type.Integer({ minimum: 0, maximum: 24 * 60 * 60 - 1, default: null }))
    ),
    startMax: Type.Optional(
      Nullable(Type.Integer({ minimum: 0, maximum: 24 * 60 * 60 - 1, default: null }))
    ),
    endMin: Type.Optional(
      Nullable(Type.Integer({ minimum: 0, maximum: 24 * 60 * 60 - 1, default: null }))
    ),
    endMax: Type.Optional(
      Nullable(Type.Integer({ minimum: 0, maximum: 24 * 60 * 60 - 1, default: null }))
    ),
    durationMax: Type.Optional(
      Nullable(Type.Integer({ minimum: 0, maximum: 24 * 60 * 60 - 1, default: null }))
    ),
    durationMaxShouldCorrect: Type.Optional(Type.Boolean({ default: false })),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  {
    relations: (r) => ({
      company: r.belongsTo("companies"),
      workingTimeModel: r.belongsTo("working-time-models")
    })
  }
);
