import type { TimeSheetMonthBase } from "@artesa/shared";
import { TimeSheetMonth } from "@artesa/shared";
import { defineFeathersPiniaStore } from "../../base-store";
import { makeTransformDatesHook } from "../../hooks/transformDates.hook";
import type { Service } from "@feathersjs/feathers";

export const servicePath = "time-sheet-months" as const;

declare module "../../feathers.client" {
  interface Services {
    "time-sheet-months": Service<TimeSheetMonthBase>;
  }
}

export const useTimeSheetMonthStore = defineFeathersPiniaStore({
  schema: TimeSheetMonth,
  servicePath,
  setupHook({ service, transformDates }) {
    const transformDatesHook = makeTransformDatesHook(transformDates);
    service.hooks({
      after: {
        find: [transformDatesHook],
        get: [transformDatesHook],
        create: [transformDatesHook],
        update: [transformDatesHook],
        patch: [transformDatesHook],
        remove: [transformDatesHook],
      },
    });
  },
});

export default useTimeSheetMonthStore;
