const smartReferenceSymbol: unique symbol = Symbol("SmartReferenceSymbol");

export type SmartReferenceArray<T, Shallow = false> = Shallow extends true
  ? T[] & { [smartReferenceSymbol]: Map<T, symbol[]> }
  : UnwrapNestedRefs<T[]> & { [smartReferenceSymbol]: Map<T, symbol[]> };

/**
 * To use with useSmartReference()
 * @returns A modified array to use with useSmartReference()
 */

export function useSmartReferenceArray<T>(shallow: true): SmartReferenceArray<T, true>;
export function useSmartReferenceArray<T>(shallow: false): SmartReferenceArray<T, false>;
export function useSmartReferenceArray<T>(): SmartReferenceArray<T, false>;
export function useSmartReferenceArray<T>(
  shallow = false,
): SmartReferenceArray<T> | SmartReferenceArray<T, true> {
  const smartReferenceArray = shallow ? shallowReactive([]) : reactive([]);

  const referenceMap = new Map<T, symbol[]>();

  Object.defineProperty(smartReferenceArray, smartReferenceSymbol, {
    get() {
      return referenceMap;
    },
  });

  return smartReferenceArray as unknown as SmartReferenceArray<T>;
}

/**
 * Items added via addTrackedItem() to the array automatically get removed, if the current scope gets disposed or if onScopeDispose gets manually called
 * @param smartArray A array returned by useSmartReferenceArray
 * @returns
 */
export function useSmartReference<T>(smartArray: SmartReferenceArray<T>) {
  const scopeId = Symbol();

  const referenceMap = smartArray[smartReferenceSymbol];

  /**
   * Manually remove all items tracked by the current scope
   */
  function onScopeDispose() {
    for (const [targetItem, references] of referenceMap.entries()) {
      if (references.includes(scopeId)) {
        const index = references.findIndex(value => value === scopeId);
        references.splice(index, 1);
      }

      if (references.length < 1) {
        const index = smartArray.findIndex(value => value === targetItem);
        smartArray.splice(index, 1);
      }

      referenceMap.delete(targetItem);
    }
  }

  tryOnScopeDispose(onScopeDispose);

  return {
    /**
     * Add an item to the smartArray
     * @param toAdd Object to add to array if it does not exist
     * @param findFunction A function passed to find the item in the smartArray
     * @returns
     */
    addTrackedItem(toAdd: T, findFunction?: (value: T) => boolean) {
      let item = (
        findFunction
          ? smartArray.find(findFunction as any)
          : smartArray.find(value => value === toAdd)
      ) as T | undefined;

      if (!item) {
        item = toAdd;
        smartArray.push(item as any);
      }

      if (referenceMap.has(item)) {
        const references = referenceMap.get(item);

        if (!references?.includes(scopeId)) {
          references?.push(scopeId);
        }
      } else {
        referenceMap.set(item, [scopeId]);
      }

      return item;
    },
    onScopeDispose,
  };
}
