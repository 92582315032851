import { defineStore } from "pinia";
import { useIDBKeyval } from "@vueuse/integrations/useIDBKeyval";
import { useAuthStore } from "@/store/auth.store";
import { parseJwt } from "@/utils/jwt";
import { Sentry } from "@/main.plugins/main.sentry";
import type { CompanyFrontend } from "@artesa/shared";

export const useImpersonationStore = defineStore("impersonation", () => {
  const authStore = useAuthStore();

  const superAdminTokenStore = useIDBKeyval<null | string>("superAdminJwt", null);

  const superAdminToken = computed({
    get() {
      return superAdminTokenStore.data.value;
    },
    set(value) {
      superAdminTokenStore.data.value = value;
    },
  });

  const canImpersonate = computed(() => !!superAdminToken.value);

  const isDialogOpen = ref(false);

  whenever(
    () => !canImpersonate.value,
    () => {
      isDialogOpen.value = false;
    },
  );

  // Save SuperAdmin token
  watch(
    [
      () => authStore.user?.role?.type,
      () => authStore.accessToken,
      () => authStore.isAuthenticated,
    ],
    () => {
      if (
        authStore.accessToken &&
        authStore.isAuthenticated &&
        authStore.user?.role?.type === "superadmin"
      ) {
        superAdminToken.value = authStore.accessToken;
      }
    },
    { immediate: true },
  );

  const impersonatedUser = computed(() => {
    if (!superAdminToken.value) {
      return null;
    }

    const payload = parseJwt(superAdminToken.value);

    if (!payload) {
      return null;
    }

    const { sub } = payload;

    const { user } = authStore;

    return user && `${sub}` !== `${user.id}` ? user : null;
  });

  const impersonatedCompany = computed<CompanyFrontend | null>(() => {
    return impersonatedUser.value?.company ?? null;
  });

  const isImpersonating = computed(() => {
    return !!impersonatedUser.value;
  });

  function impersonate(userId?: string) {
    if (!superAdminToken.value) {
      return Promise.reject("No super admin token");
    }
    return authStore.impersonate(superAdminToken.value, userId);
  }

  function reset() {
    if (!canImpersonate.value || !isImpersonating.value) {
      return;
    }

    return impersonate();
  }

  const impersonationContext = computed(() => ({
    isImpersonating: isImpersonating.value,
    impersonatedCompany: impersonatedCompany.value,
    impersonatedUser: impersonatedUser.value,
    canImpersonate: canImpersonate.value,
  }));

  watch(
    impersonationContext,
    impersonationContext => {
      if (!impersonationContext.isImpersonating || !impersonationContext.canImpersonate) {
        Sentry.setContext("impersonation", null);
      } else {
        const { impersonatedCompany, impersonatedUser, ...context } = impersonationContext;
        Sentry.setContext("impersonation", {
          ...context,
          impersonatedCompany: impersonatedCompany?.id,
          impersonatedUser: impersonatedUser?.id,
        });
      }
    },
    { immediate: true },
  );

  return {
    impersonatedUser,
    impersonatedCompany,
    isImpersonating,
    superAdminToken,
    impersonate,
    reset,
    canImpersonate,
    isDialogOpen,
  };
});
