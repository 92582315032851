import type { NavigationGuardNext, RouteLocationRaw, NavigationGuard, Router } from "vue-router";

/** Define provider signature **/
export type MultiGuard = (guards: NavigationGuard[]) => NavigationGuard;

/**
 * Provide routes with the ability to execute multiple guards.
 * This is implemented due to a gap in Vue Router functionality which limits callbacks to a single guard.
 *
 * @see https://github.com/vuejs/vue-router/issues/2688
 * @param {NavigationGuard[]} guards
 * @return {NavigationGuard}
 */
export const multiGuard: MultiGuard =
  (guards: NavigationGuard[]): NavigationGuard =>
  async (to, from, next) => {
    // Let sharedNext callback break execution if a guard is triggered.
    let resolved = false;

    // Create a "shared" next callback which breaks the iteration loop if "next()" is called within guard.
    // TODO: Implement "boolean" and "callback" cases...
    const sharedNext = (location?: RouteLocationRaw): void => {
      if (location) {
        resolved = true;
        next(location);
      }
    };

    // Iterate through provided guards array until complete or redirected.
    for (const guard of guards) {
      const returnValue = guard(to, from, sharedNext as NavigationGuardNext);
      if (returnValue instanceof Promise) {
        await returnValue;
      }
      // Check if the sharedNext callback triggered a break.
      if (resolved) {
        break;
      }
    }
    // If none of the guards triggered a redirect, allow the original intent.
    if (!resolved) {
      return next();
    }
  };

export const beforeEachMulti = (router: Router, guards: NavigationGuard[]) => {
  return router.beforeEach(multiGuard(guards));
};

export const beforeResolveMulti = (router: Router, guards: NavigationGuard[]) => {
  return router.beforeResolve(multiGuard(guards));
};
