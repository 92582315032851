import { createSharedComposable, useBreakpoints as _useBreakpoints } from "@vueuse/core";
import { breakpoints as tailwindBreakpoints } from "@/../tailwind.config";

/**
 * Reactive breakpoints object.
 * Works differently than vueuse. More like tailwind.
 *
 * @example
 * ```ts
 * const breakpoint = useBreakpoints(); // do not destructure!
 *
 * // use in template or in computed or function
 * // <div v-if="breakpoint.md">...</div>
 * // 👆 shows if screen is at least md
 *
 * // <div v-if="breakpoint.maxLg">...</div>
 * // 👆 shows if screen is at most lg
 * ```
 */
export const useBreakpoints = createSharedComposable(() => {
  const breakpoints = _useBreakpoints(tailwindBreakpoints);

  const result = {
    xs: breakpoints.greaterOrEqual("xs"),
    sm: breakpoints.greaterOrEqual("sm"),
    md: breakpoints.greaterOrEqual("md"),
    lg: breakpoints.greaterOrEqual("lg"),
    xl: breakpoints.greaterOrEqual("xl"),
    "2xl": breakpoints.greaterOrEqual("2xl"),
    between: breakpoints.between,
    maxXs: breakpoints.smallerOrEqual("xs"),
    maxSm: breakpoints.smallerOrEqual("sm"),
    maxMd: breakpoints.smallerOrEqual("md"),
    maxLg: breakpoints.smallerOrEqual("lg"),
    maxXl: breakpoints.smallerOrEqual("xl"),
    max2xl: breakpoints.smallerOrEqual("2xl"),
  };

  const mq = computed(() => {
    if (result["2xl"].value) {
      return "2xl";
    } else if (result.xl.value) {
      return "xl";
    } else if (result.lg.value) {
      return "lg";
    } else if (result.md.value) {
      return "md";
    } else if (result.sm.value) {
      return "sm";
    }

    return "xs";
  });

  return reactive({
    ...result,
    mq,
  });
});
