import { defineSchema } from "@artesa/model-compiler";
import { Type } from "../../typebox/index.js";
export const UploadCategoryRole = defineSchema(
  "upload-category-roles",
  {
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  {
    relations: (r) => ({
      company: r.belongsTo("companies"),
      role: r.belongsTo("roles"),
      category: r.belongsTo("upload-categories")
    })
  }
);
