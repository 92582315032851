import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable } from "../../typebox/index.js";
export const Customer = defineSchema(
  "customers",
  {
    number: Type.Optional(Nullable(Type.String())),
    companyName: Type.Optional(Nullable(Type.String())),
    /**
     * The title of the customer.
     * @example 'Dr.' | 'Prof.'
     */
    title: Type.Optional(Nullable(Type.String())),
    /**
     * The salutation of the customer.
     * @example 'Herr' | 'Frau'
     */
    salutation: Type.Optional(Nullable(Type.String())),
    fullName: Type.Optional(Nullable(Type.String())),
    telephone: Type.Optional(Nullable(Type.String())),
    mobile: Type.Optional(Nullable(Type.String())),
    fax: Type.Optional(Nullable(Type.String())),
    email: Type.Optional(Nullable(Type.String())),
    url: Type.Optional(Nullable(Type.String())),
    foreignReference: Type.Optional(Nullable(Type.String())),
    importReference: Type.Optional(Nullable(Type.String())),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date()),
    deletedAt: Type.Optional(Nullable(Type.Date()))
  },
  {
    relations: (r) => ({
      address: r.belongsTo("addresses", { nullable: true }),
      company: r.belongsTo("companies"),
      createdBy: r.belongsTo("users", { nullable: true })
    }),
    query: {
      additionalProperties: {
        "$address.street$": { type: "string", profile: "server" },
        "$address.zip$": { type: "string", profile: "server" },
        "$address.city$": { type: "string", profile: "server" }
      }
    }
  }
);
