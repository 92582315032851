import type { FeathersPiniaStoreDefinition } from "@artesa/feathers-pinia";
import type { ModelRegisterFrontend } from "@artesa/shared";
import type { Pinia } from "pinia";

const map = import.meta.glob<{
  default: FeathersPiniaStoreDefinition<ModelRegisterFrontend>;
}>("./**/*.pinia.ts", {
  // import: "default",
  eager: true,
});

export function initializeStores(pinia: Pinia) {
  for (const key in map) {
    const store = map[key];

    // Don't just silent fail, if you forget the default export
    if (!store.default) {
      console.warn("Missing default export for store ", key);
    }

    store.default?.(pinia);
  }
}
