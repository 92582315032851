export default {
  install: (app: App) => {
    // bind console.log - keeps the context
    app.config.globalProperties.$debug = console.log.bind(window.console);

    app.config.warnHandler = (msg, instance, trace) =>
      !["Extraneous non-props attributes (row)"].some(warning => msg.includes(warning)) &&
      console.warn("[Vue warn]: ".concat(msg).concat(trace));

    return app;
  },
};

declare module "vue" {
  interface ComponentCustomProperties {
    $debug: (...args: any[]) => void;
  }
}
