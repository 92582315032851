import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable } from "../../typebox/index.js";
const workingTimeModelPauseRule = Type.Object({
  durationMin: Nullable(
    Type.Optional(Type.Integer({ minimum: 0, maximum: 23 * 60 * 60 + 59 * 60 + 59 }))
  ),
  breakTimeDefault: Nullable(
    Type.Optional(Type.Integer({ minimum: 0, maximum: 23 * 60 * 60 + 59 * 60 + 59 }))
  ),
  breakTimeMin: Nullable(
    Type.Optional(Type.Integer({ minimum: 0, maximum: 23 * 60 * 60 + 59 * 60 + 59 }))
  ),
  breakTimeMax: Nullable(
    Type.Optional(Type.Integer({ minimum: 0, maximum: 23 * 60 * 60 + 59 * 60 + 59 }))
  )
});
export const WorkingTimeModel = defineSchema(
  "working-time-models",
  {
    name: Type.String({ default: "" }),
    order: Type.Number({ default: 1 }),
    isDefault: Type.Boolean({ default: false }),
    pauseRules: Type.Optional(Nullable(Type.Array(workingTimeModelPauseRule, { default: [] }))),
    carryOverMonthMaximum: Type.Optional(Nullable(Type.Integer({ minimum: 0, default: null }))),
    countHolidaysAsWorkdays: Type.Optional(Type.Boolean({ default: false })),
    showIsManipulated: Type.Optional(Type.Boolean({ default: false })),
    deactivatedAt: Type.Optional(Nullable(Type.Date({ default: null }))),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  {
    relations: (r) => ({
      company: r.belongsTo("companies"),
      weekdays: r.hasMany("working-time-model-weekdays", "workingTimeModelId")
    })
  }
);
