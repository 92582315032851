import { Type } from "@artesa/model-compiler";
const baseObject = Type.Object({
  createdAt: Type.ReadonlyOptional(Type.Date()),
  updatedAt: Type.ReadonlyOptional(Type.Date())
});
function newNotification(type, properties) {
  return Type.Composite([
    baseObject,
    Type.Object({
      type: Type.Literal(type),
      context: Type.Object(properties)
    })
  ]);
}
export function compositeNotification(types) {
  return types.map((value) => value(newNotification).properties);
}
