import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable } from "../../typebox/index.js";
export const TimeSheetDay = defineSchema(
  "time-sheet-days",
  {
    day: Type.String({ format: "date" }),
    startsAt: Type.Optional(Nullable(Type.Date())),
    endsAt: Type.Optional(Nullable(Type.Date())),
    approvedAt: Type.Optional(Nullable(Type.Date())),
    declinedAt: Type.Optional(Nullable(Type.Date())),
    declinedNote: Type.Optional(Nullable(Type.String())),
    pauseDuration: Type.Optional(Nullable(Type.Number({ default: 0 }))),
    duration: Type.Optional(Nullable(Type.Number({ default: 0 }))),
    submittedAt: Type.Optional(Nullable(Type.Date())),
    note: Type.Optional(Nullable(Type.String())),
    manipulatedAt: Type.Optional(Nullable(Type.Date())),
    submittedFirstAt: Type.Optional(Nullable(Type.Date())),
    startsAtManipulatedAt: Type.Optional(Nullable(Type.Date())),
    endsAtManipulatedAt: Type.Optional(Nullable(Type.Date())),
    pauseDurationManipulatedAt: Type.Optional(Nullable(Type.Date())),
    durationManipulatedAt: Type.Optional(Nullable(Type.Date())),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date()),
    deletedAt: Type.Optional(Nullable(Type.Date()))
  },
  {
    relations: (r) => ({
      company: r.belongsTo("companies"),
      user: r.belongsTo("users"),
      approvedBy: r.belongsTo("users", { nullable: true }),
      declinedBy: r.belongsTo("users", { nullable: true }),
      manipulatedBy: r.belongsTo("users", { nullable: true })
    })
  }
);
