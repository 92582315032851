export const assignmentNameToShowValues = [
  "name",
  "number",
  "addressProperty",
  "customer"
];
export const COMPANY_SETTING_DAYS_TO_SHOW_DEFAULT = [
  false,
  true,
  true,
  true,
  true,
  true,
  false
];
