import { deepEqual } from "fast-equals";

export const diffKeys = (originalObj: Record<string, any>, updatedObj: Record<string, any>) => {
  const added: string[] = [];
  const removed: string[] = [];
  const changed: string[] = [];

  for (const key in updatedObj) {
    if (!(key in originalObj)) {
      added.push(key);
    } else if (!deepEqual(originalObj[key], updatedObj[key])) {
      changed.push(key);
    }
  }

  for (const key in originalObj) {
    if (!(key in updatedObj)) {
      removed.push(key);
    }
  }

  return { added, removed, changed, allKeys: [...added, ...removed, ...changed] };
};
