import type { UserAbsenceFrontend, IUserAbsencesService } from "@artesa/shared";
import { UserAbsence, USER_ABSENCE_PARAM_WITH_WORKDAYS, USER_ABSENCE_MULTI, USER_ABSENCE_METHODS } from "@artesa/shared";
import { paramsForServer } from "feathers-utils";
import { defineFeathersPiniaStore } from "../../base-store";
import { makeTransformDatesHook } from "../../hooks/transformDates.hook";
import type feathers from "@feathersjs/feathers";

const servicePath = "user-absences" as const;

declare module "../../feathers.client" {
  interface Services {
    "user-absences": feathers.Service<DateToString<UserAbsenceFrontend>> &
    Exclude<IUserAbsencesService, feathers.Service<any>>
  }
}

export const useUserAbsenceStore = defineFeathersPiniaStore({
  schema: UserAbsence,
  servicePath,
  multi: USER_ABSENCE_MULTI,
  methods: USER_ABSENCE_METHODS,
  setupHook({ service, transformDates }) {
    const transformDatesHook = makeTransformDatesHook(transformDates);
    service.hooks({
      before: {
        all: [paramsForServer(USER_ABSENCE_PARAM_WITH_WORKDAYS)],
      },
      after: {
        find: [transformDatesHook],
        get: [transformDatesHook],
        create: [transformDatesHook],
        update: [transformDatesHook],
        patch: [transformDatesHook],
        remove: [transformDatesHook],
      },
    });
  },
});

export default useUserAbsenceStore;
