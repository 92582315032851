import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable } from "../../typebox/index.js";
export const AssignmentEventWorkPeriod = defineSchema(
  "assignment-event-work-periods",
  {
    start: Type.Date(),
    end: Type.Optional(Nullable(Type.Date())),
    pausedAt: Type.Optional(Nullable(Type.Date())),
    note: Type.Optional(Nullable(Type.String())),
    manipulatedAt: Type.Optional(Nullable(Type.Date())),
    duration: Type.ReadonlyOptional(Nullable(Type.Integer({ minimum: 0 }))),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date()),
    deletedAt: Type.Optional(Nullable(Type.Date()))
  },
  {
    relations: (r) => ({
      company: r.belongsTo("companies"),
      type: r.belongsTo("assignment-event-work-types", {
        nullable: true,
        params: { query: { $select: ["id", "name"] } }
      }),
      assignment: r.belongsTo("stairs", {
        params: { query: { $select: ["id", "number", "name", "projectId"] } }
      }),
      event: r.belongsTo("assignment-events", { nullable: true }),
      eventType: r.belongsTo("assignment-event-types", { nullable: true }),
      user: r.belongsTo("users", {
        nullable: true,
        params: { query: { $select: ["id", "fullName"] } }
      }),
      createdBy: r.belongsTo("users", { nullable: true }),
      manipulatedBy: r.belongsTo("users", { nullable: true })
    })
  }
);
