export const publicRoleTypeValues = ["admin", "spectator", "office", "craft"];
export const internalRoleTypeValues = ["superadmin"];
export const roleTypeValues = [...publicRoleTypeValues, ...internalRoleTypeValues];
export const permissionActionCrudValues = ["create", "read", "update", "delete"];
export const permissionActionCrudEnhancedValues = [
  ...permissionActionCrudValues,
  "patch-data",
  "update-data",
  "crud"
];
export const caslAliasResolvers = {
  update: "patch",
  // define the same rules for update & patch
  read: ["get", "find"],
  // use 'read' as a equivalent for 'get' & 'find'
  delete: "remove",
  // use 'delete' or 'remove'
  crud: [...permissionActionCrudValues]
};
export const caslAnySubjectType = "*";
