import { Nullable, defineSchema } from "@artesa/model-compiler";
import { Type } from "../../typebox/index.js";
export const AssignmentEventStandardProcess = defineSchema(
  "assignment-event-standard-processes",
  {
    name: Type.String(),
    order: Type.Optional(Type.Number({ minimum: 1, default: 1 })),
    color: Type.Optional(
      Nullable(Type.RegExp(/^#[0-9a-fA-F]{8}$|#[0-9a-fA-F]{6}$|#[0-9a-fA-F]{4}$|#[0-9a-fA-F]{3}$/))
    ),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  {
    relations: (r) => ({
      company: r.belongsTo("companies")
    })
  }
);
