import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable } from "../../typebox/index.js";
export const CompanyStatistic = defineSchema(
  "company-statistics",
  {
    craft: Type.Optional(Nullable(Type.String())),
    lastLoggedInAt: Type.Optional(Nullable(Type.Date())),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  {
    relations: (r) => ({})
  }
);
