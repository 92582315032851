import PrimeVue from "primevue/config";

export default {
  install: (app: App) => {
    app.use(PrimeVue, {
      locale: de,
    });

    return app;
  },
};

const de = {
  startsWith: "startet mit",
  contains: "enhält",
  notContains: "enthält nicht",
  endsWith: "endet mit",
  equals: "gleich",
  notEquals: "nicht gleich",
  noFilter: "kein Filter",
  lt: "weniger als",
  lte: "weniger oder gleich wie",
  gt: "größer als",
  gte: "größer oder gleich wie",
  dateIs: "Datum ist",
  dateIsNot: "Datum ist nicht",
  dateBefore: "Datum ist vor",
  dateAfter: "Datum ist nach",
  custom: "Benutzerdefiniert",
  apply: "Übernehmen",
  matchAll: "Passt auf alle",
  matchAny: "Passt auf einige",
  addRule: "Regel hinzufügen",
  removeRule: "Regel entfernen",
  accept: "Ja",
  reject: "Nein",
  choose: "Auswählen",
  upload: "Upload",
  cancel: "Abbruch",
  dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
  dayNamesShort: ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"],
  dayNamesMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
  monthNames: [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ],
  monthNamesShort: [
    "Jan",
    "Feb",
    "Mär",
    "Apr",
    "Mai",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dez",
  ],
  today: "Heute",
  clear: "Löschen",
  weekHeader: "Wo",
  firstDayOfWeek: 1,
  dateFormat: "dd.mm.yy",
  weak: "Schwach",
  medium: "Mittel",
  strong: "Stark",
  passwordPrompt: "Passwort eingeben",
  emptyFilterMessage: "Keine Einträge gefunden",
  emptyMessage: "Keine Einträge gefunden",
  aria: {
    trueLabel: "Richtig",
    falseLabel: "Falsch",
    nullLabel: "Nicht selektiert",
    pageLabel: "Seite",
    firstPageLabel: "Erste Seite",
    lastPageLabel: "Letzte Seite",
    nextPageLabel: "Nächste Seite",
    previousPageLabel: "Vorherige Seite",
  },
};

import "primevue/resources/themes/aura-light-teal/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "@/styles/styles.primevue.scss";
