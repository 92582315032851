import { defineSchema, Nullable } from "@artesa/model-compiler";
import { Type } from "../../typebox/index.js";
export const AssignmentEventGroup = defineSchema(
  "assignment-event-groups",
  {
    note: Type.Optional(Nullable(Type.String())),
    order: Type.Optional(Type.Integer({ minimum: 1, default: 1 })),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  {
    relations: (r) => ({
      company: r.belongsTo("companies"),
      type: r.belongsTo("assignment-event-group-types"),
      assignment: r.belongsTo("stairs")
    })
  }
);
