import debounce from "lodash/debounce";
import { Type, TypeCompiler } from "@artesa/shared";
import rison from "rison-node";
import { deepEqual } from "fast-equals";
import { parse as parseDate, format as formatDate } from "date-fns";
import { isNumber, isInteger , isObject } from "@artesa/utils";
import fastCopy from "fast-copy";
import type {
  Static,
  TSchema,
  StringOptions,
  NumberOptions,
  IntegerOptions,
  DateOptions,
  ArrayOptions,
  ViewName,
  TString,
  TNumber,
  TBoolean,
  TArray,
  TInteger,
  TDate,
  TUnion,
  TLiteral,
  TNull,
} from "@artesa/shared";
import type { LocationQueryValue, LocationQuery } from "vue-router";
import { diffKeys } from "@/utils/diffKeys";
import { useVueRouterStore } from "@/store/vue-router.store";

export const URL_QUERY_RESTORE_STORE_KEY = "url-query-restore";

export const URL_QUERY_CUSTOM_VIEW_KEY = "custom-view" as const;

type ToQueryFunction = (input: any, schema: URLQuerySchema) => string | undefined;
type FromQueryFunction = (input: string, schema: URLQuerySchema) => any;

type URLQuerySchemaOptions<D = any> = {
  /**
   * use `router.push` instead of `router.replace`
   *
   * @default false
   */
  push?: boolean;
  /**
   * store the value in the local storage for the current user
   *
   * @default false
   */
  restore?: boolean;
  /**
   * restore the value from local storage even if 'skipUrl' is true
   */
  forceRestore?: boolean;
  /**
   * custom function to transform the value from the actual value to the URL query parameter (string)
   */
  toQuery?: ToQueryFunction;
  /**
   * custom function to transform the value from the URL query parameter (string) to the actual value
   */
  fromQuery?: FromQueryFunction;

  default?: D | (() => D);
};

export type URLQuerySchema<D = any> = TSchema & URLQuerySchemaOptions<D>;

export type URLQueryConfig = {
  [key: string]: URLQuerySchema;
};

export type URLQueryResult<Config extends URLQueryConfig> = {
  [Key in keyof Config]: Static<Config[Key]>;
};

const converterMap: Record<string, [from: FromQueryFunction, to: ToQueryFunction]> = {
  string: [
    str => (str ? decodeURIComponent(str) : undefined),
    val => (val ? encodeURIComponent(val) : undefined),
  ],
  number: [
    str => (isNumber(str) ? parseFloat(str) : undefined),
    val => (val ? val.toString() : undefined),
  ],
  integer: [
    str => (isInteger(str) ? parseInt(str, 10) : undefined),
    val => (val ? val.toString() : undefined),
  ],
  Date: [
    str => (str ? new Date(decodeURIComponent(str)) : undefined),
    val => (val ? encodeURIComponent(val.toISOString()) : undefined),
  ],
  boolean: [input => (input === "1" ? true : false), input => (input ? "1" : "0")],
  array: [
    str => (str ? rison.decode_array(str) : undefined),
    input =>
      input && Array.isArray(input) && input.length ? rison.encode_array(input) : undefined,
  ],
  object: [
    str => (str ? rison.decode_object(str) : undefined),
    input => (input && Object.keys(input).length ? rison.encode_object(input) : undefined),
  ],
  comparisonOp: [
    str => {
      if (str === "eq" || str === "gte" || str === "lte") {
        return `$${str}`;
      }

      return "$eq";
    },
    input => {
      if (input === "$eq" || input === "$gte" || input === "$lte") {
        return input.substr(1);
      }

      return "eq";
    },
  ]
};

function getTypeOfSchema(schema: TSchema): string | undefined {
  if ("anyOf" in schema) {
    for (const subSchema of schema.anyOf) {
      if (subSchema.type && subSchema.type !== "null") {
        return getTypeOfSchema(subSchema);
      }
    }
  }

  return schema.type ?? undefined;
}

function getDefault(schema: URLQuerySchema) {
  if ("anyOf" in schema && !("default" in schema)) {
    for (const subSchema of schema.anyOf) {
      if ("default" in subSchema) {
        return getDefault(subSchema);
      }
    }
  }

  return "default" in schema
    ? typeof schema.default === "function"
      ? schema.default()
      : fastCopy(schema.default)
    : null;
}

function getToQuery(schema: TSchema): ToQueryFunction | undefined {
  if ("anyOf" in schema && !("toQuery" in schema)) {
    for (const subSchema of schema.anyOf) {
      if ("toQuery" in subSchema) {
        return subSchema.toQuery;
      }
    }
  }

  return schema.toQuery;
}

function getFromQuery(schema: TSchema): FromQueryFunction | undefined {
  if ("anyOf" in schema && !("fromQuery" in schema)) {
    for (const subSchema of schema.anyOf) {
      if ("fromQuery" in subSchema) {
        return subSchema.fromQuery;
      }
    }
  }

  return schema.fromQuery;
}

function getRestore(schema: TSchema): boolean {
  if ("anyOf" in schema && !("restore" in schema)) {
    for (const subSchema of schema.anyOf) {
      if ("restore" in subSchema) {
        return !!subSchema.restore;
      }
    }
  }

  return !!schema.restore;
}

function getForceRestore(schema: TSchema): boolean {
  if ("anyOf" in schema && !("forceRestore" in schema)) {
    for (const subSchema of schema.anyOf) {
      if ("forceRestore" in subSchema) {
        return !!subSchema.forceRestore;
      }
    }
  }

  return !!schema.forceRestore;
}

function transformFromUrl(
  input: LocationQueryValue | LocationQueryValue[],
  schema: URLQuerySchema,
  checker: ReturnType<typeof TypeCompiler.Compile>,
) {
  if (!input || Array.isArray(input)) {
    return getDefault(schema);
  }

  const fromQuery = getFromQuery(schema);

  if (fromQuery) {
    try {
      const value = fromQuery(input, schema);
      if (!checker.Check(value)) {
        throw new Error("Invalid value");
      }

      return value;
    } catch (err) {
      return getDefault(schema);
    }
  }

  const type = getTypeOfSchema(schema);

  if (!type || !(type in converterMap)) {
    return getDefault(schema);
  }

  try {
    const value = converterMap[type][0](input, schema);

    if (!checker.Check(value)) {
      throw new Error("Invalid value");
    }

    return value;
  } catch (err) {
    return getDefault(schema);
  }
}

function transformValueToUrl(newValue: any, schema: URLQuerySchema) {
  const toQuery = getToQuery(schema);

  if (toQuery) {
    return toQuery(newValue, schema);
  }

  const type = getTypeOfSchema(schema);

  if (!type || !(type in converterMap)) {
    console.error("no type", schema);
    return null;
  }

  try {
    const result = converterMap[type][1](newValue, schema);
    return result;
  } catch (err) {
    console.error(err);
    return null;
  }
}

const _literal = <T extends (string | number | boolean)[]>(
  value: [...T],
  options?: URLQuerySchemaOptions<T[number]>,
) =>
  Type.Union(
    value.map(t => Type.Literal(t)),
    options,
  ) as LiteralResult<T>;

type GenericLiteral = <T extends ReadonlyArray<string | number | boolean>>(
  value: T,
  options?: URLQuerySchemaOptions<T[number]>,
) => LiteralResult<T>;

type Literal<T extends (string | number | boolean)[]> = (
  value: [...T],
  options?: URLQuerySchemaOptions<T[number]>,
) => LiteralResult<T>;

type LiteralResult<T extends (string | number | boolean)[]> = TUnion<{
  [Key in keyof T]: TLiteral<T[Key]>;
}>;

export const urlQueryType: UrlQueryType = {
  string: (options?: StringOptions & URLQuerySchemaOptions) => Type.String(options),
  number: (options?: NumberOptions & URLQuerySchemaOptions) => Type.Number(options),
  integer: (options?: IntegerOptions & URLQuerySchemaOptions) => Type.Integer(options),
  boolean: (options?: URLQuerySchemaOptions) => Type.Boolean(options),
  array: <T extends TSchema>(schema: T, options?: ArrayOptions & URLQuerySchemaOptions) =>
    Type.Array(schema, options),
  date: (options?: DateOptions & { format?: string } & URLQuerySchemaOptions) =>
    Type.Date({
      fromQuery: (str: string) =>
        str
          ? options?.format
            ? parseDate(str, options.format, new Date())
            : new Date(parseInt(str, 10))
          : undefined,
      toQuery: (input: Date) =>
        input
          ? options?.format
            ? formatDate(input, options.format)
            : input.getTime().toString()
          : undefined,
      ...options,
    }),
  comparisonOp: (options?: URLQuerySchemaOptions<"$eq" | "$gte" | "$lte">) => _literal(["$eq", "$gte", "$lte"] as const, {
    default: "$eq" as const,
    fromQuery: converterMap.comparisonOp[0],
    toQuery: converterMap.comparisonOp[1],
    ...options
  }) as any,
  literal: _literal,
  id: (options?: IntegerOptions & URLQuerySchemaOptions) =>
    Type.Integer({
      minimum: 1,
      ...options,
    }),
  nullable: <T extends TSchema>(schema: T) => Type.Union([schema, Type.Null()]),
};

export type UrlQueryType = {
  string: (options?: StringOptions & URLQuerySchemaOptions) => TString;
  number: (options?: NumberOptions & URLQuerySchemaOptions) => TNumber;
  integer: (options?: IntegerOptions & URLQuerySchemaOptions) => TInteger;
  boolean: (options?: URLQuerySchemaOptions) => TBoolean;
  array: <T extends TSchema>(
    schema: T,
    options?: ArrayOptions & URLQuerySchemaOptions,
  ) => TArray<T>;
  date: (options?: DateOptions & { format?: string } & URLQuerySchemaOptions) => TDate;
  literal: GenericLiteral;
  comparisonOp: (options?: URLQuerySchemaOptions<"$eq" | "$gte" | "$lte">) => LiteralResult<["$gte", "$lte", "$eq"]>;
  id: (options?: IntegerOptions & URLQuerySchemaOptions) => TInteger;
  nullable: <T extends TSchema>(schema: T) => TUnion<[T, TNull]>;
};

type UseUrlQueryOptionsFunction<Config extends URLQueryConfig> = (ctx: UrlQueryType) => Config;

const useUrlQueryRestoreStore = createSharedComposable(() => {
  return useLocalStorage<UrlQueryLocalStore>(URL_QUERY_RESTORE_STORE_KEY, {});
});

export type UseURLQueryOptions = {
  /**
   * Skip updating the URL query parameter.
   * 
   * This can be used to prevent the URL from updating when the value is changed.
   * You probably want to use this, if you share the same composable in multiple places
   */
  skipUrl?: boolean;
}

function _forceRestoreData(name: string, config: URLQueryConfig) {
  const restoreStore = useQueryRestoreData().value;

  if (!restoreStore) {
    return {};
  }

  const data = restoreStore[name];

  if (!data || !isObject(data) || !Object.keys(data).length) {
    return {};
  }

  const query = {};

  for (const key in config) {
    if (!getForceRestore(config[key]) || !(key in data)) {
      continue;
    }

    query[key] = data[key];
  }

  return query;
}

export function useURLQuery<Config extends URLQueryConfig>(
  name: ViewName,
  makeConfig: Config | UseUrlQueryOptionsFunction<Config>,
  options?: UseURLQueryOptions
): UnwrapNestedRefs<URLQueryResult<Config>> {
  const skipUrl = options?.skipUrl ?? false;
  const route = useRoute();
  const router = useRouter();
  const vueRouterStore = useVueRouterStore();

  const config = typeof makeConfig === "function" ? makeConfig(urlQueryType) : makeConfig;

  const checker = {} as Record<keyof Config, ReturnType<typeof TypeCompiler.Compile>>;

  for (const key in config) {
    checker[key] = TypeCompiler.Compile(config[key]);
  }

  // Keys where we need to use push instead of replace
  const pushKeys = computed(() =>
    Object.entries(config)
      .filter(([, value]) => "push" in value && value.push === true)
      .map(([key]) => key),
  );

  // Local copy for the url store
  const store = reactive<URLQueryResult<Config>>({} as any);

  // Sync all values from url query parameter to our store
  // Can be limited to a set of keys
  const syncToStore = (query: LocationQuery, onlySyncKeys: string[] = Object.keys(config)) => {
    for (const key in config) {
      if (!onlySyncKeys.includes(key)) {
        continue;
      }

      store[key] = ref(transformFromUrl(query[key], config[key], checker[key]));
    }
  };

  if (!skipUrl) {
    syncToStore(route.query);

    // When we update our current route with router.replace, we trigger a watch
    // which syncs from our query parameter back to our local store.
    // We don't want this. Each router.replace increases this value by 1. And after each
    // successfully or unsuccessfully router.replace this semaphore gets decreased by 1.
    const semaphore = ref(0);

    const { pause: pauseWatch, resume: resumeWatch } = watchDebouncedPausable(
      store,
      newValue => {
        const newQuery = { ...route.query };

        for (const key in config) {
          const schema = config[key];

          if (deepEqual(newValue[key], getDefault(schema))) {
            delete newQuery[key];
            continue;
          }

          newQuery[key] = transformValueToUrl(newValue[key], schema) ?? null;

          if (newQuery[key] === null) {
            delete newQuery[key];
            continue;
          }
        }

        const { allKeys: changedKeys } = diffKeys(route.query, newQuery);

        if (!changedKeys.length) {
          return;
        }

        // Determine if we need to push instead of replace
        const doPush = changedKeys.some(key => pushKeys.value.includes(key));

        semaphore.value += 1;

        vueRouterStore.skipRestore = true;
        router
          .push({
            query: newQuery,
            force: true,
            replace: !doPush,
          })
          .finally(() => {
            semaphore.value -= 1;
          });
      },
      {
        deep: true,
        debounce: 200,
      },
    );

    const ignoreUpdates = (cb: () => void) => {
      pauseWatch();
      cb();
      resumeWatch();
    }

    // Update local store
    watch(
      () => ({ ...route.query }),
      (newValue, oldValue) => {
        // If this value is > 0, this watch was triggered by a router.replace
        if (semaphore.value > 0) {
          return;
        }

        const { allKeys: changedKeys } = diffKeys(oldValue, newValue);

        const keysToSync = changedKeys
          // Make sure, we only update keys we actually need in this instance.
          // Otherwise a watch on our local store would trigger even for keys required
          // by other instances
          .filter(key => Object.keys(config).includes(key));

        ignoreUpdates(() => {
          syncToStore(newValue, keysToSync);
        });
      },
      {
        deep: true,
      },
    );
  } else {
    syncToStore(_forceRestoreData(name, config));
  }

  // persistence

  const localStore = useUrlQueryRestoreStore();
  const currentUser = useUser();

  function routeNameMatches() {
    const { matched } = route;

    for (let i = matched.length - 1; i >= 0; i--) {
      const record = matched[i];

      if (!record.name) {
        continue;
      }

      if (record.name === name) {
        return true;
      }
    }

    return false;
  }

  function _persist() {
    if (!routeNameMatches()) {
      return;
    }

    if (!currentUser.value?.id || !name) {
      return;
    }

    // do not persist customView routes
    if (route.query[URL_QUERY_CUSTOM_VIEW_KEY]) {
      return;
    }

    let routeData: Record<string, any> | undefined = undefined;

    for (const key in config) {
      const schema = config[key];
      // Persist only if 'restore:true' is set to true
      if (getRestore(schema) && route.query[key]) {
        routeData ??= {};
        routeData[key] = route.query[key];
      }
    }

    if (!routeData) {
      if (localStore.value[currentUser.value.id]?.[name]) {
        delete localStore.value[currentUser.value.id][name];
      }

      return;
    }

    if (!localStore.value[currentUser.value.id]) {
      localStore.value[currentUser.value.id] = {};
    }

    localStore.value[currentUser.value.id][name] = routeData;
  }

  const persist = debounce(_persist, 350);

  watch([currentUser, route], () => {
    if (skipUrl) {
      return;
    }

    persist();
  });

  return store;
}

/**
 *
 * @returns The current restore data for the current user
 */
export function useQueryRestoreData() {
  const localStore = useUrlQueryRestoreStore();
  const currentUser = useUser();

  return computed(() =>
    currentUser.value?.id ? localStore.value[currentUser.value.id] : undefined,
  );
}

export function objectToQueryObject<Config extends URLQueryConfig>(
  config: Config,
  input: URLQueryResult<Config>,
): Record<string, string> {
  const query = {} as Record<string, string>;
  for (const key in input) {
    const urlValue = transformValueToUrl(input[key], config[key]);
    if (urlValue != null) {
      query[key] = urlValue;
    }
  }
  return query;
}

export type UrlQueryLocalStoreValue = Record<string | symbol, Record<string, string>>;

/**
 * First Record key is current user id
 * Second Record key is View Name
 * Third Record key Query Property key
 * Value: String representation of query value for specific key
 */
export type UrlQueryLocalStore = Record<number, UrlQueryLocalStoreValue>;
