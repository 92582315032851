import { defineSchema } from "@artesa/model-compiler";
import { weekdayNumberValues } from "../../utils.js";
import { Type, NumberEnum, Nullable } from "../../typebox/index.js";
export const WorkingTime = defineSchema(
  "working-times",
  {
    weekday: NumberEnum(weekdayNumberValues),
    start: Type.Optional(Nullable(Type.Integer({ minimum: 0, maximum: 24 * 60 * 60 - 1 }))),
    end: Type.Optional(Nullable(Type.Integer({ minimum: 0, maximum: 24 * 60 * 60 - 1 }))),
    pause: Type.Optional(Nullable(Type.Integer({ minimum: 0, maximum: 24 * 60 * 60 - 1 }))),
    duration: Type.Optional(Nullable(Type.Integer({ minimum: 0, maximum: 24 * 60 * 60 - 1 }))),
    isActive: Type.Optional(Type.Boolean({ default: true })),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  {
    relations: (r) => ({
      company: r.belongsTo("companies")
    })
  }
);
