import type { IUnaccountablePeriodService} from "@artesa/shared";
import { UNACCOUNTABLE_PERIODS_METHODS, UnaccountablePeriod } from "@artesa/shared";
import { defineFeathersPiniaStore } from "../../base-store";
import { makeTransformDatesHook } from "../../hooks/transformDates.hook";

declare module "../../feathers.client" {
  interface Services {
    "unaccountable-periods": IUnaccountablePeriodService;
  }
}

export const useUnaccountablePeriodStore = defineFeathersPiniaStore({
  schema: UnaccountablePeriod,
  servicePath: "unaccountable-periods",
  methods: UNACCOUNTABLE_PERIODS_METHODS,
  setupHook({ service, transformDates }) {
    const transformDatesHook = makeTransformDatesHook(transformDates);
    service.hooks({
      after: {
        find: [transformDatesHook],
        get: [transformDatesHook],
        create: [transformDatesHook],
        update: [transformDatesHook],
        patch: [transformDatesHook],
        remove: [transformDatesHook],
      },
    });
  },
});

export default useUnaccountablePeriodStore;
