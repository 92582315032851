import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable } from "../../typebox/index.js";
export const TimeSheetMonth = defineSchema(
  "time-sheet-months",
  {
    month: Type.String({ format: "date" }),
    submittedAt: Type.Optional(Nullable(Type.Date())),
    submittedFirstAt: Type.Optional(Nullable(Type.Date())),
    approvedAt: Type.Optional(Nullable(Type.Date())),
    declinedAt: Type.Optional(Nullable(Type.Date())),
    declinedNote: Type.Optional(Nullable(Type.String())),
    note: Type.Optional(Nullable(Type.String())),
    sumActual: Type.Number({ default: 0 }),
    sumTarget: Type.Optional(Nullable(Type.Number({ default: null }))),
    carryOverAfter: Type.Optional(Nullable(Type.Number({ default: 0 }))),
    carryOverBefore: Type.Optional(Nullable(Type.Number({ default: 0 }))),
    carryOverBeforeManipulatedAt: Type.Optional(Nullable(Type.Date())),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  {
    relations: (r) => ({
      company: r.belongsTo("companies"),
      user: r.belongsTo("users"),
      approvedBy: r.belongsTo("users", { nullable: true }),
      declinedBy: r.belongsTo("users", { nullable: true })
    })
  }
);
