import { AssignedUserEventType } from "@artesa/shared";
import { defineFeathersPiniaStore } from "../../base-store";
import { makeTransformDatesHook } from "@artesa/feathers-pinia";

export const useAssignedUserEventTypeStore = defineFeathersPiniaStore({
  schema: AssignedUserEventType,
  servicePath: "assigned-user-event-types",
  setupHook({ service, transformDates }) {
    const transformDatesHook = makeTransformDatesHook(transformDates);
    service.hooks({
      after: {
        find: [transformDatesHook],
        get: [transformDatesHook],
        create: [transformDatesHook],
        update: [transformDatesHook],
        patch: [transformDatesHook],
        remove: [transformDatesHook],
      },
    });
  },
});

export default useAssignedUserEventTypeStore;
