import { isNonNullable } from "@artesa/utils";
import { del } from "idb-keyval";
import useUserStore from "./feathers/services/users/users.pinia";
import app from "./feathers/feathers.client";
import useCustomViewRoleStore from "./feathers/services/custom-view-roles/custom-view-roles.pinia";
import useCustomViewDataStore from "./feathers/services/custom-view-data/custom-view-data.pinia";
import useCustomViewStore from "./feathers/services/custom-views/custom-views.pinia";
import type { Router } from "vue-router";
import type { UserFrontend } from "@artesa/shared";
import { usePushNotificationStore } from "./web-push.store";
import { unset as caslUnset, setRules as caslSetRules } from "@/main.plugins/main.casl";
import { useIDBKeyval } from "@vueuse/integrations/useIDBKeyval";
import type { FeathersLoadingState } from "@artesa/feathers-pinia";
import { Sentry } from "@/main.plugins/main.sentry";
import { parseJwt } from "@/utils/jwt";

declare module "./feathers/feathers.client" {
  interface Configuration {
    useAuthStore: typeof useAuthStore;
  }
}

export const useAuthStore = defineStore("auth", () => {
  let router: Router | undefined = undefined;
  function setRouter(_router: Router) {
    router = _router;
  }

  const userStore = useUserStore();
  const userStoreScope = userStore.createScope();

  const customViewStore = useCustomViewStore().createScope();
  const customViewRoleStore = useCustomViewRoleStore().createScope();
  const customViewDataStore = useCustomViewDataStore().createScope();

  const accessToken = shallowRef<string | null>(null);

  const jwtPayload = computed(() => {
    if (!accessToken.value) {
      return null;
    }

    return parseJwt(accessToken.value);
  });

  const state = shallowRef<FeathersLoadingState>("idle");
  const errorOnAuthenticate = shallowRef<Error | null>(null);
  const _authResponse = shallowRef<any>(null);

  const user = shallowRef<UserFrontend | null>(null);

  const { data: signInMethod } = useIDBKeyval<"local" | "rfid" | "impersonate" | null>(
    "sign-in-method",
    null,
    { deep: false, shallow: true },
  );

  async function authenticate(data?: any, params?: any) {
    state.value = "pending";
    let authResult;
    try {
      authResult = await app.authenticate(data, params);
      _authResponse.value = authResult;
      state.value = "success";
      errorOnAuthenticate.value = null;
    } catch (error: any) {
      state.value = "error";
      errorOnAuthenticate.value = error;
      accessToken.value = null;
      caslUnset();
      signInMethod.value = null;
      throw error;
    }

    if (authResult?.accessToken) {
      accessToken.value = authResult.accessToken;

      if (authResult.rules) {
        caslSetRules(authResult.rules);
      }

      if (authResult.user) {
        userStore.transformDates(authResult.user);
        // feathers-pinia
        user.value = userStoreScope.construct(authResult.user);
      }
    }
  }

  async function authenticateLocal({ email, password }: { email: string; password: string }) {
    await reset();
    const result = await authenticate({
      strategy: "local",
      email,
      password,
    });
    signInMethod.value = "local";
    return result;
  }

  async function authenticateRfid(tag: string) {
    if (!user.value?.id) {
      throw new Error("User not found");
    }
    await usePushNotificationStore().disableSubscription();
    const result = await authenticate({
      strategy: "rfid",
      rfid: tag,
    });

    return result;
  }

  async function impersonate(token: string, userId?: string) {
    await usePushNotificationStore().disableSubscription();
    const result = await authenticate({
      strategy: "impersonate",
      adminToken: token,
      ...(userId ? { user: userId } : {}),
    });
    if (userId) {
      // impersonating a user
      signInMethod.value = "impersonate";
    } else {
      // reset impersonation
      signInMethod.value = "local";
    }

    return result;
  }

  const logoutEvent = createEventHook<void>();

  async function reset() {
    await del("superAdminJwt");
    safeLocalStorage()?.removeItem("manufacturingUserInactive");
  }

  async function logout() {
    try {
      router?.push({ name: "Login" });
      await nextTick();
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      await usePushNotificationStore().disableSubscription().catch(() => {});
      await app.logout();
    } finally {
      logoutEvent.trigger();
      await reset();
      accessToken.value = null;
      state.value = "idle";
      caslUnset();
      user.value = null;
      window.location.reload();
    }
  }

  // Use this to get the user's view roles
  const { data: viewRoles } = customViewRoleStore.useFind({
    params: computed(() => {
      if (!user.value?.roleId) {
        return null;
      }

      return {
        query: {
          roleId: user.value.roleId,
          $limit: 100,
        },
      };
    }),
  });

  const viewIds = computed(() => {
    return viewRoles.value.map(x => x.viewId).filter(isNonNullable);
  });

  const { data: views } = customViewStore.useFindOnce({
    items: viewIds,
    params: computed(() => {
      return {
        query: {
          id: { $in: viewIds.value },
          $sort: { order: 1 },
          $limit: 100,
        },
      };
    }),
    fetchParams: ids => ({
      query: {
        id: { $in: ids },
        $limit: 100,
      },
    }),
  });

  const { data: viewData } = customViewDataStore.useFindOnce({
    items: viewIds,
    params: computed(() => {
      return {
        query: {
          viewId: { $in: viewIds.value },
          $limit: 100,
        },
      };
    }),
    fetchParams: ids => ({
      query: {
        viewId: { $in: ids },
        $limit: 100,
      },
    }),
  });

  const customViewsWithData = computed(() => {
    return views.value.map(view => {
      const viewDataItems = viewData.value.filter(x => x.viewId === view.id);

      return {
        view,
        data: viewDataItems,
      };
    });
  });

  const isAuthenticated = computed(() => !!accessToken.value);

  const clearAuthenticateError = () => {
    errorOnAuthenticate.value = null;
  };

  function isJwtExpired(): boolean {
    if (!jwtPayload.value?.exp) {
      return true;
    }

    return jwtPayload.value.exp * 1000 < Date.now();
  }

  watch(
    user,
    user => {
      if (user) {
        Sentry.setUser({
          id: user.id,
          username: `${user.fullName} @ ${user.companyId}`,
          email: user.email,
        });
        Sentry.setContext("company", {
          name: user.company?.name,
          id: user.companyId,
          uuid: user.companyUuid
        });
      } else {
        Sentry.setUser(null);
        Sentry.setContext("company", null);
      }
    },
    { immediate: true },
  );

  return {
    accessToken,
    errorOnAuthenticate,
    state,
    user: computed<UserFrontend | null>(() => user.value),
    isAuthenticated,
    clearAuthenticateError,
    authenticate,
    authenticateLocal,
    authenticateRfid,
    logout,
    impersonate,
    customViewsWithData,
    router,
    setRouter,
    signInMethod,
    onLogout: logoutEvent.on,
    isJwtExpired,
  };
});

app.set("useAuthStore", useAuthStore);
