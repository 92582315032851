export const userViewNameValues = [
  "ViewUserProfile",
  "ViewNotifications",
  "MainMenu",
  "ViewUserAbsences",
  "ViewTimeSheetDays",
  "ViewNotificationHandler"
];
export const adminViewNameValues = [
  "ViewSettings",
  "ViewSettings.UserAbsenceTypes",
  "ViewAssignmentWorkPeriodsExport",
  "ViewSettings.Users",
  "ViewSettings.Manufacturing",
  "ViewSettings.AssignmentTypes",
  "ViewSettings.AssignmentTagTypes",
  "ViewSettings.Roles",
  "ViewSettings.AssignmentEventTypes",
  "ViewSettings.AssignmentEventWorkPeriods",
  "ViewSettings.Capabilities",
  "ViewSettings.Gangs",
  "ViewSettings.Holidays",
  "ViewSettings.Company",
  "ViewSettings.Holidays",
  "ViewSettings.TimeSheets",
  "ViewSettings.AssignmentStatusTypes",
  "ViewSettings.WorkingTimesForUser",
  "ViewSettings.UploadCategories",
  "ViewManufacturingAssignPeriodsToOtherUsers"
];
const ViewPlanningBoard = "ViewPlanningBoard";
const ViewAssignments = "ViewAssignments";
const ViewAssignment = "ViewAssignment";
const ViewCustomers = "ViewCustomers";
const ViewCustomer = "ViewCustomer";
const ViewProjects = "ViewProjects";
const ViewProject = "ViewProject";
const ViewAssignmentEvents = "ViewAssignmentEvents";
const ViewDepartmentScheduler = "ViewDepartmentScheduler";
export const officeViewNameValues = [
  "ViewAssignmentCreate",
  ViewAssignments,
  ViewCustomers,
  ViewCustomer,
  ViewProjects,
  ViewProject,
  ViewAssignmentEvents,
  "ViewUserAbsences.Table",
  "ViewChatRooms",
  ViewDepartmentScheduler,
  "ViewListForms",
  "ViewListForms.Form",
  "ViewCustomer.CSV"
];
export const craftViewNameValues = [
  "ViewDoing",
  "DialogAssemblyAssignmentCard",
  "ViewShopfloorEntrance",
  "ViewManufacturingWorkSpace",
  ViewPlanningBoard,
  ViewAssignment
];
export const internalViewNameValues = [
  "ViewSuperAdmin",
  "ViewSuperAdminCompanies",
  "ViewSuperAdminCompanies.Company",
  "ViewSuperAdminCustomForms",
  "ViewSuperAdminCustomForms.CustomForm",
  "ViewSuperAdminCustomFormPreview",
  "ViewSuperAdminFeatureFlags",
  "ViewSettings.CustomViews",
  "ViewSettings.SuperAdmin",
  "ViewSettings.StripeInvoices",
  "ViewSettings.StripeSubscriptions",
  "ViewSettings.AssignmentCustomAttributes",
  "ViewPlayground",
  "ViewSuperAdminStripePayoutView"
];
export const viewNameValuesPublic = [
  .../* @__PURE__ */ new Set([
    ...userViewNameValues,
    ...adminViewNameValues,
    ...officeViewNameValues,
    ...craftViewNameValues
  ])
];
export const viewNameValuesDuplicated = [
  // ViewAssignment
  `${ViewPlanningBoard}.${ViewAssignment}`,
  `${ViewAssignments}.${ViewAssignment}`,
  `${ViewCustomers}.${ViewCustomer}.${ViewAssignment}`,
  `${ViewProjects}.${ViewProject}.${ViewAssignment}`,
  `${ViewAssignmentEvents}.${ViewAssignment}`,
  // ViewCustomer
  `${ViewCustomers}.${ViewCustomer}`,
  // ViewProject
  `${ViewProjects}.${ViewProject}`,
  `${ViewProjects}.${ViewProject}.${ViewAssignment}`,
  // ViewDepartmentScheduler
  `${ViewDepartmentScheduler}.${ViewAssignment}`
];
export const viewNameValues = [
  .../* @__PURE__ */ new Set([...viewNameValuesPublic, ...internalViewNameValues, ...viewNameValuesDuplicated])
];
export const viewNameEnum = viewNameValues.reduce(
  (acc, curr) => ({ ...acc, [curr]: curr }),
  {}
);
