import { defineSchema } from "@artesa/model-compiler";
import { internalRoleTypeValues, publicRoleTypeValues } from "../../permissions/index.js";
import { Type, Nullable, StringEnum } from "../../typebox/index.js";
export const Role = defineSchema(
  "roles",
  {
    name: Type.String(),
    type: StringEnum([...publicRoleTypeValues, ...internalRoleTypeValues]),
    order: Type.Optional(Type.Integer({ minimum: 1, default: 1 })),
    deactivatedAt: Type.Optional(Nullable(Type.Date({ default: null }))),
    canJustSeeOwnEvents: Type.Optional(Type.Boolean({ default: false })),
    canSeeAbsences: Type.Optional(
      Type.Union([Type.Literal("own"), Type.Literal("supervisor"), Type.Null()])
    ),
    canSeeTimesheets: Type.Optional(
      Type.Union([Type.Literal("own"), Type.Literal("supervisor"), Type.Null()])
    ),
    cannotDeleteAssignments: Type.Optional(Type.Boolean({ default: false })),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  {
    relations: (r) => ({
      company: r.belongsTo("companies")
    })
  }
);
