import type { HookContext, NextFunction } from "@feathersjs/feathers";
import { Sentry } from "@/main.plugins/main.sentry";
import type { Span } from "@sentry/browser";
import { NotAuthenticated } from "@feathersjs/errors";
import { notInstanceof as notError } from "@artesa/utils";

let overarchingSpan: Span | null = null

export const sentry = () => 
  async (context: HookContext, next: NextFunction) => {
    if (!context.params.span) {
      if (!overarchingSpan) {
        overarchingSpan = Sentry.getActiveSpan() ?? null;
      }
      if (overarchingSpan) {
        context.params.span = overarchingSpan;
      }
    }

    const span = Sentry.startInactiveSpan(
      {
        name: `${context.method}: /${context.path}`,
        op: "websocket",
        forceTransaction: true,
        attributes: {
          method: context.method,
          service: context.path,
        },
        parentSpan: context.params.span
      },
    );
    // Create `sentry-trace` header
    const sentryTraceHeader = Sentry.spanToTraceHeader(span);
    // Create `baggage` header
    const sentryBaggageHeader = Sentry.spanToBaggageHeader(span);

    context.params["$sentry-trace"] = sentryTraceHeader;
    context.params["$baggage"] = sentryBaggageHeader;
    context.params["$span"] = Sentry.spanToJSON(span);
    context.params.span = span;

    try {
      await next();
    } catch (error) {
      const sentryMessage = (
        context.path === "authentication" || 
        notError(error, NotAuthenticated)
      ) ? "unauthenticated" : "unknown_error";

      span?.setStatus({ code: 2 /* SPAN_STATUS_ERROR */, message: sentryMessage });
      throw error;
    } finally {
      if (context.params.query)
        Sentry.getIsolationScope().setContext("query", context.params.query);
      if (context.params.$populateParams)
        Sentry.getIsolationScope().setContext(
          "populateParams",
          context.params.$populateParams,
        );
      span.end();
    }
  };