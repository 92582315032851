import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable } from "../../typebox/index.js";
export const UnaccountablePeriod = defineSchema(
  "unaccountable-periods",
  {
    start: Type.Date(),
    end: Type.Optional(Nullable(Type.Date())),
    note: Type.Optional(Nullable(Type.String())),
    manipulatedAt: Type.Optional(Nullable(Type.Date())),
    duration: Type.ReadonlyOptional(Nullable(Type.Integer({ minimum: 0 }))),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date())
  },
  {
    relations: (r) => ({
      company: r.belongsTo("companies"),
      type: r.belongsTo("unaccountable-period-types"),
      user: r.belongsTo("users"),
      eventType: r.belongsTo("assignment-event-types", { nullable: true }),
      manipulatedBy: r.belongsTo("users", { nullable: true })
    })
  }
);
