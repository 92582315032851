import { createI18n } from "vue-i18n";
import de from "../locales/de.json";
import { registerMessageCompiler, compile } from "@intlify/core-base";

// Type-define 'en-US' as the master schema for the resource
type MessageSchema = typeof de;

export function createI18nInstance(
  globalInjection = true,
  legacy = false,
  forceMessageCompiler = false,
) {
  const locale = import.meta.env.VITE_I18N_LOCALE || "de";
  const fallbackLocale = import.meta.env.VITE_I18N_FALLBACK_LOCALE || "de";

  // TODO: We can improve this at some point
  const files = import.meta.glob("../locales/*.json");

  const messages = {};

  // Stupid workaround for ServiceWorkers
  if (forceMessageCompiler) {
    registerMessageCompiler(compile);
  }

  return createI18n<[MessageSchema], "de">({
    locale,
    fallbackLocale,
    globalInjection,
    messages: {
      de,
    },
    legacy,
  });
}

globalThis._i18n = createI18nInstance(false, true);

export default {
  install: async app => {
    app.use(createI18nInstance());

    return app;
  },
};
