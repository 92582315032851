import { defineSchema } from "@artesa/model-compiler";
import { Type, Nullable, StringEnum } from "../../typebox/index.js";
import { languagesAvailable, languageDefault } from "./companies.types.js";
export const Company = defineSchema(
  "companies",
  {
    name: Type.String(),
    deactivatedAt: Type.Optional(Nullable(Type.Date({ default: null }))),
    language: Type.Optional(StringEnum(languagesAvailable, { default: languageDefault })),
    stripeCustomer: Type.Optional(Nullable(Type.String())),
    // sequelize dates
    createdAt: Type.ReadonlyOptional(Type.Date()),
    updatedAt: Type.ReadonlyOptional(Type.Date()),
    deletedAt: Type.Optional(Nullable(Type.Date()))
  },
  {
    relations: (r) => ({
      referenceUser: r.belongsTo("users", { nullable: true }),
      address: r.belongsTo("addresses", { nullable: true })
    })
  }
);
