import prettyBytes from "pretty-bytes";

export const downloadFile = (str: string, mimetype: string, fileName: string) => {
  const blob = new Blob([str], { type: mimetype });
  const url = window.URL.createObjectURL(blob);
  downloadFromUrl(url, { fileName });
};

export type DownloadFromUrlOptions = {
  fileName?: string;
};

export const downloadFromUrl = (url: string, options?: DownloadFromUrlOptions) => {
  const a = document.createElement("a");
  a.setAttribute("href", url);
  if (options?.fileName) {
    a.setAttribute("download", options.fileName);
  }
  a.setAttribute('target', '_blank')
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

/**
 * Format fileSize in bytes to human readable format with 'prettyBytes' regarding the locale of the currently logged in user.
 * @returns
 */
export function useFormatBytes() {
  const locale = useLocale();

  return function formatBytes(size?: number | null) {
    return size ? prettyBytes(size, { locale: locale.value }) : "";
  };
}

/**
 * Split a file name into name and extension.
 */
export function splitFileName(fileName: string) {
  // get the last part of absolute path
  fileName = fileName.split("/").pop() || fileName;
  const splitted = fileName.split(".");
  const ext = splitted.length > 1 ? `.${splitted.pop()}` : "";
  const name = fileName.slice(0, -ext.length);
  return [name, ext];
}
