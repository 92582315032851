import { useAuthStore } from "@/store/auth.store";
import type { UserFrontend } from "@artesa/shared";

/**
 * Get the current logged-in user
 */
export const useUser = createSharedComposable<() => ComputedRef<UserFrontend | null>>(() => {
  const authStore = useAuthStore();

  return computed(() => {
    return authStore.user;
  });
});

/**
 * Get the current logged-in user
 */
export const useUserNotNullable = createSharedComposable<() => ComputedRef<UserFrontend>>(() => {
  const authStore = useAuthStore();

  return computed(() => {
    const user = authStore.user;
    if (!user) {
      throw new Error("User is not logged in");
    }
    return user;
  });
});
